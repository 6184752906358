import {
  IonButton,
  IonContent,
  IonPage,
  IonAlert,
  IonSpinner,
} from '@ionic/react';
import React, {useState} from 'react';
import logo from '../../../assets/logo-freighthub.png';
import './login.scss';
import {useForm} from 'react-hook-form';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {login, setLoading, USER_LOGIN, getData} from '../../../actions/user';
import _ from 'lodash';
import ls from 'local-storage';
import { version } from '../../../../package.json';
import { getToken } from '../../../firebase';

const isMshippers = process.env.REACT_APP_APP_NAME === 'mshippers';
if (process.env.REACT_APP_APP_NAME === 'mshippers') {
  require('./loginbg.mshippers.scss');
} else {
  require('./loginbg.receivers.scss');
}


const checkRole = (roleName, roleNames) => {
  return roleNames.indexOf(roleName) > -1;
}

const getRoute = (user) => {
  const role = _.get(user, 'role.name');

  if (!role) {
    return false;
  }

  const lowerCaseRole = role.toLowerCase();
  let path = false;
  if (checkRole(lowerCaseRole, ['security', 'gatekeeper', 'gatekeeper - kino'])) {
    path = '/security';
  } else if (checkRole(lowerCaseRole, ['warehouse admin'])) {
    path = '/warehouse'
  } else if (checkRole(lowerCaseRole, ['owner', 'operator'])) {
    path = '/operator'
  }

  /**
   * override path to distributor if company type is consignee
   */
  if (_.get(user, 'company.company_type' ,'') === 'consignee') {
    path = '/distributor'
  }

  if (path) {
    ls.set('USER_ROUTE', path);
  }

  return path;
};

const LoginContainer = ({login, setLoading, user, history, location, getData, isTimeout}) => {
  const {register, handleSubmit} = useForm();
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const {loadingLogin} = user;

  const onSubmit = handleSubmit(data => {
    if (!loadingLogin) {
      data.user_type = 'admin';
      login(data).then(res => {
        const {type, payload} = res;
        if (type === `${USER_LOGIN}_SUCCESS`) {
          var {data} = payload;
          if (!data.errorMessage) {
            getData().then(res => {
              const user = _.get(res, 'payload.data');
              if (user.errorMessage) {
                setMessage(data.errorMessage);
                setShowAlert(true);
                return;
              }
              
              const route = getRoute(user);

              if (route) {
                if (route == '/distributor') {
                  getToken();
                }

                const query = location.search;
                if (location.search.includes('redirect')) {
                  let search = query.substring(1).split('&');
                  let searchObj = {};
                  _.each(search, s => {
                    let query_type = s.split('=');
                    searchObj[query_type[0]] = query_type[1];
                  });
                  history.push(searchObj.redirect);
                } else {
                  history.push(route);
                }
              } else {
                setMessage('Mohon maaf, saat ini halaman ini hanya dapat diakses oleh security/owner/operator.');
                setShowAlert(true);
              }

            });
          } else {
            setMessage(data.errorMessage);
            setShowAlert(true);
          }
        } else {
          if (res.error && res.error.data) {
            setMessage(res.error.data);
          } else {
            setMessage('Terjadi kesalahan yang tidak diketahui. Coba lagi nanti.');
          }
          setShowAlert(true);
          setLoading(false);
        }
      });
    }
  });

  return (
    <IonPage>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Login Gagal!'}
        message={message}
        buttons={['Ok']}
      />
      <IonContent>
        <div className="login">
          <div className="login-page">
            <div className="logo">
              <img className='logo-login' src={logo} alt=""/>
            </div>
            <form onSubmit={onSubmit}>
              <label htmlFor="email">No. Handphone / Email</label>
              <input type="text" ref={register} name="email"/>
              <label htmlFor="password">Password</label>
              <input type="password" ref={register} name="password"/>
              <IonButton color="primary" type="submit" disabled={loadingLogin}>
                {loadingLogin ? <IonSpinner color="light" /> : 'MASUK'}
              </IonButton>
              {!isMshippers && <div style={{textAlign: 'center'}}>
                <small>v{version}</small>
              </div>}
            </form>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapstp = ({user}) => ({
  user,
  isTimeout: user.isTimeout
});

const mapdtp = dispatch => bindActionCreators({login, setLoading, getData}, dispatch);

export default connect(mapstp, mapdtp)(LoginContainer);
