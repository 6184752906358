import React, {useState} from 'react';
import {
  IonPage,
  IonCard,
  IonContent,
  IonToolbar,
  IonModal,
  IonButton,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonFooter,
  IonSkeletonText,
  IonText,
  IonBadge,
} from '@ionic/react';
import errorImage from '../../../../assets/error.png';
import './InboundDetailScreen.scss';
import './CreateReport.scss';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {closeOrder} from '../../../../actions/w_trip';
import _ from 'lodash';
// import truckIcon from '../../../../assets/icons/truck.svg';
import CreateReport from './CreateReport';
import {show} from "../../../../actions/order";
import getShortId from "../../../../helpers/getShortId";
import moment from 'moment';

export const ConsigneeReportCard = ({item}) => {
  const status = _.get(item, 'goods_status', '');
  return (
    <div className="order-section">
      <div className="order-content">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonBadge color={status === 'unload' ? 'success' : 'danger'}>{status.toUpperCase()}</IonBadge>
            </IonCol>
            {_.get(item, 'reason_description') &&
              <IonCol>
                <strong>{_.get(item, 'reason_description')}</strong>
              </IonCol>
            }
          </IonRow>
          {_.get(item, 'dropoff_at') && 
            <IonRow>
              <IonCol>
                Estimated Arrival
              </IonCol>
              <IonCol>
                <IonText>{moment(_.get(item, 'dropoff_at')).format('DD/MM/YYYY')}</IonText>
              </IonCol>
            </IonRow>
          }
          {_.get(item, 'unload_at') && 
            <IonRow>
              <IonCol>
                Estimated Unload
              </IonCol>
              <IonCol>
                <IonText>{moment(_.get(item, 'unload_at')).format('DD/MM/YYYY')}</IonText>
              </IonCol>
            </IonRow>
          }
          {_.get(item, 'remarks') && 
            <IonRow>
              <IonCol>
                <small>Remarks:</small>
                <br/>
                {_.get(item, 'remarks', '-')}
              </IonCol>
            </IonRow>
          }
        </IonGrid>
      </div>
    </div>
  )
}

const DeliveryReportScreen = ({loading, data, error, getOrderById, id, query}) => {
  const [showModal, setShowModal] = useState(false);

  const handleSuccess = () => {
    getOrderById(id, query);
    setShowModal(false);
  }

  return (
    <IonPage style={{ paddingTop: '60px', opacity: '1' }}>
      <IonModal 
        isOpen={showModal}
        className="package-modal"
        swipeToClose={true}
        onDidDismiss={() => setShowModal(false)}
      >
        <CreateReport order={data} onCancel={() => setShowModal(false)} onSuccess={handleSuccess}/>
      </IonModal>
      <IonContent style={{textAlign: 'left'}} className="order-list">
        <div className="trip-detail">
          {!loading && error && 
            <div className={'error-information'}>
              <h3>Gagal!</h3>
              <p>Terjadi kesalahan saat mengambil data</p>
              <IonButton onClick={() => getOrderById(id, query)}>Coba Lagi</IonButton>
              {_.get(error, 'errorMessage') && <IonCard color={'danger'}>
                <code>{_.get(error, 'errorMessage', '')}</code>
              </IonCard>}
            </div>
          }
          {loading ? 
            <>
              <IonSkeletonText animated style={{width: '50%'}}/>
              <IonSkeletonText animated style={{width: '30%'}}/>
              <IonSkeletonText animated style={{width: '40%'}}/>
              <hr/>
              <IonSkeletonText animated style={{width: '80%'}}/>
            </>
          : !error && 
            <div className="order-section">
              <div className="order-content">
                <IonGrid>
                  <IonRow className="order-row">
                    <IonCol size="4" className="order-row-title">
                      Nomor Kendaraan
                    </IonCol>
                    <IonCol size="8">
                      {_.get(data, 'trip.vehicle.license_plate', 'Belum diassign')}
                    </IonCol>
                  </IonRow>
                  
                  <IonRow className="order-row">
                    <IonCol size="4" className="order-row-title">
                      Driver
                    </IonCol>
                    <IonCol size="8">
                      {_.get(data, 'trip.driver.name', 'Belum diassign')}
                    </IonCol>
                  </IonRow>
                  
                  <IonRow className="order-row">
                    <IonCol size="4" className="order-row-title">
                      Jenis Kendaraan
                    </IonCol>
                    <IonCol size="8">
                      {_.get(data, 'trip.vehicle.type.name', '-')}
                    </IonCol>
                  </IonRow>
                  
                  <IonRow className="order-row">
                    <IonCol size="4" className="order-row-title">
                      Transporter
                    </IonCol>
                    <IonCol size="8">
                      {_.get(data, 'company_transport.name', '-')}
                    </IonCol>
                  </IonRow>
                  
                  <IonRow className="order-row">
                    <IonCol size="4" className="order-row-title">
                      Trip ID
                    </IonCol>
                    <IonCol size="8">
                      {getShortId(_.get(data, 'trip.id'))}
                    </IonCol>
                  </IonRow>
                  
                  <IonRow className="order-row">
                    <IonCol size="4" className="order-row-title">
                      Shipment Number
                    </IonCol>
                    <IonCol size="8">
                      {_.get(data, 'shipment_number')}
                    </IonCol>
                  </IonRow>
                  
                  <IonRow className="order-row">
                    <IonCol size="4" className="order-row-title">
                      OBD Number
                    </IonCol>
                    <IonCol size="8">
                      {_.get(data, 'packages', []).map(p => p.obd).join(', ') || '-'}
                    </IonCol>
                  </IonRow>
                  
                  {_.get(data, 'expected_arrival') &&
                    <IonRow className="order-row">
                      <IonCol size="4" className="order-row-title">
                        Tanggal harus bongkar
                      </IonCol>
                      <IonCol size="8">
                        <IonBadge color={'danger'}>
                          <code>
                            {moment(_.get(data, 'expected_arrival')).format('DD/MM/YYYY')}
                          </code>
                        </IonBadge>
                      </IonCol>
                    </IonRow>
                  }
                </IonGrid>
              </div>
            </div>
          }
          {(!loading && !error) && _.get(data, 'consignee_reports.length', 0) === 0 && (
            <>
              <IonImg src={errorImage} className="error-image"/>
              <IonText class="error-description">
                Belum ada laporan
              </IonText>
            </>
          )}

          {!loading && _.get(data, 'consignee_reports', []).map((c, key) => 
            <ConsigneeReportCard key={key} item={c}/>
          )}
        </div>
      </IonContent>
      {!_.find(_.get(data, 'consignee_reports', []), {goods_status: 'unload'}) && 
        <IonFooter>
          <IonToolbar style={{paddingRight: 16, paddingLeft: 16}}>
            {loading && 
              <IonSkeletonText style={{width: '100%', borderStartEndRadius: 4, height: 36}}/>
            }
            {!loading && !error && 
              <IonButton 
                onClick={() => setShowModal(true)} 
                expand="block" 
              >
                Buat Laporan
              </IonButton>
            }
          </IonToolbar>
        </IonFooter>
      }
    </IonPage>
  );
}

const mapStateToProps = ({order: {inbound, orderDetail: {loading, data, error}}}) => {
  return ({
    inbound,
    loading,
    data,
    error,
  })
};

const mapDispatchToProps = dispatch => bindActionCreators({closeOrder, getOrderById: show}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryReportScreen);
