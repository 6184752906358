import React from 'react';
import _ from 'lodash';

const ContactAddress = ({ contact }) => {

  return (
    <>
      <div style={{fontWeight: '600', marginBottom: '10px'}}>
        {_.get(contact, 'name', '-')}
      </div>
      <div>
        {_.get(contact, 'location.address', '-')}
      </div>
    </>
  );
}

export default ContactAddress;