import React, { useState } from 'react';
import _ from 'lodash';
import TripIssueCard from './TripIssueCard';
import './TripIssueCards.scss';

const TripIssueCards = ({trips=[], onClick}) => {
    
    const [selectedTrip, setSelectedTrip] = useState();
    const onSelectTrip = (trip) => {
      setSelectedTrip(trip);
      onClick(trip);
    }

    const compareObj = (trip) => {
      if (selectedTrip) {
        return trip.id === selectedTrip.id;
      }
    }

    return (
    <>
      {trips.map(trip => (
        <TripIssueCard trip={trip} onClick={onSelectTrip} compareObj={compareObj} />
      ))}
    </>
    );
}

export default TripIssueCards;