import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonActionSheet, IonAlert } from '@ionic/react';
import _ from 'lodash';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { detail as getDetail, resetOrderDetail } from '../../../../actions/order';
import './OrderDetailScreen.scss';
import back from '../../../../assets/new-icons/back.svg'
import trip from '../../../../assets/new-icons/trip-orange.svg'
import right from '../../../../assets/new-icons/angle-right.svg'

const OrderDetailScreen = (props) => {
  const { data } = props;
  const [id, setID] = useState(props.match.params.id);
  const [trips, setTrips] = useState([]);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [error, setError] = useState('');

  const statuses = {
    "99": { "order": 0, "id":"99", "text": "prebooked", "description": "Customer belum melakukan pembayaran"},
    "00": { "order": 1, "id":"00", "text": "booked", "description": "Menunggu assignment ke transporter" },
    "05": { "order": 2, "id":"05", "text": "confirmed", "description": "Transporter sudah melakukan konfirmasi" },
    "07": { "order": 3, "id":"07", "text": "accepted", "description": "Driver siap melaksanakan order" },
    "10": { "order": 4, "id":"10", "text": "pickup", "description": "Driver sudah tiba di lokasi muat"},
    "20": { "order": 5, "id":"20", "text": "loaded", "description": "Barang dimuat, driver dalam perjalanan ke lokasi bongkar" },
    "30": { "order": 6, "id":"30", "text": "drop-off", "description": "Driver sudah tiba di lokasi bongkar" },
    "40": { "order": 7, "id":"40", "text": "unloaded", "description": "Barang sudah selesai dibongkar" },
    "50": { "order": 8, "id":"50", "text": "pod", "description": "Bukti pengiriman sudah diupload" },
    "60": { "order": 9, "id":"60", "text": "cancelled", "description": "Order dibatalkan"}
  }

  if (id !== props.match.params.id) {
    setID(props.match.params.id);
  };

  useEffect(() => {
    // if (id !== 'create') {
      props.getDetail(id).then(result => {
        if (_.get(result, 'payload.data.trip')) {
          setError('');
          let trip_list = [];
          trip_list.push({text: result.payload.data.trip.id});

          _.each(result.payload.data.relatedtrips, trip => trip_list.push({text: trip.id}));

          _.each(trip_list, trip => {
            trip.handler = () => {
              props.history.push(`/operator/order/${id}/track/${trip.text}`);
            }
          });

          if (trip_list.length > 1) {
            trip_list.push({
              text: 'Cancel',
              role: 'cancel',
            })
          }
          setTrips(trip_list);
        } else if (_.get(result, 'payload.data.errorMessage')) {
          setError(result.payload.data.errorMessage);
        };
      }).catch(err => {
        setError(err);
        console.error(err);
      });
    // }
  }, [id]);

  const goBack = () => {
    props.resetOrderDetail();
    props.history.push('/operator/order');
  };

  const tripDetail = () => {
    if (trips.length == 1) {
      props.history.push(`/operator/order/${id}/track/${trips[0].text}`);
    } else {
      setShowActionSheet(true);
    }
  }

  return (
    <IonPage className="operator order-detail">
      <IonAlert
        isOpen={error.length > 0}
        onDidDismiss={() => goBack()}
        header={'Error!'}
        message={error}
        buttons={['OK']}
      />
      <IonContent className="operator-content">
        <div className="operator-header">
          <span style={{filter: 'invert(1) brightness(200%)', marginRight: '25px'}} onClick={() => goBack()}><img src={back} alt="back" /></span>
          <span>Order Detail</span>
        </div>

        <div className="order-section">
          <div className="order-section-title">
            Order Detail
          </div>
          <div className="order-content">
            <IonGrid>
              <IonRow className="order-row">
                <IonCol size="3" className="order-row-title">
                  Order ID
                </IonCol>
                <IonCol size="9">
                  {data.id && `${data.id.substring(0,8)}${data.id.substring(data.id.length - 6)}`}
                </IonCol>
              </IonRow>

              <IonRow className="order-row">
                <IonCol size="3" className="order-row-title">
                  Shipment Number
                </IonCol>
                <IonCol size="9">
                  {data.shipment_number || '-'}
                </IonCol>
              </IonRow>

              <IonRow className="order-row">
                <IonCol size="3" className="order-row-title">
                  Transporter
                </IonCol>
                <IonCol size="9">
                  {_.get(data, 'company_transport.name', '-')}
                </IonCol>
              </IonRow>

              <IonRow className="order-row">
                <IonCol size="3" className="order-row-title">
                  Asal
                </IonCol>
                <IonCol size="9">
                  <div style={{fontWeight: '500', marginBottom: '5px'}}>{_.get(data, 'origin_contact_name', '-')}</div>
                  {data.origin && <div>{_.get(data, 'origin.address', '-')}</div>}
                </IonCol>
              </IonRow>

              <IonRow className="order-row">
                <IonCol size="3" className="order-row-title">
                  Tujuan
                </IonCol>
                <IonCol size="9">
                  <div style={{fontWeight: '500', marginBottom: '5px'}}>{_.get(data, 'destination_contact_name', '-')}</div>
                  {data.destination && <div>{_.get(data, 'destination.address', '-')}</div>}
                </IonCol>
              </IonRow>

              <IonRow className="order-row">
                <IonCol size="3" className="order-row-title">
                  Status
                </IonCol>
                <IonCol size="9">
                  {_.get(data, 'status.name', '-')}
                  {_.get(data, 'status_id') &&
                    <div style={{color: '#9e9e9e', fontSize: '12px', fontStyle: 'italic', marginTop: '5px'}}>
                      {_.get(statuses, `${data.status_id}.description`, '')}
                    </div>
                  }
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>

        <div className="order-section">
          {/* <Link to={`/operator/order/${id}/track`}> */}
            <div className="order-content" onClick={tripDetail}>
              <img src={trip} alt="" style={{marginRight: '15px', verticalAlign: 'sub'}} />
              <span>Lihat Detail Perjalanan</span>
              <img src={right} alt="" style={{float: 'right', width: '24px', marginTop: '4px'}} />
            </div>
          {/* </Link> */}
        </div>

        <div className="order-section">
          <div className="order-section-title">
            Info Detail
          </div>
          <div className="order-content">
            <IonGrid>
              <IonRow className="order-row">
                <IonCol size="3" className="order-row-title">
                  Estimasi Berangkat
                </IonCol>
                <IonCol size="9">
                  {_.get(data, 'trip.expected_pickup') ? moment(data.trip.expected_pickup).format('dddd, DD MMMM YYYY, HH:mm') : '-'}
                </IonCol>
              </IonRow>

              <IonRow className="order-row">
                <IonCol size="3" className="order-row-title">
                  Estimasi Tiba
                </IonCol>
                <IonCol size="9">
                  {_.get(data, 'expected_arrival') ? moment(data.expected_arrival).format('dddd, DD MMMM YYYY, HH:mm') : '-'}
                </IonCol>
              </IonRow>

              <IonRow className="order-row">
                <IonCol size="3" className="order-row-title">
                  Order No.
                </IonCol>
                <IonCol size="9">
                  {_.get(data, 'order_number', '-') || '-'}
                </IonCol>
              </IonRow>

              <IonRow className="order-row">
                <IonCol size="3" className="order-row-title">
                  Ready Time
                </IonCol>
                <IonCol size="9">
                  {_.get(data, 'ready_time') ? moment(data.ready_time).format('dddd, DD MMMM YYYY, HH:mm') : '-'}
                </IonCol>
              </IonRow>

              <IonRow className="order-row">
                <IonCol size="3" className="order-row-title">
                  Goods Issue Date
                </IonCol>
                <IonCol size="9">
                  {_.get(data, 'issued_date') ? moment(data.issued_date).format('DD MMMM YYYY') : '-'}
                </IonCol>
              </IonRow>

              <IonRow className="order-row">
                <IonCol size="3" className="order-row-title">
                  Berat
                </IonCol>
                <IonCol size="9">
                  {_.get(data, 'total_load', '0') || 0} Kg
                </IonCol>
              </IonRow>

              <IonRow className="order-row">
                <IonCol size="3" className="order-row-title">
                  Dimensi
                </IonCol>
                <IonCol size="9">
                  {_.get(data, 'total_dimension', '0') || 0} CBM
                </IonCol>
              </IonRow>

              <IonRow className="order-row">
                <IonCol size="3" className="order-row-title">
                  Jarak
                </IonCol>
                <IonCol size="9">
                  {_.get(data, 'distance', '0') || 0} Km
                </IonCol>
              </IonRow>

              <IonRow className="order-row">
                <IonCol size="3" className="order-row-title">
                  Kuantitas
                </IonCol>
                <IonCol size="9">
                  {_.get(data, 'total_quantity', '0') || 0} Unit
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>

        <IonActionSheet
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          cssClass='my-custom-class'
          buttons={trips}
        >
        </IonActionSheet>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = state => {
  return {
    data: state.order.detail
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getDetail, resetOrderDetail
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailScreen);
