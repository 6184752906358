export const CREATE = 'VEHICLE_CHECLIST_CREATE';
export const GET_ITEMS = 'VEHICLE_CHECKLIST_GET_ITEMS'
export const GET = 'VEHICLE_CHECKLIST_GET'

export const create = (data) => {
  return {
    type: CREATE,
    payload: {
      request: {
        url: '/vehicle_checklist',
        method: 'post',
        data
      }
    }
  }
}

export const getItems = () => {
  return {
    type: GET_ITEMS,
    payload: {
      request: {
        url: `/vehicle_checklist/items`,
        method: 'get',
      }
    }
  }
}

export const get = (search = '') => {
  return {
    type: GET,
    payload: {
      request: {
        url: `/trips/vehicle_checklist`,
        method: 'get',
        params: {
          search
        }
      }
    }
  }
}
