/* global google */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonPage, IonContent } from '@ionic/react';
import { SwipeableDrawer } from '@material-ui/core';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, DirectionsRenderer } from 'react-google-maps';
import _ from 'lodash';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { detail as getDetail } from '../../../../actions/order';
import { getDetail as getTrip } from '../../../../actions/trip';
import left from '../../../../assets/new-icons/left.svg';
import noavatar from '../../../../assets/no-avatar.png';
import originmarker from '../../../../assets/new-icons/origin-orange.svg';
import destinationmarker from '../../../../assets/new-icons/dest-blue.svg';
import truckmarker from '../../../../assets/new-icons/truck_top.svg';
import './OrderDetailScreen.scss';

const OrderDetailTrack = (props) => {
  const { data } = props;
  const [id, setID] = useState(props.match.params.id);
  const [idtrip, setIDTrip] = useState(props.match.params.idtrip);
  const [showStatus, setShowStatus] = useState(false);
  const [statusTimeline, setStatusTimeline] = useState([]);
  const [position, setPosition] = useState(null);
  const [origin, setOrigin] = useState({});
  const [destination, setDestination] = useState({});
  const [directions, setDirections] = useState({});
  
  const statuses = [
    { id: '00', value: 'booked' },
    { id: '05', value: 'confirmed' },
    { id: '07', value: 'accepted' },
    { id: '10', value: 'pickup' },
    { id: '20', value: 'loaded' },
    { id: '30', value: 'dropoff' },
    { id: '40', value: 'unloaded' },
    { id: '50', value: 'pod' },
    { id: '60', value: 'cancelled' },
    { id: '70', value: 'transit'}
  ]

  if (id !== props.match.params.id) {
    setID(props.match.params.id);
  };

  if (idtrip !== props.match.params.idtrip) {
    setIDTrip(props.match.params.idtrip);
  };

  useEffect(() => {
    props.getDetail(id).then(function (result) {
      let res = result.payload.data;
      if (res.id) {
        createStatusTimeline(res);

        initMap(res);
      };
    });
  }, [id]);

  useEffect(() => {
    props.getTrip(idtrip).then(function (result) {
      let location = _.get(result, 'payload.data.latest_location', null);
      
      if (location) {
        let latest_time = null;
        let latest_position = null;
        _.each(location, (loc) => {
          let time = loc.time ? moment(loc.time) : null;
          if (time) {
            if (!latest_time || moment(latest_time).isBefore(time)) {
              latest_time = time;
              latest_position = {
                lat: loc.latitude,
                lng: loc.longitude,
              }
            }
          }
        });
  
        setPosition(latest_position);
      } else {
        setPosition(null);
      }
    });
  }, [idtrip]);

  const goBack = () => {
    props.history.push('/operator/order/' + id);
  };

  const createStatusTimeline = (item) => {
    let timeline = [];
    // let is_multimoda = item.trip.has_next_trip;
    // let is_split = item.is_split_trip;
    let is_cancelled = item.status_id === '60';
    
    timeline.push(
      { type_id: '00', value: 'booked', date: item.created_at },
      { type_id: '05', value: 'confirmed', date: item.confirmed_at },
      { type_id: '07', value: 'accepted', date: item.accepted_at },
      { type_id: '10', value: 'pickup', date: item.pickup.completed_at },
      { type_id: '20', value: 'loaded', date: item.load.completed_at },
      { type_id: '30', value: 'dropoff', date: item.dropoff.completed_at },
      { type_id: '40', value: 'unloaded', date: item.unload.completed_at },
      { type_id: '50', value: 'pod', date: item.pod.completed_at }
    );

    // if (is_multimoda) {
    //   timeline.push(
    //     { type_id: '70', value: 'transit', place: _.get(item, 'trip.destination.name', '') }
    //   );

    //   _.each(item.relatedtrips, (trip, i) => {
    //     timeline.push(
    //       { type_id: '70', value: 'transit', place: _.get(trip, 'origin.name', '') },
    //       { type_id: '07', value: 'accepted', date: trip.accepted_at },
    //       { type_id: '10', value: 'pickup', date: getTask(trip.tasks, '10').completed_at },
    //       { type_id: '20', value: 'loaded', date: getTask(trip.tasks, '20').completed_at },
    //       { type_id: '30', value: 'dropoff', date: getTask(trip.tasks, '30').completed_at },
    //       { type_id: '40', value: 'unloaded', date: getTask(trip.tasks, '40').completed_at },
    //       { type_id: '50', value: 'pod', date: getTask(trip.tasks, '50').completed_at }
    //     );

    //     if (trip.has_next_trip) {
    //       timeline.push(
    //         { type_id: '70', value: 'transit', place: _.get(trip, 'destination.name', '') }
    //       );
    //     };
    //   });
    // } else if (is_split) {
    //   _.each(item.subtrips, (trip, i) => {
    //     timeline.push(
    //       { type_id: '07', value: 'accepted', date: trip.accepted_at },
    //       { type_id: '10', value: 'pickup', date: getTask(trip.tasks, '10').completed_at },
    //       { type_id: '20', value: 'loaded', date: getTask(trip.tasks, '20').completed_at },
    //       { type_id: '30', value: 'dropoff', date: getTask(trip.tasks, '30').completed_at },
    //       { type_id: '40', value: 'unloaded', date: getTask(trip.tasks, '40').completed_at },
    //       { type_id: '50', value: 'pod', date: getTask(trip.tasks, '50').completed_at }
    //     );
    //   });
    // };

    if (is_cancelled) {
      timeline = _.filter(timeline, time => !_.isNil(time.date));
      timeline.push({ type_id: '60', value: 'cancelled', date: item.canceled_at});
    };

    setStatusTimeline(timeline);
  };

  const getTask = function (tasks, type_id) {
    return _.find(tasks, task => task.type_id === type_id, {});
  };
  
  const getImage = (id, type) => {
    let res = _.find(statuses, status => status.id === id);
    let img = '';
    if (res) {
      img += res.value;
      switch (type) {
        case 'pass':
          img += '_pass.svg';
          break;
        case 'active':
          img += '_active.svg';
          break;
        case 'inactive':
          img += '_inactive.svg';
          break;
        default:
          img += '.svg';
          break;
      };
    } else {
      img += 'booked_pass.svg'
    };

    return img;
  }

  const initMap = (res) => {
    const directionsService = new google.maps.DirectionsService();

    let ori = {
      lat: parseFloat(_.get(res, 'origin.latitude', 0)),
      lng: parseFloat(_.get(res, 'origin.longitude', 0))
    }
    setOrigin(ori);

    let dest = {
      lat: parseFloat(_.get(res, 'destination.latitude', 0)),
      lng: parseFloat(_.get(res, 'destination.longitude', 0))
    }
    setDestination(dest);

    directionsService.route(
      {
        origin: ori,
        destination: dest,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }

  const google_api_key = process.env.REACT_APP_GOOGLE_API_KEY;
  const google_map_url = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=drawing&key=${google_api_key}`;
  const defaultOptions = {
    zoomControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
  }
  const directionsOptions = {
    polylineOptions: {
      strokeColor: '#008c98',
      strokeWeight: 4
    },
    preserveViewPort: true,
    suppressMarkers: true
  }

  const OrderMap = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
      defaultZoom={10}
      clickableIcons={false}
      defaultOptions={defaultOptions}
      defaultCenter={position}
    >
      {_.get(position, 'lat') && _.get(position, 'lng') &&
        <Marker position={position} icon={truckmarker} />
      }
      {origin.lat && origin.lng && 
        <Marker 
          position={origin} 
          icon={{
            url: originmarker,
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(5, 10),
          }} 
        />
      }
      {destination.lat && destination.lng && 
        <Marker 
          position={destination} 
          icon={{
            url: destinationmarker,
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(5, 10),
          }} 
        />
      }
      {directions.status === 'OK' &&
        <DirectionsRenderer
          directions={directions}
          options={directionsOptions}
        />
      }
    </GoogleMap>
  ))

  return (
    <IonPage className="operator order-detail">
      <IonContent className="operator-content">
        <div className="back-btn" onClick={goBack}>
          <img src={left} alt=""/>
        </div>

        <div className="order-detail-card-container">
          {_.get(data, 'status.name', false) &&
            <div className="status-btn" onClick={() => setShowStatus(true)}>
              <img src={require(`../../../../assets/new-icons/status/${getImage(data.status_id, '')}`)} alt=""/>
            </div>
          }
          <div className="order-detail-card">
            {_.get(data, 'trip.driver', false) &&
              <div style={{display: 'inline-flex'}}>
                <img src={_.isNil(data.trip.driver.image_url) ? noavatar : data.trip.driver.image_url} alt=''/>
                <div>
                  <div style={{fontWeight: 'bold', marginBottom: '5px'}}>{data.trip.driver.name || '-'}</div>
                  <div>{data.trip.driver.phone_number || '-'}</div>
                </div>
              </div>
            }
            <span className="order-status">{_.get(data, 'status.name', '')}</span>
            <div style={{display:'inline-flex', fontSize: '12px', marginTop: '14px', width: '100%'}}>
              <div style={{marginRight: '20px'}}>
                <div className="order-card-title">Distance</div>
                <div>{data.distance || '0'} Km</div>
              </div>
              <div>
                <div className="order-card-title">Receiver</div>
                <div>{data.destination_contact_name || '-'}</div>
              </div>
            </div>
          </div>
        </div>

        <div style={{height: '100%', width: '100%'}}>
          <OrderMap
            isMarkerShown
            googleMapURL={google_map_url}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
        <SwipeableDrawer
          anchor="right"
          open={showStatus}
          onClose={() => setShowStatus(false)}
          onOpen={() => setShowStatus(true)}
          disableSwipeToOpen={true}
          className="order-status-container"
        >
          <IonContent>
            <div className="order-status-title">Order Status</div>
            <div className="order-status-list">
              {statusTimeline.map((status, i) => {
                let type;
                // let active = _.findIndex(statusTimeline, s => _.isNil(s.date));
                if (status.type_id === data.status_id) type = 'active';
                else if (status.date) type = 'pass';
                else type = 'inactive';

                return (
                  <div key={i} className={`order-status-item ${type}`}>
                    <img src={require(`../../../../assets/new-icons/status-revamp/${getImage(status.type_id, type)}`)} alt=""/>
                    <div>
                      <div className="order-status-value" style={{textTransform: status.type_id === '50' ? 'uppercase' : 'capitalize'}}>{status.value}</div>
                      {status.date &&
                        <div className="order-status-date">{moment(status.date).format('DD MMMM YYYY, HH:mm')}</div>
                      }
                      {status.place &&
                        <div className="order-status-date">{status.place}</div>
                      }
                    </div>
                  </div>
                )
              })}
            </div>
          </IonContent>
        </SwipeableDrawer>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = state => {
  return {
    data: state.order.detail
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getDetail, getTrip
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailTrack);