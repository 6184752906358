import {hot} from 'react-hot-loader/root'
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  IonPage,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import routes from './routes';
import { Provider } from 'react-redux';
import store from '../store';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { getToken } from '../firebase';


const App = () => {
  getToken();

  return (
    <Provider store={store}>
      <IonApp>
        <IonPage>
          <IonReactRouter>
            <IonRouterOutlet>
              <Route path="/" exact render={() => <Redirect to="/login" />} />
              {routes.map((route, key) => <Route key={key} {...route} />)}
            </IonRouterOutlet>
          </IonReactRouter>
        </IonPage>
      </IonApp>
    </Provider>
  );
}

export default hot(App);
