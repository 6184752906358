/* eslint-disable */
import React from 'react';
import {IonCard, IonCardContent, IonRow, IonCol } from '@ionic/react';
import _ from 'lodash';
import truckIcon from '../../../../assets/truck-icon.svg';
import moment from 'moment';

const NotificationCard = (props) => {

    const { notif, onClickCard } = props;

    const getDifferenceTime = (date) => {
      if (date) {
        let now = moment.utc(new Date())
        let comparisonTime = moment.utc(date)
        let remainingTime = moment.duration(now.diff(comparisonTime))._data
        let result = ''

        if (remainingTime.years == 0 && remainingTime.months == 0 && remainingTime.days == 0) {
          if (remainingTime.hours) {
            result += `${ remainingTime.hours } jam`;
          } else if (remainingTime.minutes) {
            result += ` ${ remainingTime.minutes } menit`;
          } else if (remainingTime.seconds) {
            result += ` ${ remainingTime.seconds } detik`;
          }

          result += ` yang lalu`;
        } else {
          result = moment(date).format('DD MMM YYYY, hh:mm a');
        }

        return result;
      }

      return '-';
    }

    const isNotificationRead = (notif) => {
      return notif.read_at ? true : false;
    }

    return (
    <>
      <IonCard key={notif.id} onClick={() => onClickCard(notif)} >
        <IonCardContent style={{ backgroundColor: isNotificationRead(notif) ? '#ffffff' : '#edfbf9'}}>
          <IonRow>
            <IonCol size="3"><img src={truckIcon} style={{padding: '3%'}} /></IonCol>
            <IonCol size="9">
              <p>
                <span style={{fontWeight: 600}}>{ _.get(notif, 'notification_data.title') }</span>
              </p>
              <p>{ _.get(notif, 'notification_data.body') }</p>
              <p className="ion-float-right" style={{fontStyle: 'italic'}}>{ getDifferenceTime(_.get(notif, 'created_at')) }</p>
            </IonCol>
          </IonRow>
        </IonCardContent>
      </IonCard>
    </>
    );
}

export default NotificationCard;