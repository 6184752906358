import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    IonButtons,
    IonHeader,
    IonLabel,
    IonMenuButton,
    IonPage,
    IonContent,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonToggle,
    IonToast,
    IonSpinner,
    IonButton,
    IonListHeader
} from "@ionic/react";
import { getAllTopics, createOrUpdate } from '../../../../actions/notification';
import { getUserProfile } from '../../../../actions/user';
import _ from 'lodash';
import back from '../../../../assets/new-icons/back.svg';

const NotificationSettingScreen = props => {

    const { isLoading, history } = props;
    const [dictTopics, setDictTopics] = useState(props.topics)
    const [user, setUser] = useState(props.user)
    const [topics, setTopics] = useState([])
    const [showToast, setShowToast ] = useState(false)
    const [toastMessage, setToastMessage ] = useState(null)
    const topicsDict = {
      'webNotification' : 'Web Push',
      'email': 'Email',
      'whatsapp': 'Whatsapp',
      'tmsLiteNotification': 'TMS Lite'
    }

    const init = () => {
      props.getAllTopics();
      if (props.user_id) {
        props.getUserProfile(props.user_id);
      }
    }

    useEffect(() => {
      init()
    }, []);
    
    useEffect(() => {
      setDictTopics(props.topics);
    }, [props.topics])

    useEffect(() => {
      setUser(props.user);
    }, [props.user])

    useEffect(() => {
      if (user && dictTopics && dictTopics.length > 0) {
        let allTopics = _.cloneDeep(dictTopics);
        let subscribed_topic_notif = _.get(user, 'notif_topics', []);

        allTopics.forEach(topic => {
          let subscribed_topic = subscribed_topic_notif.find(topic_notif => topic_notif.topic == topic.topic);
          topic.description = _.split(topic.description, '(')[0]

          if (subscribed_topic) {
            topic.initialValue = true;
            topic.checked = true;
          } else {
            topic.initialValue = false;
            topic.checked = false;
          }
        })
        const groupedTopics = _.groupBy(allTopics, 'channels')

        setTopics(groupedTopics)
      }
    }, [dictTopics, user])

    const onChangeToggle = (topic) => {
      topic.checked = !topic.checked;
      let payload = {
        add: [],
        delete: []
      }

      if (topic.checked && !topic.initialValue) {
        payload.add.push(topic.topic);
      } else if (!topic.checked && topic.initialValue) {
        payload.delete.push(topic.topic);
      }

      props.createOrUpdate(payload)
        .then((result) => {
          if (props.user_id) {
            props.getUserProfile(props.user_id);
          }

          if (payload.add.length > 0) {
            let arrayAdd = payload.add;
            if (arrayAdd[0].includes('Email')) {
              setToastMessage(`Notifikasi email akan dikirimkan ke ${user.email}`);
              setShowToast(true);
            }
          }
        })
    }

    const goBack = () => {
      history.goBack();
    }


    return (
      <IonPage>
          <IonHeader>
              <IonToolbar className="ritase-toolbar" color="primary">
                  <IonButtons slot="start">
                    <IonButton onClick={ () => goBack() }>
                      <span style={{filter: 'invert(1) brightness(200%)', marginRight: '25px'}}><img src={back} alt="back" /></span>
                    </IonButton>
                  </IonButtons>
                  <IonTitle>Pengaturan Notifikasi</IonTitle>
              </IonToolbar>
          </IonHeader>
          <IonContent style={{textAlign: 'left'}}>
            <ion-text className="ion-margin" color="medium">
              <h5 style={{ margin: '16px 16px 10px' }}>Atur notifikasi yang ingin anda terima mengenai shipment anda di Ritase</h5>
            </ion-text>
            { topics && Object.keys(topics).length > 0 && !isLoading ?
                Object.keys(topics).map((topic) => (
                  <IonList style={{ marginTop: '15px' }}>
                    <IonListHeader style={{ minHeight: '20px', fontWeight: 'bold', fontSize: '16px', textTransform: 'capitalize' }}>
                      <IonLabel>{ _.get(topicsDict, topic, topic) }</IonLabel>
                    </IonListHeader>
                    {topics[topic].map(item => (
                      <IonItem key={item.id}>
                        <IonLabel className="ion-text-wrap">{ item.description }</IonLabel>
                        <IonToggle checked={item.checked} onIonChange={ e => onChangeToggle(item)} slot="end"></IonToggle>
                      </IonItem>
                    ))}
                  </IonList>
                ))
              : isLoading ?
                <IonSpinner name="crescent" style={{width: '100%'}}/>
              : ''
            }
          </IonContent>

          <IonToast
            isOpen={showToast}
            onDidDismiss={() => { setShowToast(false); setToastMessage(null);} }
            message={toastMessage}
            color="light"
            position="bottom"
            duration={4000}
            />
      </IonPage>
    )
}

const mapStateToProps = state => {
  
  let topics = state.notification.getTopics.data
  let user_id = state.user.data.id
  let user = state.user.profile.data
  let isLoading = state.notification.getTopics.isLoading || state.user.profile.isLoading || state.notification.createOrUpdateTopic.isLoading
  
  return {
    topics,
    user_id,
    user,
    isLoading
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getAllTopics, createOrUpdate, getUserProfile
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettingScreen);