import firebase from 'firebase';
import 'firebase/messaging';
import axios from 'axios';
import store from './store';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain:  process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
}

// firebase.initializeApp(firebaseConfig);

// const messaging = firebase.messaging();
let messaging = null

if (firebase.messaging.isSupported()) {
  firebase.initializeApp(firebaseConfig)
  messaging = firebase.messaging()
}

const updateWebToken = (token) => {
  let authorization = store.getState().user && store.getState().user.token
  let savedToken = store.getState().user && store.getState().user.web_push_token

  if (token && token != savedToken && authorization) {
    let axiosConfig = {
      baseURL: process.env.REACT_APP_API_URL,
      responseType: 'json',
      headers: { 'Authorization': authorization }
    }

    const client = axios.create(axiosConfig);

    let payload = {
      web_push_token: token
    }

    client.post('/users/web_push_token', payload);
  }
}

export const getToken = () => {
  function getToken () {
    return messaging.getToken()
      .then(firebaseToken => {
        updateWebToken(firebaseToken);
        return firebaseToken;
      })
      .catch(err => {
        console.log('An error occurred while retrieving token. ', err);
      })
  }

  new Promise((resolve, reject) => {
    if (Notification.permission === 'granted') {
      return getToken()
       .then(firebaseToken => {
          console.log('get token for client');
          resolve(firebaseToken)
       });
    } else {
      Notification.requestPermission()
        .then(permission => {
          if (permission == 'granted') {
            return getToken()
              .then(firebaseToken => {
                  console.log('get token for client');
                  resolve(firebaseToken)
              });
          }
        })
    }
  })
}

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       resolve(payload);
//     });
// });