import React, { useEffect, useState } from 'react';
import { IonPage, IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton,IonFooter, IonBackButton, IonIcon} from '@ionic/react';
import './VehicleChecklistScreen.scss';
import {connect} from 'react-redux';
import {logout} from '../../../../actions/user';
import {bindActionCreators} from 'redux';
import { detail as getDetail } from '../../../../actions/order';
import { create, getItems, get as getHistory } from '../../../../actions/vehicle_checklist';
import { OrderCard } from './OrderCard';
import Preloader from '../../../../components/Preloader'
import { home } from 'ionicons/icons'
import { Link } from 'react-router-dom';
import _ from 'lodash'
import { ModalVehicleChecklist } from './ModalVehicleChecklist';
import { VehicleChecklistStatus } from './VehicleChecklistStatus';
import Swal from 'sweetalert2'

const DetailVehicleChecklistScreen = (props) => {
  //needed to fecth data from api
  const id = props.match && props.match.params && props.match.params.id
  const params = "&is_trucktobee_order=1"

  const [isOpenModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [vehicleChecklistHistory, setVehicleChecklistHistory] = useState([])
  const [latestvehicleChecklistHistory, setLatestvehicleChecklistHistory] = useState()
  const [modalMode, setModalMode] = useState('manage')
  const [options, setOptions] = useState([])
  const [formVehicleChecklist, setFormVehicleChecklist] = useState({
    items: {},
    status: ''
  })
  const [selectedVehicleChecklistHistory, setSelectedVehicleChecklistHistory] = useState()
  
  const vehicleStatusOptions = [
    {
      id: 'reject',
      label: 'Ditolak'
    },
    {
      id: 'accept',
      label: 'Diterima'
    }
  ]
  
  useEffect(() => {
    (async () => {
      await props.getItems().then(res => {
        const items = res && res.payload && res.payload.data
        setOptions(items)
      })
    
      await props.getDetail(id, 0, params).then(async (res) => {
        const detailOrder = await _.get(res, 'payload.data')
        if (detailOrder !== {}) {
          await fetchVehicleChecklistHistory(detailOrder);
        }
      })
      
      setLoading(false)
    })();
  }, [])

  const fetchVehicleChecklistHistory = async (detailOrder) => {
    const shipment_number = await _.get(detailOrder, 'shipment_number')  
    const company_transport = await _.get(detailOrder, 'company_transport')

    if (!shipment_number && !company_transport) {
      return;
    }

    const filteredHistory = await props.getHistory(shipment_number).then(async (res) => {
      let responseHistory = await _.get(res, 'payload.data.rows')
      return responseHistory.filter(item => item.transporter.name === company_transport.name)
    })

    const data = await _.first(filteredHistory)

    if (data && data.vehicle_checklist && data.vehicle_checklist.length) {
      setVehicleChecklistHistory(data.vehicle_checklist)
      setLatestvehicleChecklistHistory(data.vehicle_checklist[0])
    }
    
    
  }

  //handle change dynamic state vehicle checklist options
  const handleChangeChecklist = (label, checked) => {
    setFormVehicleChecklist((prev) => ({
      ...prev,
      items: {
        ...prev.items,
        [label]: checked
      }
    }))
  }

  const handleChangeStatus = (status) => {
    setFormVehicleChecklist((prev) => ({
      items: prev.items,
      status: status
    }))
  }

  const onClickVehicleChecklistStatus = (item) => {
    setSelectedVehicleChecklistHistory(item)
    setModalMode('view')
    toggleModal()
  }

  const toggleModal = () => {
    setOpenModal(!isOpenModal)
  }

  const onClickSubmit = () => {
    toggleModal();

    if (props.detail && !props.detail.trip_id) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Trip ID tidak valid!'
      })
    } else if (!formVehicleChecklist.status) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Pilih satu vehicle status!',
      })
    } else {
      Swal.fire({
        icon: 'question',
        title: 'Konfirmasi',
        text: formVehicleChecklist.status === 'reject' ? 'Apakah anda yakin?' : 'Dengan status diterima sudah tidak dapat melaporkan kembali',
        showCancelButton: true,
        confirmButtonText: formVehicleChecklist.status === 'reject' ? 'Ya' : 'OK'
      }).then(result => {
        if (result.value) submitVehicleChecklist()
      })
    }
  }

  const submitVehicleChecklist = async () => {
    setLoading(true)
    //generate array of string from checked options
    const checkedOptions = Object.keys(formVehicleChecklist.items).map((label) => {
      if (formVehicleChecklist.items[label]) {
        return label
      }
    }).filter(item => item)

    const payload = {
      shipment_number: props.detail && props.detail.shipment_number,
      trip_id: props.detail && props.detail.trip_id,
      company_transport_id: props.detail && props.detail.company_transport_id,
      vehicle_id: props.detail && props.detail.trip && props.detail.trip.vehicle_id,
      driver_id: props.detail && props.detail.trip && props.detail.trip.driver_id,
      items: checkedOptions,
      status: formVehicleChecklist.status === 'accept' ? 'accept' : 'reject'
    }

    const response = await props.create(payload)
    const responseData = await _.get(response, 'payload.data')

    setLoading(false)

    if (responseData && responseData.errorMessage) { 
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: responseData.errorMessage,
      })
    } else {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: formVehicleChecklist.status === 'reject' ? 'Driver berhasil ditolak' : 'Driver berhasil diterima',
      })
    }

    props.getDetail(id, 0, params).then(async (res) => {
      const detailOrder = await _.get(res, 'payload.data')
      await fetchVehicleChecklistHistory(detailOrder);
    })
  }

  return (
    <IonPage className="vehiclechecklistscreen">
      <IonHeader>
        <IonToolbar className="ritase-toolbar" color="primary">
          <IonButtons slot="start">
            <IonBackButton text={''} defaultHref='/security/vehicle-checklist'/>
          </IonButtons>
          <IonTitle style={{ textAlign: 'left', marginLeft: '-30px'}}>Vehicle Checlist</IonTitle>
          <IonButtons slot='end'>
            <Link to='/security/home'>
              <IonIcon icon={home} mode="ios" className="ion-margin-end" color='light' size="large"/>
            </Link>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {loading && <Preloader />}

        {!loading && props.detail && <OrderCard order={props.detail} />}
        
        {!loading && vehicleChecklistHistory && vehicleChecklistHistory.length
          ? vehicleChecklistHistory.map((item, key) => (<VehicleChecklistStatus clickable onClick={()=>onClickVehicleChecklistStatus(item)} key={key} item={item} />))
          : ''
        }

        {
          !loading && 
          <ModalVehicleChecklist
            isOpenModal={isOpenModal}
            toggleModal={toggleModal}
            loading={loading}
            options={options}
            handleChangeChecklist={handleChangeChecklist}
            handleChangeStatus={handleChangeStatus}
            vehicleStatusOptions={vehicleStatusOptions}
            formVehicleChecklist={formVehicleChecklist}
            selectedVehicleChecklistHistory={selectedVehicleChecklistHistory}
            modalMode={modalMode}
            onClickSubmit={onClickSubmit}
            setModalMode={setModalMode}
          />
        }
        
      </IonContent>
      <IonFooter>
        {
          !loading && !latestvehicleChecklistHistory &&
          <IonButton disabled={loading} expand='block' className='ion-margin' color='primary' onClick={toggleModal}>Vehicle Checklist</IonButton>
        }

        {
          !loading && latestvehicleChecklistHistory && latestvehicleChecklistHistory.status !== 'accept' && latestvehicleChecklistHistory.status !== 'reported' &&
          <IonButton expand='block' className='ion-margin' color='primary' onClick={toggleModal}>Vehicle Checklist</IonButton>
        }
      </IonFooter>
    </IonPage>
  );
}
const mapstp = state => {
  const { user } = state
  const { detail } = state.order

  return {
    detail,
    user
  }
};

const mapdtp = dispatch => {
  return bindActionCreators({getDetail, create, getItems, getHistory, logout}, dispatch);
}

export default connect(mapstp, mapdtp)(DetailVehicleChecklistScreen);
