import React from 'react';
import styled from 'styled-components';
import {IonSpinner} from '@ionic/react';

const Preloader = styled.div`
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default () => (
  <Preloader><IonSpinner color="light" /></Preloader>
)
