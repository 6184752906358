import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonActionSheet, IonButton, IonSkeletonText, IonCard, IonModal } from '@ionic/react';
import _ from 'lodash';
import moment from 'moment';
import trip from '../../../../assets/new-icons/trip-orange.svg'
import right from '../../../../assets/new-icons/angle-right.svg'
import packages from '../../../../assets/new-icons/packages.svg'
import { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import CreateReport from './CreateReport';

const OrderDetailScreen = (props) => {
  const { data, id, loading, error, getOrderById, query } = props;
  const [trips, setTrips] = useState([]);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [statusName, setStatusName] = useState('');
  const [statusDescription, setStatusDescription] = useState('');

  useEffect(() => {
    let trip_list = [];
    if (data && data.trip) {
      trip_list.push({text: data.trip.id});
  
      _.each(data.relatedtrips, trip => trip_list.push({text: trip.id}));
  
      _.each(trip_list, trip => {
        trip.handler = () => {
          props.history.push(`/distributor/inbound/detail/${id}/track/${trip.text}`);
        }
      });
  
      if (trip_list.length > 1) {
        trip_list.push({
          text: 'Cancel',
          role: 'cancel',
        })
      }
      setTrips(trip_list);
    }

    getActiveStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const statuses = {
    "99": { "order": 0, "id":"99", "text": "prebooked", "description": "Customer belum melakukan pembayaran"},
    "00": { "order": 1, "id":"00", "text": "booked", "description": "Menunggu assignment ke transporter" },
    "05": { "order": 2, "id":"05", "text": "confirmed", "description": "Transporter sudah melakukan konfirmasi" },
    "07": { "order": 3, "id":"07", "text": "accepted", "description": "Driver siap melaksanakan order" },
    "10": { "order": 4, "id":"10", "text": "pickup", "description": "Driver sudah tiba di lokasi muat"},
    "20": { "order": 5, "id":"20", "text": "loaded", "description": "Barang dimuat, driver dalam perjalanan ke lokasi bongkar" },
    "30": { "order": 6, "id":"30", "text": "drop-off", "description": "Driver sudah tiba di lokasi bongkar" },
    "40": { "order": 7, "id":"40", "text": "unloaded", "description": "Barang sudah selesai dibongkar" },
    "50": { "order": 8, "id":"50", "text": "pod", "description": "Bukti pengiriman sudah diupload" },
    "60": { "order": 9, "id":"60", "text": "cancelled", "description": "Order dibatalkan"}
  }

  const tripDetail = () => {
    if (trips.length === 1) {
      props.history.push(`/distributor/inbound/detail/${id}/track/${trips[0].text}`);
    } else {
      setShowActionSheet(true);
    }
  }
  
  const packageDetail = () => {
    props.history.push(`/distributor/inbound/detail/${id}/package`);
  }

  const getTotalQuantity = () => {
    let total = 0
    if (_.get(data, 'packages.length', 0) > 0) {
      _.each(data.packages, item => {
        let qty = 0
        if (item.delivered_quantity) {
          qty = item.delivered_quantity
        } else if (item.actual_quantity) {
          qty = item.actual_quantity
        } else {
          qty = item.quantity || 0
        }

        total += qty
      })
    }
    return total
  }

  const handleSuccess = () => {
    getOrderById(id, query);
    setShowModal(false);
  }

  const getActiveStatus = () => {
    let is_multimode = _.get(data, 'trip.has_next_trip', false);
    let status_id = _.get(data, 'status_id');
    const trip = _.get(data, 'trip', {}) || {};
    const relatedtrips = _.get(data, 'relatedtrips', []) || [];
    let name = _.get(data, 'status.name', '-');
    let description = _.get(statuses, `${status_id}.description`, '');

    if (!is_multimode || ['99', '00', '05', '60'].includes(status_id)) {
      setStatusName(name)
      setStatusDescription(description)
    } else {
      const tasks = []
      tasks.push({
        type_id: '07',
        completed_at: _.get(trip, 'accepted_at'),
        leg: _.get(trip, 'leg', 0) || 0
      })
      const mainTasks = _.get(trip, 'tasks', [])
      _.each(mainTasks, task => {
        task.leg = _.get(trip, 'leg', 0) || 0
      })
      tasks.push(...mainTasks)

      _.each(relatedtrips, (relatedtrip, i) => {
        tasks.push({
          type_id: '07',
          completed_at: _.get(relatedtrip, 'accepted_at'),
          leg: _.get(relatedtrip, 'leg', 0) || 0
        })

        const relatedTasks = _.get(relatedtrip, 'tasks', [])
        _.each(relatedTasks, task => {
          task.leg = _.get(relatedtrip, 'leg', i) || i
        })
        tasks.push(...relatedTasks)
      })
      
      const currentTask = _.findLast(tasks, task => !_.isNil(task.completed_at));
      status_id = _.get(currentTask, 'type_id');
      name = _.upperCase(_.get(statuses, `${status_id}.text`, ''));
      description = _.get(statuses, `${status_id}.description`, '');
      switch (status_id) {
        case '07':
          setStatusName(`${name} ${currentTask.leg > 0 ? `LEG ${currentTask.leg}` : ''}`)
          setStatusDescription(description)
          break;
        case '10':
          setStatusName(`${name} ${currentTask.leg > 0 ? `LEG ${currentTask.leg}` : ''}`)
          setStatusDescription(`Driver sudah tiba di lokasi ${currentTask.leg > 0 ? 'transit' : 'muat'}`)
          break;
        case '20':
          setStatusName(`${name} ${currentTask.leg > 0 ? `LEG ${currentTask.leg}` : ''}`)
          setStatusDescription(`Barang dimuat, driver dalam perjalanan ke lokasi ${currentTask.leg > 0 ? 'bongkar' : 'transit'}`)
          break;
        case '30':
          setStatusName(`${name} ${currentTask.leg > 0 ? '' : 'TRANSIT'}`)
          setStatusDescription(`Driver sudah tiba di lokasi ${currentTask.leg > 0 ? 'bongkar' : 'transit'}`)
          break;
        case '40':
          setStatusName(`${name} ${currentTask.leg > 0 ? '' : 'TRANSIT'}`)
          setStatusDescription(`Barang sudah selesai ${currentTask.leg > 0 ? 'dibongkar' : 'bongkar untuk transit'}`)
          break;
        case '50':
          setStatusName(`${name} ${currentTask.leg > 0 ? '' : 'TRANSIT'}`)
          setStatusDescription(`Bukti ${currentTask.leg > 0 ? 'pengiriman' : 'transit'} sudah diupload`)
          break;
      
        default:
          setStatusName(name)
          setStatusDescription(description)
          break;
      }
    }
  }

  return (
    <IonPage className="order-detail" style={{ paddingTop: '60px', opacity: '1' }}>
      <IonModal 
        isOpen={showModal}
        className="package-modal"
        swipeToClose={true}
        onDidDismiss={() => setShowModal(false)}
      >
        <CreateReport
          order={data}
          onCancel={() => setShowModal(false)}
          onSuccess={handleSuccess}
          showUnloadOnly={true}
        />
      </IonModal>

      {!loading && error && 
        <div className={'error-information'}>
          <h3>Gagal!</h3>
          <p>Terjadi kesalahan saat mengambil data</p>
          <IonButton onClick={() => getOrderById()}>Coba Lagi</IonButton>
          {_.get(error, 'errorMessage') && <IonCard color={'danger'}>
            <code>{_.get(error, 'errorMessage', '')}</code>
          </IonCard>}
        </div>
      }

      {loading ? 
        <>
          <IonSkeletonText animated style={{width: '50%'}}/>
          <IonSkeletonText animated style={{width: '30%'}}/>
          <IonSkeletonText animated style={{width: '40%'}}/>
          <hr/>
          <IonSkeletonText animated style={{width: '80%'}}/>
        </>
      : !error && 
        <IonContent>
          <div className="order-section" style={{ marginBottom: '2px' }}>
            <div className="order-content">
              <IonGrid>
                <IonRow className="order-row">
                  <IonCol size="4" className="order-row-title">
                    Order ID
                  </IonCol>
                  <IonCol size="8">
                    {_.get(data, 'id') && `${data.id.substring(0,8)}${data.id.substring(data.id.length - 6)}`}
                  </IonCol>
                </IonRow>

                <IonRow className="order-row">
                  <IonCol size="4" className="order-row-title">
                    Shipment Number
                  </IonCol>
                  <IonCol size="8">
                    {_.get(data, 'shipment_number', '-')}
                  </IonCol>
                </IonRow>

                <IonRow className="order-row">
                  <IonCol size="4" className="order-row-title">
                    Transporter
                  </IonCol>
                  <IonCol size="8">
                    {_.get(data, 'company_transport.name', '-')}
                  </IonCol>
                </IonRow>

                <div className="list-location">
                  <IonRow className="order-row list-location-item">
                    <IonCol size="4" className="order-row-title">
                      Asal
                    </IonCol>
                    <IonCol size="8" className="list-location-address">
                      <div style={{fontWeight: '500', marginBottom: '5px'}}>{_.get(data, 'origin_contact_name', '-')}</div>
                      {(data && data.origin) && <div>{_.get(data, 'origin.address', '-')}</div>}
                    </IonCol>
                  </IonRow>

                  <IonRow className="order-row list-location-item">
                    <IonCol size="4" className="order-row-title">
                      Tujuan
                    </IonCol>
                    <IonCol size="8" className="list-location-address">
                      <div style={{fontWeight: '500', marginBottom: '5px'}}>{_.get(data, 'destination_contact_name', '-')}</div>
                      {(data && data.destination) && <div>{_.get(data, 'destination.address', '-')}</div>}
                    </IonCol>
                  </IonRow>
                </div>

                <IonRow className="order-row">
                  <IonCol size="4" className="order-row-title">
                    Status
                  </IonCol>
                  <IonCol size="6">
                    { statusName }
                    <div style={{color: '#9e9e9e', fontSize: '12px', fontStyle: 'italic', marginTop: '5px'}}>
                      { statusDescription }
                    </div>
                    {/* {_.get(data, 'status.name', '-')}
                    {_.get(data, 'status_id') &&
                      <div style={{color: '#9e9e9e', fontSize: '12px', fontStyle: 'italic', marginTop: '5px'}}>
                        {data && _.get(statuses, `${data.status_id}.description`, '')}
                      </div>
                    } */}
                  </IonCol>
                  <IonCol size="2" style={{ textAlign: 'right' }}>
                    <Link to={`/distributor/inbound/detail/${id}/status`} style={{ textDecoration: 'none' }}>
                      Lihat
                    </Link>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>

          <div className="order-section" style={{ marginBottom: '15px' }}>
            <div className="order-content" onClick={tripDetail} style={{cursor: 'pointer'}}>
              <img src={trip} alt="" style={{marginRight: '15px', verticalAlign: 'sub'}} />
              <span style={{marginLeft: '9px'}}>Lihat Detail Perjalanan</span>
              <img src={right} alt="" style={{float: 'right', width: '9px', marginTop: '4px'}} />
            </div>
          </div>

          <div className="order-section" style={{ marginBottom: '2px' }}>
            <div className="order-section-title">
              Info Detail
            </div>
            <div className="order-content">
              <IonGrid>
                <IonRow className="order-row">
                  <IonCol size="4" className="order-row-title">
                    Waktu Pengiriman
                  </IonCol>
                  <IonCol size="8">
                    {_.get(data, 'load.completed_at') ?
                      moment(data.load.completed_at).format('dddd, DD MMMM YYYY, HH:mm')
                    : _.get(data, 'ready_time') ? 
                      moment(data.ready_time).format('dddd, DD MMMM YYYY, HH:mm') 
                    : 
                      '-'
                    }
                  </IonCol>
                </IonRow>

                <IonRow className="order-row">
                  <IonCol size="4" className="order-row-title">
                    Estimasi Tiba
                  </IonCol>
                  <IonCol size="8">
                    {_.get(data, 'expected_arrival') ? moment(data.expected_arrival).format('dddd, DD MMMM YYYY, HH:mm') : '-'}
                  </IonCol>
                </IonRow>

                <IonRow className="order-row">
                  <IonCol size="4" className="order-row-title">
                    Order No.
                  </IonCol>
                  <IonCol size="8">
                    {_.get(data, 'order_number', '-') || '-'}
                  </IonCol>
                </IonRow>

                <IonRow className="order-row">
                  <IonCol size="4" className="order-row-title">
                    Jumlah Package
                  </IonCol>
                  <IonCol size="8">
                    {_.get(data, 'packages.length', 0) || 0} Packages
                  </IonCol>
                </IonRow>

                <IonRow className="order-row">
                  <IonCol size="4" className="order-row-title">
                    Kuantitas
                  </IonCol>
                  <IonCol size="8">
                    {getTotalQuantity()} Unit
                  </IonCol>
                </IonRow>

                <IonRow className="order-row">
                  <IonCol size="4" className="order-row-title">
                    Berat
                  </IonCol>
                  <IonCol size="8">
                    {_.get(data, 'total_load', '0') || 0} Kg
                  </IonCol>
                </IonRow>

                <IonRow className="order-row">
                  <IonCol size="4" className="order-row-title">
                    Dimensi
                  </IonCol>
                  <IonCol size="8">
                    {_.get(data, 'total_dimension', '0') || 0} CBM
                  </IonCol>
                </IonRow>

                <IonRow className="order-row">
                  <IonCol size="4" className="order-row-title">
                    Jarak
                  </IonCol>
                  <IonCol size="8">
                    {_.get(data, 'distance', '0') || 0} Km
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>

          <div className="order-section" style={{ marginBottom: '15px' }}>
            <div className="order-content" onClick={packageDetail} style={{cursor: 'pointer'}}>
              <img src={packages} alt="" style={{marginRight: '15px', verticalAlign: 'middle'}} />
              <span style={{verticalAlign: 'middle'}}>Lihat Muatan</span>
              <img src={right} alt="" style={{float: 'right', width: '9px', marginTop: '4px'}} />
            </div>
          </div>
          
          {!_.find(_.get(data, 'consignee_reports', []), {goods_status: 'unload'}) && 
            <div className="order-section" style={{ padding: '10px 16px' }}>
              <IonButton 
                onClick={() => setShowModal(true)} 
                expand="block" 
              >
                Barang sudah diterima
              </IonButton>
            </div>
          }

          <IonActionSheet
            isOpen={showActionSheet}
            onDidDismiss={() => setShowActionSheet(false)}
            cssClass='my-custom-class'
            buttons={trips}
          >
          </IonActionSheet>
        </IonContent>
      }
    </IonPage>
  );
};

const mapStateToProps = ({order: {inbound, orderDetail: {loading, data, error}}}) => {
  return ({
    inbound,
    loading,
    data,
    error,
  })
};

export default connect(mapStateToProps)(withRouter(OrderDetailScreen));