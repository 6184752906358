import React, {useState, useEffect} from 'react';
import { IonPage, IonContent, IonHeader, IonToolbar, IonModal, IonButton, IonButtons, IonTitle, IonBackButton, IonSearchbar, IonSpinner, IonImg, IonText, IonGrid, IonRow, IonCol, IonFooter} from '@ionic/react';
// import IonRouteRedirect from '@ionic/react-router'
import errorImage from '../../../../assets/error.png';
import './TripDetail.scss';
import './UpdateQuantityModal.scss';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import OrderCards from './OrderCards';
import {useParams} from 'react-router-dom';
import {getTripById, closeOrder} from '../../../../actions/w_trip';
import _ from 'lodash';
import truckIcon from '../../../../assets/icons/truck.svg';
import UpdateQuantityModal from './UpdateQuantityModal'; 

const TripDetailScreen = ({trip, isLoading, errorMessage, modalOrder, getTripById, closeOrder}) => {
  const [searchText, setSearchText] = useState('');
  const [displayedOrders, setDisplayedOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleChange = e => {
    setSearchText(e.target.value);
  };
  const {id} = useParams();
  const getShortId  = (id) => {
    if (!id) {
      return '';
    }
    return id.slice(0,8) + id.slice(22);
  };

  useEffect(() => {
    getTripById(id).then(data => {
  });
  }, []);

  useEffect(() => {
    if (trip && trip.orders) {
      setDisplayedOrders(trip.orders);
    }
  }, [trip]);

  useEffect(() => {
    if (trip && trip.orders && searchText) {
      const query = searchText.toLowerCase();
      const filteredOrders = trip.orders.filter(order => (
        (order.shipment_number && order.shipment_number.includes(query)) ||
        order.id.includes(query) ||
        order.packages.some(pkg => 
          (pkg.sku && pkg.sku.includes(query))
          || (pkg.external_number && pkg.external_number.includes(query))
          // || (pkg.customer_number && pkg.customer_number.includes(query))
          // || (pkg.order_number && pkg.order_number.includes(query))
        )
      ));
      setDisplayedOrders(filteredOrders);
    };
  }, [searchText]);
  
  useEffect(() => {
    if (modalOrder && modalOrder.id) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [modalOrder]);

  return (
  <IonPage>
    <IonModal isOpen={showModal} className="package-modal" swipeToClose={true} onDidDismiss={closeOrder}>
      <UpdateQuantityModal order={modalOrder}/>
    </IonModal>
    <IonHeader>
      <IonToolbar className="ritase-toolbar">
        <IonButtons slot="start">
          <IonBackButton defaultHref="/warehouse/trips" color="primary"></IonBackButton>
        </IonButtons>
        <IonTitle color="primary">Delivery Notes List</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent style={{textAlign: 'left'}} className="order-list">
      
      <div className="trip-detail"> 
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonImg className="truck-icon" alt="Truck icon" src={truckIcon}/>
              <span className="card-title"><strong>{_.get(trip, 'vehicle.license_plate', 'Belum diassign')}</strong></span>
            </IonCol>
            {/* <IonCol className="trip-id">
              <span className="card-title">{getShortId(_.get(trip, 'id'))}</span>
            </IonCol> */}
          </IonRow>
        </IonGrid>
        Driver: <strong>{_.get(trip, 'driver.name', 'Belum diassign')}</strong> <br/>
        Jenis Kendaraan: <strong>{_.get(trip, 'vehicle.type.name', '-')}</strong> <br/>
        Transporter: <strong>{_.get(trip, 'company_transport.name', '-')}</strong> <br/>
        <hr/>
        List delivery notes:<br/>
      </div>

      <IonSearchbar mode="ios" inputmode="search" style={{ '--background': '#fff' }} className="search-box" value={searchText} debounce="300" onIonChange={handleChange} placeholder="Cari SKU"></IonSearchbar>
      {
        isLoading ? 
          (<>
            <IonSpinner name="crescent" style={{width: '100%'}}/>
            <OrderCards orders={[...Array(3)].map((u, i) => ({id: `${i}`}))} showSkeleton={true} />
          </>)
        : errorMessage ?
          (<>
            <IonImg src={errorImage} className="error-image"/>
            <IonText class="error-description">
              {errorMessage}
            </IonText>
          </>)
        : displayedOrders.length > 0 ?       
          <OrderCards orders={displayedOrders}/>
        : (<>
            <IonImg src={errorImage} className="error-image"/>
            <IonText class="error-description">
              {searchText ? 'Order tidak ditemukan' : 'Trip tidak memiliki order'}
            </IonText>
          </>)
      }
    </IonContent>
    <IonFooter>
        <IonToolbar>
          <IonButton expand="block" color="secondary" routerLink={`/warehouse/authorize/${id}`}>
            Selanjutnya
          </IonButton>
        </IonToolbar>
      </IonFooter>
  </IonPage>
);
}

const mapStateToProps = ({w_trip}) => ({
  trip: w_trip.trip,
  isLoading: w_trip.isLoading,
  errorMessage: w_trip.errorMessage,
  modalOrder: w_trip.order
});
const mapDispatchToProps = dispatch => bindActionCreators({getTripById, closeOrder}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps) (TripDetailScreen);