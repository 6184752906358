import React from 'react';
import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonImg,
    IonRippleEffect, IonRouterLink,
    IonRow,
    IonSkeletonText,
    IonBadge,
} from "@ionic/react";
import truckIcon from "../../../../assets/icons/truck.svg";
import getShortId from "../../../../helpers/getShortId";
import get from 'lodash/get';
import moment from 'moment';

const TripCard = ({trip, routerLink, loading, reports, shipmentNumber, expectedArrivalDate}) => {
    const status = get(reports, '0.goods_status', '');
    const reason_description = get(reports, '0.reason_description', '');
    const unload_at = get(reports, '0.unload_at', '');

    let description = reason_description;
    if (status === 'unload') {
        description = moment(unload_at).local().format('DD/MM/YYYY')
    }

    return (
        <IonRouterLink routerLink={routerLink}>
            <IonCard className="trip-card ion-activatable">
                <IonRippleEffect></IonRippleEffect>
                <IonCardContent>
                    {loading ? <>
                        <IonSkeletonText animated style={{width: '50%'}}/>
                        <IonSkeletonText animated style={{width: '40%'}}/>
                        <IonSkeletonText animated style={{width: '40%'}}/>
                        <hr/>
                        <IonSkeletonText animated style={{width: '80%'}}/>
                    </> : <>
                        <IonCardTitle>
                            <IonRow>
                                <IonCol>
                                    {get(trip, 'vehicle.license_plate') && <>
                                        <IonImg className="truck-icon" alt="Truck icon" src={truckIcon}/>
                                        <span className="card-title">{get(trip, 'vehicle.license_plate')}</span>
                                    </>}
                                    <div>
                                        <small style={{fontSize: 10, color: 'grey'}}>Driver</small>
                                        <p><strong>{get(trip, 'driver.name', 'Belum diassign')}</strong></p>
                                        <small style={{fontSize: 10, color: 'grey'}}>Jenis Kendaraan</small>
                                        <p><strong>{get(trip, 'vehicle.type.name', '-')}</strong></p>
                                    </div>
                                </IonCol>
                                <IonCol className="trip-id">
                                    <small style={{fontSize: 10, color: 'grey'}}>Shipment Number</small> <br/>
                                    <strong className="card-title">{shipmentNumber || '-'}</strong>
                                </IonCol>
                            </IonRow>
                        </IonCardTitle>
                        <IonRow>
                            <IonCol>
                                Transporter: <br/>
                                <strong>{get(trip, 'company_transport.name', '-')}</strong>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                {status && <div>
                                    <p><small>Laporan terakhir:</small></p>
                                    <IonBadge
                                        color={status === 'unload' ? 'success' : 'danger'}>{status.toUpperCase()} - <strong>{description}</strong></IonBadge>
                                </div>}
                            </IonCol>
                            <IonCol>
                                <p><small>Tanggal harus bongkar</small></p>
                                <IonBadge color={'danger'}><code>{expectedArrivalDate ? moment(expectedArrivalDate).format('DD/MM/YYYY') : null}</code></IonBadge>
                            </IonCol>
                        </IonRow>
                    </>}
                </IonCardContent>
            </IonCard>
        </IonRouterLink>
    )
}

export default TripCard;
