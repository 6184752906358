import React, { useEffect, useState } from 'react';
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle } from '@ionic/react';
import './HomeScreen.scss';
import home from '../../../../assets/waiting-process.png';
import scan from '../../../../assets/icons/scan-gray.svg';
import taskIcon from '../../../../assets/icons/task-gray.svg'
import logo from '../../../../assets/logo-freighthub.png';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

const HomeScreen = ( props ) => {
  const { data, token } = props
  const [isActiveVehicleChecklist, setIsActiveVehicleChecklist] = useState(false)

  useEffect(() => {
    if (!token) {
      return window.location.href = '/login'
    }

    if (data && data.company && data.company.config && data.company.config.settings) {
      const parsedSetting = JSON.parse(data.company.config.settings)
      checkVehicleChecklist(parsedSetting)
    }
  }, [data, token])

  const getUserFrontName = () => {
    return data && data.name && data.name.split(" ")[0] 
  }
  
  const checkVehicleChecklist = (setting) => {
    const isActive = setting.vehicle_checklist && setting.vehicle_checklist.is_active
    setIsActiveVehicleChecklist(isActive)
  }

  return (
    <IonPage className="homescreen">
      <IonHeader>
        <IonToolbar className="ritase-toolbar" color="primary">
          <IonButtons slot="start">
            <IonMenuButton auto-hide="true"></IonMenuButton>
          </IonButtons>
        <IonTitle style={{ textAlign: 'left', marginLeft: '-30px'}}>Home</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent style={{ textAlign: 'center' }}>
        <div className='content'>
          <div className='main'>
            <h4>Hi, {getUserFrontName()}</h4>
            
            <img src={home} alt="Shippers" className='home-image'/>

            <h6>Aktivitas Anda</h6>
            <div className='activity-item'>
              <img src={scan} alt='Scan' width={24} height={24} />
              <p>Scan (Get In/Get Out)</p>
            </div>
            {
              isActiveVehicleChecklist &&
              <div className='activity-item'>
                <img src={taskIcon} alt='Vehicle Checklist' width={24} height={24} />
                <p>Vehicle Checklist</p>
              </div>
            }
          </div>
          <img src={logo} alt="Freighthub" width="150dp" />
        </div>
      </IonContent>
    </IonPage>
  );
}

const mapstp = ({user: {token, data}}) => {
  return {
    token,
    data,
  }
};

const mapdtp = () => bindActionCreators({});

export default connect(mapstp, mapdtp)(HomeScreen);
