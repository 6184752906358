import React, {useState} from 'react';
import { connect } from 'react-redux'
import { IonPage, IonContent, IonButton, IonAlert } from '@ionic/react';
import './ProfileScreen.scss';
import edit from '../../../../assets/new-icons/edit_1.svg';
import logoutIcon from '../../../../assets/new-icons/logout.svg';
import impersonateIcon from '../../../../assets/icons/impersonate-group.svg';
import {logout} from '../../../../actions/user';
import {bindActionCreators} from 'redux';

const ProfileScreen = ({user, history, logout}) => {
  const [showAlert, setShowAlert] = useState(false);

  const doLogout = () => {
    logout().then(res => {
      window.location.href = '/login';
    }).catch(error => {
      window.location.href = '/login';
    });
  }
  
  return (
    <IonPage className="operator operator-profile">
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={"Keluar"}
        message={"Anda yakin ingin keluar?"}
        buttons={[
          {
            text: "Batal",
            role: "cancel",
          },
          {
            text: "Keluar",
            handler: () => doLogout(),
          },
        ]}
      />
      <IonContent className="operator-content">
        <div className="operator-header profile-header">
          <div>
            Profile
            {/* <img src={edit} alt='' style={{float: 'right'}} /> */}
          </div>
          <div
            style={{
              display: "inline-flex",
              marginTop: "40px",
              alignItems: "center",
            }}
          >
            <div className="profile-logo">{user && user.name.charAt(0)}</div>
            <div style={{ fontSize: "16px" }}>
              <div style={{ fontWeight: "bold", marginBottom: "6px" }}>
                {user && user.name}
              </div>
              <div>{user && user.company.name}</div>
            </div>
          </div>
        </div>

        <div className="profile-content">
          {user && user.supergroup && <IonButton
            expand="block"
            className="impersonate-btn"
            onClick={() => history.push("/operator/group-impersonate")}
          >
            <img src={impersonateIcon} alt="" style={{ marginRight: "20px" }} />
            <div style={{ width: "100%", textAlign: "left" }}>Change Group</div>
          </IonButton>}
          <IonButton
            expand="block"
            className="profile-btn"
            onClick={() => setShowAlert(true)}
          >
            <img src={logoutIcon} alt="" style={{ marginRight: "20px" }} />
            <div style={{ width: "100%", textAlign: "left" }}>Log out</div>
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user.data
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({logout}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);