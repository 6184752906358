import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonSkeletonText } from '@ionic/react';
import _ from 'lodash';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { detail as getDetail } from '../../../../actions/order';
import home from '../../../../assets/icons/home-white.svg';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const OrderDetailStatus = (props) => {
  const [id, setID] = useState(props.match.params.id);
  const [statusTimeline, setStatusTimeline] = useState([]);
  const [activeStatus, setActiveStatus] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  
  const statuses = [
    { id: '00', value: 'booked' },
    { id: '05', value: 'confirmed' },
    { id: '07', value: 'accepted' },
    { id: '10', value: 'pickup' },
    { id: '20', value: 'loaded' },
    { id: '30', value: 'dropoff' },
    { id: '40', value: 'unloaded' },
    { id: '50', value: 'pod' },
    { id: '60', value: 'cancelled' },
    { id: '70', value: 'transit'}
  ]

  if (id !== props.match.params.id) {
    setID(props.match.params.id);
  };

  useEffect(() => {
    setIsLoading(true)
    props.getDetail(id).then(function (result) {
      let res = result.payload.data;
      if (res.id) {
        createStatusTimeline(res);
      } else if (res.errorMessage) {
        throw res
      };
    }).catch(error => {
      console.error(error)
      setIsLoading(false)
      Swal.fire(
        'Error!',
        _.get(error, 'errorMessage', 'Terjadi kesalahan').split('\n')[0],
        'error'
      )
    });
  }, [id]);

  const getTask = (tasks, type) => {
    let result = {};
    if (typeof tasks !== "undefined") {
      result = _.find(tasks, task => task.type_id === type);
    }
    return result;
  }

  const createStatusTimeline = (item) => {
    let timeline = [];
    let timeline_multimode = [];
    let is_multimode = _.get(item, 'trip.has_next_trip', false);
    // let is_split = item.is_split_trip;
    let is_cancelled = item.status_id === '60';
    
    timeline.push(
      { 
        type_id: '00', 
        value: 'booked',
        date: item.created_at,
        description: 'Menunggu assignment ke transporter'
      },
      { 
        type_id: '05', 
        value: 'confirmed',
        date: item.confirmed_at,
        description: 'Transporter sudah melakukan konfirmasi'
      },
      { 
        type_id: '07', 
        value: 'accepted',
        date: item.accepted_at,
        description: 'Driver siap melaksanakan order'
      },
      { 
        type_id: '10', 
        value: 'pickup',
        date: item.pickup.completed_at,
        description: 'Driver sudah tiba dilokasi muat'
      },
      { 
        type_id: '20', 
        value: 'loaded',
        date: item.load.completed_at,
        description: `Barang dimuat, driver dalam perjalanan ke lokasi ${is_multimode ? 'transit' : 'bongkar'}`
      },
    );

    if (!is_multimode) {
      timeline.push(
        { 
          type_id: '30', 
          value: 'dropoff',
          date: item.dropoff.completed_at,
          description: 'Driver sudah tiba dilokasi bongkar'
        },
        { 
          type_id: '40', 
          value: 'unloaded',
          date: item.unload.completed_at,
          description: 'Barang sudah selesai dibongkar'
        },
        { 
          type_id: '50', 
          value: 'POD',
          date: item.pod.completed_at,
          description: 'Bukti pengiriman sudah diupload'
        }
      )
    } else {
      timeline.push(
        {
          type_id: '30', 
          value: 'dropoff transit',
          date: getTask(_.get(item, 'trip.tasks', []), '30').completed_at,
          description: 'Driver sudah tiba dilokasi transit'
        },
        {
          type_id: '40', 
          value: 'unloaded transit',
          date: getTask(_.get(item, 'trip.tasks', []), '40').completed_at,
          description: 'Barang sudah selesai bongkar untuk transit'
        },
        {
          type_id: '50', 
          value: 'POD transit',
          date: getTask(_.get(item, 'trip.tasks', []), '50').completed_at,
          description: 'Bukti transit sudah diupload'
        },
        {
          type_id: '70', 
          value: 'transit',
          date: null,
          description: _.get(item, 'trip.destination.name', '')
        },
      )
      
      _.each(item.relatedtrips, (related, index) => {
        if (related.leg > 0 && related.ordering === 0) {
          let temp_timeline = [
            {
              type_id: '70', 
              value: 'transit',
              date: null,
              description: _.get(related, 'origin.name', '')
            },
            { 
              type_id: '07', 
              value: `accepted leg ${related.leg}`,
              date: related.accepted_at,
              description: 'Driver siap melaksanakan order'
            },
            { 
              type_id: '10', 
              value: `pickup leg ${related.leg}`,
              date: getTask(related.tasks, '10').completed_at,
              description: 'Driver sudah tiba dilokasi transit'
            },
            { 
              type_id: '20', 
              value: `loaded leg ${related.leg}`,
              date: getTask(related.tasks, '20').completed_at,
              description: 'Barang dimuat, driver dalam perjalanan ke lokasi bongkar'
            },
            { 
              type_id: '30', 
              value: 'dropoff',
              date: getTask(related.tasks, '30').completed_at,
              description: 'Driver sudah tiba dilokasi bongkar'
            },
            { 
              type_id: '40', 
              value: 'unloaded',
              date: getTask(related.tasks, '40').completed_at,
              description: 'Barang sudah selesai dibongkar'
            },
            { 
              type_id: '50', 
              value: 'POD',
              date: getTask(related.tasks, '50').completed_at,
              description: 'Bukti pengiriman sudah diupload'
            },
          ]

          if (index !== item.relatedtrips.length - 1) {
            temp_timeline.push({
              type_id: '70', 
              value: 'transit',
              date: null,
              description: _.get(related, 'destination.name', '')
            })
          }

          timeline_multimode.push(temp_timeline)
        }
      })
    }

    if (is_cancelled) {
      timeline = _.filter(timeline, time => !_.isNil(time.date));
      timeline.push({ type_id: '60', value: 'cancelled', date: item.canceled_at});
    };

    const all_timeline = [timeline]
    
    if (!is_cancelled) {
      all_timeline.push(...timeline_multimode)
    }

    _.each(_.flatten(all_timeline), (status, index) => {
      status.index = index
      if (!_.isNil(status.date)) {
        setActiveStatus(status)
      }
    })

    if (is_cancelled) {
      setActiveStatus(_.find(_.flatten(all_timeline), status => status.type_id === '60'))
    }

    setStatusTimeline(all_timeline);
    setIsLoading(false)
  };

  const getImage = (id, type) => {
    let res = _.find(statuses, status => status.id === id);
    let img = '';
    if (res) {
      img += res.value;
      switch (type) {
        case 'pass':
          img += '_pass.svg';
          break;
        case 'active':
          img += '_active.svg';
          break;
        case 'inactive':
          img += '_inactive.svg';
          break;
        default:
          img += '.svg';
          break;
      };
    } else {
      img += 'booked_pass.svg'
    };

    return img;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="ritase-toolbar" color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/distributor/inbound/detail/${id}`}></IonBackButton>
          </IonButtons>
          <IonTitle>Status Detail</IonTitle>
          <IonButtons slot="end">
            <Link to={'/distributor/inbound/ongoing'}>
              <div style={{ marginRight: '20px' }}>
                <img src={home} alt=""/>
              </div>
            </Link>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="order-status-container distributor">
          {isLoading ?
            <div>
              <IonSkeletonText animated style={{width: '50%'}}/>
              <IonSkeletonText animated style={{width: '30%'}}/>
              <IonSkeletonText animated style={{width: '40%'}}/>
              <hr/>
              <IonSkeletonText animated style={{width: '80%'}}/>
            </div>
          :
            statusTimeline.map((timeline, j) => {
              return (
                <ul key={j} className="order-status-list">
                  {timeline.map((status, i) => {
                    let type;
                    let activeStatusIndex = _.get(activeStatus, 'index', 0);
                    if (status.index === activeStatusIndex) type = 'active';
                    else if (status.index < activeStatusIndex) type = 'pass';
                    else type = 'inactive';
      
                    return (
                      <li key={`timeline-${status.index}`} className={`order-status-item ${type}`}>
                        <img 
                          src={require(`../../../../assets/new-icons/status-revamp/${getImage(status.type_id, type)}`)}
                          alt=""
                        />
                        <div>
                          <div
                            className="order-status-value"
                            style={{textTransform: 'capitalize'}}
                          >
                            {status.value}
                          </div>
                          {status.description &&
                            <div className="order-status-description">
                              {status.description}
                            </div>
                          }
                        </div>

                        {status.date &&
                          <div className="order-status-date">
                            {moment(status.date).format('DD MMMM YYYY HH:mm')}
                          </div>
                        }
                      </li>
                    )
                  })}
                </ul>
              )
            })
          }
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getDetail
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(OrderDetailStatus);