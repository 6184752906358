import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonModal, IonButton, IonDatetime, IonList, IonItem, IonSearchbar, IonLabel, IonRouterLink, IonInfiniteScroll, IonInfiniteScrollContent, IonToast } from '@ionic/react';
import _ from 'lodash';
import moment from 'moment';
import { get as getCompanies } from '../../../../actions/transporter';
import { getLists as getContacts } from '../../../../actions/contact';
import { get as getOrders, exportOrder } from '../../../../actions/order';
import { bindActionCreators } from 'redux';
import './OrderScreen.scss';
import search from '../../../../assets/new-icons/search.svg';
import xlfile from '../../../../assets/new-icons/xlfile.svg';
import refresh from '../../../../assets/new-icons/refresh-orange.svg';
import group from '../../../../assets/new-icons/group-orange.svg';
import prefernce from '../../../../assets/new-icons/preference-orange.svg';
import rightsmall from '../../../../assets/new-icons/angle-right-small.svg';
import tick from '../../../../assets/new-icons/tick.svg';

const OrderScreen = props => {
  var searchQry = props.location.search;
  const urlParams = new URLSearchParams(searchQry);

  const getParamsQuery = () => {
    var params = [];
    if (searchQry.length > 0) {
      var entries = urlParams.entries();
      for (const entry of entries) {
        params.push({
          type: entry[0],
          value: entry[1]
        });
      };
    };
    
    return params;
  };

  const statuses = [
    { id: '00', value: 'Booked' },
    { id: '05', value: 'Confirmed' },
    { id: '07', value: 'Accepted' },
    { id: '10', value: 'Pickup' },
    { id: '20', value: 'Loaded' },
    { id: '30', value: 'Dropoff' },
    { id: '40', value: 'Unloaded' },
    { id: '50', value: 'POD' },
    { id: '60', value: 'Cancelled' },
  ];

  const requests = [
    { id: '05,07,10,20,30,40,50', value: 'Accepted' },
    { id: '00', value: 'Onbid' },
    { id: '60', value: 'N/A Fleet' },
  ];

  const [showFilter, setShowFilter] = useState(false);
  const [showDestination, setShowDestination] = useState(false);
  const [showTransporter, setShowTransporter] = useState(false);
  const [showSearchbar, setShowSearchbar] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [request, setRequest] = useState([]);
  const [status, setStatus] = useState([]);
  const [transporter, setTransporter] = useState([]);
  const [destination, setDestination] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchOrder, setSearchOrder] = useState('');
  const [searchDestination, setSearchDestination] = useState('');
  const [searchTransporter, setSearchTransporter] = useState('');
  const [displayedTransporter, setDisplayedTransporter] = useState(props.allTransporter);
  const [filter, setFilter] = useState({});
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
  const [paramsOrd, setParamsOrder] = useState(getParamsQuery());
  // const [paramsOrd, setParamsOrder] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    let search = props.location.search.length > 0 ? props.location.search.replace('?', '&') : '';
    props.getCompanies().then(result => {
      let res = result.payload.data.map(x => {
        return {
          id: x.id,
          value: x.name,
        };
      });
      
      setDisplayedTransporter(res);
    });
    props.getContacts();
    props.getOrders(0, search);
    // initFilter();
  }, []);

  // const initFilter = () => {
  //   let res = {};
  //   if (urlParams.has('status_id')) {
  //     res.status = [];
  //     _.each(urlParams.get('status_id').split(','), param => {
  //       let item = _.find(statuses, stat => stat.id === param);
  //       res.status.push(item);
  //     });

  //     setStatus(res.status);
  //   };

  //   if (urlParams.has('destination_contact_id')) {
  //     res.destination = [];
  //     _.each(urlParams.get('destination_contact_id').split(','), param => {
  //       let item = _.find(props.allDestination, dest => dest.id === param);
  //       res.destination.push(item);
  //     });

  //     setDestination(res.destination);
  //   };
  //   setFilter(res);
  // };

  const openFilter = () => {
    if (filter.request) {
      setRequest(filter.request);
    } else {
      setRequest([]);
    }
    
    if (filter.status) {
      setStatus(filter.status);
    } else {
      setStatus([]);
    }
    
    if (filter.transporter) {
      setTransporter(filter.transporter);
    } else {
      setTransporter([]);
    }
    
    if (filter.destination) {
      setDestination(filter.destination);
    } else {
      setDestination([]);
    }

    setShowFilter(true);
  }

  const toggleRequest = (type) => {
    if (status.length > 0) return;
    let new_request;
    if (_.find(request, req => req.id === type.id, false)) {
      new_request = _.filter(request, req => req.id !== type.id);
    } else {
      new_request = [type];
    }
    setRequest(new_request);
  };

  const toggleStatus = (type) => {
    if (request.length > 0) return;
    let new_status;
    if (_.find(status, stat => stat.id === type.id, false)) {
      new_status = _.filter(status, stat => stat.id !== type.id);
    } else {
      new_status = [...status, type];
    };
    setStatus(new_status);
  };

  const toggleDestination = (val) => {
    let selected_destination;
    if (_.find(destination, dest => dest.id === val.id)) {
      selected_destination = _.filter(destination, dest => dest.id !== val.id);
    } else {
      selected_destination = [val];
      filterDestination('');
      setShowDestination(false);
    };
    setDestination(selected_destination);
  };

  const toggleTransporter = (val) => {
    let selected_transporter;
    if (_.find(transporter, trp => trp.id === val.id)) {
      selected_transporter = _.filter(transporter, trp => trp.id !== val.id);
    } else {
      selected_transporter = [val];
      filterTransporter('');
      setShowTransporter(false);
    };
    setTransporter(selected_transporter);
  };

  const resetFilter = () => {
    setRequest([]);
    setStatus([]);
    setTransporter([]);
    setDestination([]);
    setStartDate('');
    setEndDate('');
  };

  const applyFilter = () => {
    let new_filter = {};
    let new_params = [];

    if (request.length > 0) {
      new_filter.request = request;
      let start = moment().startOf('day').utc().format();
      let end = moment().startOf('day').add(1, 'day').utc().format();
      let params_request = _.map(request, req => req.id);
      new_params.push(
        {type: 'start_created', value: start},
        {type: 'end_created', value: end},
      );
        
      if (_.find(request, req => req.value === 'Onbid')) {
        new_params.push(
          {type: 'on_bidding', value: '1'}
        )
      } else {
        new_params.push(
          {type: 'status_id', value: params_request.join(',')}
        );
      }
    };
    
    if (status.length > 0) {
      new_filter.status = status;
      let params_status = _.map(status, stat => stat.id);
      new_params.push({type: 'status_id', value: params_status.join(',')});
    };
    
    if (transporter.length > 0) {
      new_filter.transporter = transporter;
      let params_transporter = _.map(transporter, trp => trp.id);
      new_params.push({type: 'company_transport_id', value: params_transporter.join(',')});
    };

    if (destination.length > 0) {
      new_filter.destination = destination;
      let params_destination = _.map(destination, dest => dest.id);
      new_params.push({type: 'destination_contact_id', value: params_destination.join(',')});
    };

    if (startDate && endDate) {
      new_filter.startDate = startDate;
      new_params.push({type: 'start', value: moment.utc(startDate).format()});
      new_filter.endDate = endDate;
      new_params.push({type: 'end', value: moment.utc(endDate).format()});
    };

    setParamsOrder(new_params);
    setFilter(new_filter);
    setShowFilter(false);
    props.getOrders(0, getParamsString(new_params));
    // props.history.push({search: getParamsString(new_params).substring(1)});
    setDisableInfiniteScroll(false);
  };

  const filterDestination = (val) => {
    setSearchDestination(val);
    let params = val && val.length > 0 ? `&search=${val}` : '';
    props.getContacts(params);
  };

  const filterTransporter = (val) => {
    setSearchTransporter(val);
    let displayed = props.allTransporter.filter(item => {
      return _.includes(item.value.toLowerCase(), val.toLowerCase());
    });
    setDisplayedTransporter(displayed);
  };

  const removeFilter = (type, val) => {
    let params_type, new_params;
    let new_filter = _.filter(filter[type], f => f.id !== val.id);
    let removeDate = false;
    
    switch (type) {
      case 'request':
        new_filter = _.filter(filter[type], f => f !== val);
        
        if (_.find(filter[type], req => req.value === 'Onbid')) {
         params_type = 'on_bidding';
        } else {
          params_type = 'status_id';
        };
        
        if (new_filter.length <= 0) {
          removeDate = true;
        };
        
        setRequest(new_filter);
        break;
      case 'status':
        setStatus(new_filter);
        params_type = 'status_id';
        break;
      case 'transporter':
        setTransporter(new_filter);
        params_type = 'company_transport_id';
        break;
      case 'destination':
        setDestination(new_filter);
        params_type = 'destination_contact_id';
        break;
      default:
        break;
    };

    setFilter({
      ...filter,
      [type]: new_filter
    });

    new_params = _.filter(paramsOrd, par => par.type !== params_type);
    if (removeDate) new_params = _.filter(new_params, par => par.type !== 'start_created' && par.type !== 'end_created');
    new_params.push({type: params_type, value: _.map(new_filter, fil => fil.id).join(',')});
    setParamsOrder(new_params);
    props.getOrders(0, getParamsString(new_params));
    // props.history.push({search: getParamsString(new_params).substring(1)});
    setDisableInfiniteScroll(false);
  };

  const removeReadyTime = () => {
    setStartDate('');
    setEndDate('');

    setFilter({
      ...filter,
      startDate: '',
      endDate: '',
    });

    let new_params = _.filter(paramsOrd, par => par.type !== 'start' && par.type !== 'end');
    setParamsOrder(new_params);
    props.getOrders(0, getParamsString(new_params));
    setDisableInfiniteScroll(false);
  }

  const hasFilter = () => {
    return _.get(filter, 'request.length', 0) > 0 || _.get(filter, 'status.length', 0) > 0 || _.get(filter, 'transporter.length', 0) > 0 || _.get(filter, 'destination.length', 0) > 0 || _.get(filter, 'startDate.length', 0) > 0 || _.get(filter, 'endDate.length', 0) > 0;
  };

  const filterBySearch = (val) => {
    setSearchOrder(val);
    let new_params = _.filter(paramsOrd, par => par.type !== 'search');
    new_params.push({type: 'search', value: val});
    setParamsOrder(new_params);
    props.getOrders(0, getParamsString(new_params));
    setDisableInfiniteScroll(false);
  };

  const hideSearchBar = () => {
    setSearchOrder('');
    setShowSearchbar(false);
    let new_params = _.filter(paramsOrd, par => par.type !== 'search');
    setParamsOrder(new_params);
    props.getOrders(0, getParamsString(new_params));
    setDisableInfiniteScroll(false);
  };

  const loadMore = (e) => {
    props.getOrders(props.offset + 10, getParamsString(paramsOrd));
    
    e.target.complete();
    
    if (props.count > props.orders.length) {
      setDisableInfiniteScroll(false);
    } else {
      setDisableInfiniteScroll(true);
    };
  };

  const getParamsString = (params) => {
    let params_string = _.map(params, par => par.value.length > 0 ? `&${par.type}=${par.value}` : '');
    if (params_string.length > 0) return params_string.join('');
    
    return '';
  };

  const refreshOrder = () => {
    props.getOrders(0, getParamsString(paramsOrd));
  };

  const exportExcel = () => {
    if (startDate && endDate) {
      let export_params = _.filter(paramsOrd, par => par.type !== 'start' && par.type !== 'end' && par.value.length > 0);
      export_params.push({type: 'start', value: moment.utc(startDate).format()});
      export_params.push({type: 'end', value: moment.utc(endDate).format()});
      export_params.push({type: 'timezone_offset', value: moment().utcOffset() / 60});

      let params_string = _.map(export_params, par => `${par.type}=${par.value}`).join('&');
      
      props.exportOrder(params_string).then(function(result) {
        if (result.payload.status === 200) {
          if (result.payload.data.errorMessage) {
            setMessage(result.payload.data.errorMessage);
            setShowMessage(true);
          } else {
            window.location.href = result.payload.data.url;
          }
        } else {
          setMessage(`Error: ${result.payload.status}`);
          setShowMessage(true);
        }
      })
    }
  };

  const getRequestStatus = (item) => {
    let accepted = ['05','07','10','20','30','40','50'];
    let cancelled = ['60']
    
    if (!isNewOrderRequest(item)) return null;
    if (_.get(item, 'trip.onbid_tender_offers.length', 0) > 0) return 'onbid';
    if (accepted.includes(item.status_id)) return 'accepted';
    if (cancelled.includes(item.status_id)) return 'cancelled';
    return null;
  };

  const isNewOrderRequest = (item) => {
    let start = moment().startOf('day').utc();
    let end = moment().startOf('day').add(1, 'day').utc();
    if (moment.utc(item.created_at).isBetween(start, end)) return true;
    return false;
  };

  return (
    <IonPage className="operator operator-order">
      <IonContent className="operator-content">
        <div className="operator-header" style={{padding: showSearchbar ? '19px 20px' : '30px 20px'}}>
          {!showSearchbar ? 
            <span>
              Order
              <div className="order-header">
                <img src={search} alt='' onClick={() => setShowSearchbar(true)} />
                <img src={xlfile} alt='' onClick={() => setShowExport(true)} />
                <img src={refresh} alt='' style={{backgroundColor: '#fff', padding: '4px', borderRadius: '50%'}} onClick={() => refreshOrder()} />
              </div>
            </span>
          :
            <span style={{display: 'inline-flex', width: '100%'}}>
              <IonSearchbar 
                class="operator-searchbar" 
                value={searchOrder} 
                onIonChange={e => filterBySearch(e.detail.value)}
                placeholder="Cari shipment number, order number, PO number"
              />
              <div style={{lineHeight: '2.4'}} onClick={hideSearchBar}>Cancel</div>
            </span>
          }
        </div>

        {filter && hasFilter() &&
          <div className="operator-chip-container" style={{padding: '0 20px'}}>
            {filter.request && filter.request.length > 0 && 
              filter.request.map(req => (
                <div className="operator-chip" key={req.id} onClick={() => removeFilter('request', req)}>
                  {req.value}
                  <span style={{marginLeft: '15px'}}>&times;</span>
                </div>
              ))
            }

            {filter.status && filter.status.length > 0 && 
              filter.status.map(stat => (
                <div className="operator-chip" key={stat.id} onClick={() => removeFilter('status', stat)}>
                  {stat.value}
                  <span style={{marginLeft: '15px'}}>&times;</span>
                </div>
              ))
            }
            
            {filter.transporter && filter.transporter.length > 0 && 
              filter.transporter.map(trp => (
                <div className="operator-chip" key={trp.id} onClick={() => removeFilter('transporter', trp)}>
                  {trp.value}
                  <span style={{marginLeft: '15px'}}>&times;</span>
                </div>
              ))
            }

            {filter.destination && filter.destination.length > 0 && 
              filter.destination.map(dest => (
                <div className="operator-chip" key={dest.id} onClick={() => removeFilter('destination', dest)}>
                  {dest.value}
                  <span style={{marginLeft: '15px'}}>&times;</span>
                </div>
              ))
            }

            {filter.startDate && filter.startDate.length > 0 && filter.endDate && filter.endDate.length > 0 && 
              <div className="operator-chip" onClick={() => removeReadyTime()}>
                Ready time : {moment(startDate).format('DD MMM YYYY')} - {moment(endDate).format('DD MMM YYYY')}
                <span style={{marginLeft: '15px'}}>&times;</span>
              </div>
            }
          </div>
        }

        <div className="order-content" style={{paddingBottom: disableInfiniteScroll ? '45px' : '0'}}>
          {props.orders.length === 0 ?
            <div>No data found</div>
          : props.orders.map((item, i) => (
            <div key={i} className="order-item">
              <div className="item-header">
                <span style={{fontWeight: '500'}}>{_.get(item, 'status.name', '')}</span>
                {/* <span className={item.request_status}> ({item.request_status}) </span> */}
                {getRequestStatus(item) &&
                  <span className={getRequestStatus(item)}> ({getRequestStatus(item)}) </span>
                }
                <span style={{color: '#9e9e9e'}}> &bull; </span>
                <span style={{color: '#9e9e9e'}}> {_.get(item, 'company_transport.name', '')}</span>
                <IonRouterLink routerLink={`/operator/order/${item.id}`}><img src={group} alt='' /></IonRouterLink>
              </div>

              <div className="item-content">
                <div className="origin">
                  <div className="trip-dot" style={{backgroundColor: '#fff'}}></div>
                  <div className="trip-place-name">{_.get(item, 'origin_contact_name', '')}</div>
                </div>

                <div className="destination">
                  <div className="trip-dot" style={{backgroundColor: '#fe6600'}}></div>
                  <div className="trip-place-name">{_.get(item, 'destination_contact_name', '')}</div>
                </div>

                <div>
                  <span style={{color: '#9e9e9e', marginRight: '10px'}}>Estimasi Berangkat</span>
                  <span style={{fontWeight: '500'}}>{!_.isNil(item.ready_time) ? moment(item.ready_time).format('dddd, DD MMMM YYYY, HH:mm') : '-'}</span>
                </div>
              </div>

              <div>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <div className="item-title">Shipment Number</div>
                      <div>{item.shipment_number || '-'}</div>
                    </IonCol>

                    <IonCol>
                      <div className="item-title">Order Number</div>
                      <div>{item.order_number || '-'}</div>
                    </IonCol>

                    <IonCol>
                      <div className="item-title">PO Number</div>
                      <div>{item.po_number || '-'}</div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
          ))}
        </div>

        <IonInfiniteScroll 
          threshold="10px"
          disabled={disableInfiniteScroll}
          onIonInfinite={(e) => loadMore(e)}
        >
          <IonInfiniteScrollContent loadingSpinner="circular" />
        </IonInfiniteScroll>

        <div className="float-button-container">
          <button className="float-button" onClick={openFilter}>
            <img src={prefernce} alt='' style={{width: '16px', verticalAlign: 'middle'}} />
            <span style={{fontSize: '16px', color: '#fe6600', marginLeft: '12px'}}>Filter</span>
          </button>
        </div>

        <IonModal isOpen={showFilter} cssClass="operator" swipeToClose={true} onDidDismiss={() => setShowFilter(false)}>
          <IonContent>
            <div className="operator-header">
              <span style={{fontSize: '20px', marginRight: '25px'}} onClick={() => setShowFilter(false)}>&times;</span>
              <span>Filter</span>
              <span onClick={resetFilter} style={{float: 'right', fontSize: '14px', marginTop: '5px', cursor: 'pointer'}}>Reset</span>
            </div>
            <div className="operator-filter">
              <div className="operator-filter-item">
                <div className="filter-item-title">
                  New Order Request
                  <img src={rightsmall} alt='' className="rightsmall" />
                </div>
                <div className="operator-chip-container">
                  {requests.map(req => (
                    <div key={req.id} className={`operator-chip${_.find(request, s => s.id === req.id, false) ? ' selected' : ''}${status.length > 0 ? ' disabled' : ''}`} onClick={() => toggleRequest(req)}>{req.value}</div>
                  ))}
                </div>
              </div>

              <div className="operator-filter-item">
                <div className="filter-item-title">
                  Status
                  <img src={rightsmall} alt='' className="rightsmall" />
                </div>
                <div className="operator-chip-container">
                  {statuses.map(stat => (
                    <div key={stat.id} className={`operator-chip${_.find(status, s => s.id === stat.id, false) ? ' selected' : ''}${request.length > 0 ? ' disabled' : ''}`} onClick={() => toggleStatus(stat)}>{stat.value}</div>
                  ))}
                </div>
              </div>

              <div className="operator-filter-item">
                <div className="filter-item-title" onClick={() => setShowTransporter(true)}>
                  Transporter
                  <img src={rightsmall} alt='' className="rightsmall" />
                </div>
                {transporter.length > 0 &&
                  <div className="operator-chip-container">
                    {transporter.map(trp => (
                      <div key={trp.id} className="operator-chip selected">{trp.value}</div>
                    ))}
                  </div>
                }
              </div>

              <div className="operator-filter-item">
                <div className="filter-item-title" onClick={() => setShowDestination(true)}>
                  Destination
                  <img src={rightsmall} alt='' className="rightsmall" />
                </div>
                {destination.length > 0 &&
                  <div className="operator-chip-container">
                    {destination.map(dest => (
                      <div key={dest.id} className="operator-chip selected">{dest.value}</div>
                    ))}
                  </div>
                }
              </div>

              <div className="operator-filter-item">
                <div className="filter-item-title">
                  Ready Time
                  <img src={rightsmall} alt='' className="rightsmall" />
                </div>
                <div>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="5">
                        <div className="filter-date-label">Start time</div>
                        <IonDatetime 
                          displayFormat="DD/MM/YYYY" 
                          placeholder="Select Date" 
                          value={startDate} 
                          onIonChange={e => setStartDate(e.detail.value)}
                          className="filter-date"
                          disabled={request.length > 0}
                        />
                      </IonCol>

                      <IonCol size="2" style={{textAlign: 'center', alignSelf: 'center'}}>
                        <div style={{color: '#979797', marginTop: '30px'}}>&mdash;</div>
                      </IonCol>

                      <IonCol size="5" style={{textAlign: 'right'}}>
                        <div className="filter-date-label">End time</div>
                        <IonDatetime 
                          displayFormat="DD/MM/YYYY" 
                          placeholder="Select Date" 
                          value={endDate} 
                          onIonChange={e => setEndDate(e.detail.value)}
                          className="filter-date"
                          disabled={request.length > 0}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              </div>
            </div>

            <div className="operator-footer">
              <IonButton expand="block" className="btn-filter" onClick={applyFilter}>Terapkan</IonButton>
            </div>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showDestination} cssClass="operator" swipeToClose={true} onDidDismiss={() => setShowDestination(false)}>
          <IonContent>
            <div className="operator-header">
              <span style={{fontSize: '20px', marginRight: '25px'}} onClick={() => setShowDestination(false)}>&times;</span>
              <span>Destination</span>
            </div>
            <div className="operator-filter">
              <IonList>
                <IonSearchbar 
                  class="operator-filter-search" 
                  value={searchDestination} 
                  onIonChange={e => filterDestination(e.detail.value)}
                  placeholder="Search Destination"
                />
                {props.allDestination.map(dest => (
                  <IonItem key={dest.id} onClick={() => toggleDestination(dest)}>
                    <IonLabel>{dest.value}</IonLabel>
                    {_.find(destination, d => d.id === dest.id) &&
                      <img src={tick} alt='' slot="end" />
                    }
                  </IonItem>
                ))}
              </IonList>
            </div>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showTransporter} cssClass="operator" swipeToClose={true} onDidDismiss={() => setShowTransporter(false)}>
          <IonContent>
            <div className="operator-header">
              <span style={{fontSize: '20px', marginRight: '25px'}} onClick={() => setShowTransporter(false)}>&times;</span>
              <span>Transporter</span>
            </div>
            <div className="operator-filter">
              <IonList>
                <IonSearchbar 
                  class="operator-filter-search" 
                  value={searchTransporter} 
                  onIonChange={e => filterTransporter(e.detail.value)}
                  placeholder="Search Transporter"
                />
                {displayedTransporter.map(trp => (
                  <IonItem key={trp.id} onClick={() => toggleTransporter(trp)}>
                    <IonLabel>{trp.value}</IonLabel>
                    {_.find(transporter, t => t.id === trp.id) &&
                      <img src={tick} alt='' slot="end" />
                    }
                  </IonItem>
                ))}
              </IonList>
            </div>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showExport} cssClass="operator" swipeToClose={true} onDidDismiss={() => setShowExport(false)}>
          <IonContent>
            <IonToast
              isOpen={showMessage}
              onDidDismiss={() => setShowMessage(false)}
              message={message}
              position="top"
              color="danger"
              duration={3000}
            />
            <div className="operator-header">
              <span style={{fontSize: '20px', marginRight: '25px'}} onClick={() => setShowExport(false)}>&times;</span>
              <span>Export Order</span>
            </div>

            <div style={{padding: '30px 20px'}}>
              <div style={{marginBottom: '20px', fontWeight: 'bold'}}>Filter in list will be applied.</div>
              <div>
                <div className="filter-item-title">
                  Ready Time
                </div>
                <div>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="5">
                        <div className="filter-date-label">Start time</div>
                        <IonDatetime 
                          displayFormat="DD/MM/YYYY" 
                          placeholder="Select Date" 
                          value={startDate} 
                          onIonChange={e => setStartDate(e.detail.value)}
                          className="filter-date"
                        />
                      </IonCol>

                      <IonCol size="2" style={{textAlign: 'center', alignSelf: 'center'}}>
                        <div style={{color: '#979797', marginTop: '30px'}}>&mdash;</div>
                      </IonCol>

                      <IonCol size="5" style={{textAlign: 'right'}}>
                        <div className="filter-date-label">End time</div>
                        <IonDatetime 
                          displayFormat="DD/MM/YYYY" 
                          placeholder="Select Date" 
                          value={endDate} 
                          onIonChange={e => setEndDate(e.detail.value)}
                          className="filter-date"
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              </div>

              {moment(endDate).diff(startDate, 'days') > 30 &&
                <div style={{color: '#a94442', margin: '10px 0'}}>
                  Export order cannot exceed 30 days. If needed, export order per 30 days multiple time.
                </div>
              }

              {moment(endDate).isSameOrBefore(startDate, 'days') &&
                <div style={{color: '#a94442', margin: '20px 0'}}>
                  End date must be later than the start date.
                </div>
              }

              <div style={{marginTop: '20px', textAlign: 'center'}}>
                <IonButton className="btn-filter" onClick={exportExcel} disabled={!startDate || !endDate || moment(endDate).diff(startDate, 'days') > 30 || moment(endDate).isSameOrBefore(startDate, 'days')}>Export</IonButton>
              </div>
            </div>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  )
};

const mapStateToProps = state => {
  const { list, count, offset } = state.order;

  let allTransporter = state.transporter.lists.map(x => {
    return {
      id: x.id,
      value: x.name,
    };
  });

  const allDestination = state.contact.lists.map(x => {
    return {
      id: x.id,
      value: x.name
    }
  });
  return {
    allTransporter,
    allDestination,
    orders: list,
    count,
    offset
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getCompanies, getContacts, getOrders, exportOrder
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderScreen);