import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonBadge, IonButton } from '@ionic/react';
import _ from 'lodash';
import moment from 'moment';
import { getTripSummary, getTripSummaryToday } from '../../../../actions/trip';
import { getIssueSummary } from '../../../../actions/issue';
import logo from '../../../../assets/logo-freighthub.png';
import approval from '../../../../assets/new-icons/approval-orange.svg';
import './HomeScreen.scss';
import { getListApproval } from '../../../../actions/approval';
import { hasRole } from '../../../../helpers/permissions';

const HomeScreen = (props) => {
  const status = [
    { id: '00', value: 'booked' },
    { id: '05', value: 'confirmed' },
    { id: '07', value: 'accepted' },
    { id: '10', value: 'pickup' },
    { id: '20', value: 'loaded' },
    { id: '30', value: 'dropoff' },
    { id: '40', value: 'unloaded' },
    { id: '50', value: 'POD' },
    { id: '60', value: 'cancelled' },
  ];

  const [allOrder, setAllOrder] = useState(0);
  const [allNewOrder, setAllNewOrder] = useState(0);
  const [newAccepted, setNewAccepted] = useState(0);
  const [startDate, setStartDate] = useState(moment().startOf('day').utc().format());
  const [endDate, setEndDate] = useState(moment().startOf('day').add(1, 'day').utc().format());
  const [summaryIssue, setSummaryIssue] = useState([]);
  const [allIssue, setAllIssue] = useState(0);
  const { total_approval, permissions, companySetting } = props;
  const issueMonitoringActive = hasRole(permissions, 'issues_management') && (companySetting && companySetting.issue_management && companySetting.issue_management.is_active == 1);

  useEffect(() => {
    props.getTripSummary().then(result => {
      let total = 0;
      _.each(result.payload.data.order_status, (sum, id) => {
        if (id !== '99') total += sum;
      });
      setAllOrder(total);
    });

    props.getTripSummaryToday().then(result => {
      let total = 0;
      let accepted = 0;
      _.each(result.payload.data.order_status, (sum, id) => {
        if (id !== '99') total += sum;
        if (['05','07','10','20','30','40','50'].includes(id)) accepted += sum;
      });
      setAllNewOrder(total);
      setNewAccepted(accepted);
    });

    if (issueMonitoringActive) {
      props.getIssueSummary('type=collab_tickets').then(result => {
        let tempArr = [];
        result.payload.data.forEach(function (issue) {
          if (issue.reason_id) {
            let obj = { reason_id: issue.reason_id, description: issue.description, count: issue[issue.reason_id] };
            tempArr.push(obj);
          }
        })
        tempArr = _.filter(tempArr, temp => temp.count > 0);
        setSummaryIssue(tempArr);
        setAllIssue(_.sumBy(tempArr, (item) => item.count ));
      });
    }

    props.getListApproval()
  }, []);

  
  return (
    <IonPage className="operator operator-homescreen">
      <IonContent className="operator-content">
        <div className="bg-white">
          <div className="home-header">
            <img src={logo} height="60dp" alt=""/>
            <Link to={`/operator/approval`}>
              <IonButton className="approval-button" fill="clear">
                <IonBadge className="notification-badge" color="danger">{ total_approval > 99 ? '99+' : total_approval }</IonBadge>
                <img src={approval} className="approval-icon" height="30dp" alt=""/>        
              </IonButton>
            </Link>
          </div>
          
          <div className="home-section">
            <strong>New Order Request</strong>
            <div style={{float: 'right', marginRight: '14px', fontSize: '14px'}}>{moment().format('DD MMM YYYY')}</div>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <Link to={`/operator/order?start_created=${startDate}&end_created=${endDate}`}>
                    <div className="home-card all">
                      <div style={{fontSize: '12px', marginBottom: '8px'}}>All Order</div>
                      <div style={{fontSize: '20px'}}>{allNewOrder}</div>
                    </div>
                  </Link>
                </IonCol>
                <IonCol>
                  <Link to={`/operator/order?start_created=${startDate}&end_created=${endDate}&status_id=05,07,10,20,30,40,50`}>
                    <div className="home-card accepted">
                      <div style={{fontSize: '12px', marginBottom: '8px'}}>Order Accepted</div>
                      <div style={{fontSize: '20px'}}>{newAccepted}</div>
                    </div>
                  </Link>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Link to={`/operator/order?start_created=${startDate}&end_created=${endDate}&on_bidding=1`}>
                    <div className="home-card bid">
                      <div style={{fontSize: '12px', marginBottom: '8px'}}>On Bid</div>
                      <div style={{fontSize: '20px'}}>{_.get(props, 'summary_today.total_on_bid', false)  ? props.summary_today.total_on_bid : 0}</div>
                    </div>
                  </Link>
                </IonCol>
                <IonCol>
                  <Link to={`/operator/order?start_created=${startDate}&end_created=${endDate}&status_id=60`}>
                    <div className="home-card no-available">
                      <div style={{fontSize: '12px', marginBottom: '8px'}}>No Available Fleet</div>
                      <div style={{fontSize: '20px'}}>{_.get(props, 'summary_today.order_status["60"]', false)  ? props.summary_today.order_status['60'] : 0}</div>
                    </div>
                  </Link>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>

        <div className="bg-white" style={{marginTop: '15px'}}>
          <div className="home-section">
            <strong>All Order Status</strong>
            <div className="homecard-container">
              <Link to='/operator/order'>
                <div className='home-card slide slide-all'>
                  <div style={{fontSize: '12px', marginBottom: '8px', textTransform: 'capitalize'}}>All</div>
                  <div style={{fontSize: '20px'}}>{allOrder}</div>
                </div>
              </Link>
              {props.summary.order_status && status.map((item) => (
                <Link key={item.id} to={`/operator/order?status_id=${item.id}`}>
                  <div className='home-card slide'>
                    <div style={{fontSize: '12px', marginBottom: '8px', textTransform: 'capitalize'}}>{item.value}</div>
                    <div style={{fontSize: '20px'}}>{props.summary.order_status[item.id]}</div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
        
        { issueMonitoringActive ?
          (
            <div className="bg-white" style={{marginTop: '15px'}}>
              <div className="home-section">
                <strong>Trip with issue</strong>
                <div className="homecard-container">
                  <Link to='/operator/issue'>
                    <div className='home-card slide slide-all issue'>
                      <div style={{fontSize: '12px', marginBottom: '8px', textTransform: 'capitalize', height: '28px'}}>All Issue</div>
                      <div style={{fontSize: '20px'}}>{allIssue}</div>
                    </div>
                  </Link>
                  { summaryIssue && summaryIssue.map((item) => (
                    <Link key={item.reason_id} to={`/operator/issue?reason_id=${item.reason_id}`}>
                      <div className='home-card slide'>
                        <div className="home-card-issue">{ item.description.length > 30 ? `${_.capitalize(item.description.substring(0,30))}...` : _.capitalize(item.description) }</div>
                        <div style={{fontSize: '20px'}}>{item.count}</div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          ) : '' 
        }
      </IonContent>
    </IonPage>
  )
};

const mapStateToProps = state => {
  const permissions = (state.user && state.user.data && state.user.data.role && state.user.data.role.permissions) || []
  const companySetting = (state.user && state.user.data && state.user.data.company && state.user.data.company.config && state.user.data.company.config.settings && JSON.parse(state.user.data.company.config.settings)) || {}
  return {
    summary: state.trip_summary.summary,
    summary_today: state.trip_summary.summary_today,
    issue_summary: state.issue_summary.summary,
    total_approval: state.approval.count,
    permissions,
    companySetting
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getTripSummary, getTripSummaryToday, getIssueSummary, getListApproval
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);