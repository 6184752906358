import React from 'react';
import {IonGrid, IonRow, IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonCol, IonItem } from '@ionic/react';
import _ from 'lodash';
import moment from 'moment';

const IssueFeedbacks = ({feedbacks=[]}) => {

  const getDifferenceTime = (time) => {
    if (!time) {
      return '';
    }

    let end = moment(time);
    let start = moment();
    let duration = moment.duration(start.diff(end));

    if (duration._data.years) return `(${duration._data.years} years ago)`;
    if (duration._data.months) return `(${duration._data.months} months ago)`;
    if (duration._data.days) return `(${duration._data.days} days ago)`;
    if (duration._data.hours) return `(${duration._data.hours} hours ago)`;
    if (duration._data.minutes) return `(${duration._data.minutes} minutes ago)`;
    if (duration._data.seconds) return `(${duration._data.seconds} seconds ago)`;
  }
  
    return (
    <>
      {feedbacks.map((feedback, i) => (
        <IonGrid key={i}>
          <IonRow>
            <IonCard style={{ width: '100%' }}>
              <IonCardHeader>
                <IonCardSubtitle style={{ color: '#595959', fontSize: '16px', fontWeight: '600' }}>{ _.get(feedback, 'sender.name', '') }</IonCardSubtitle>
              </IonCardHeader>

              <IonCardContent style={{ color: '#9e9e9e', fontSize: '14px' }}>
                {feedback.message}
              </IonCardContent>
            </IonCard>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonItem lines="none">
                <span slot="end" style={{ color: '#595959', fontSize: '12px' }}>{ !_.isNil(feedback.created_at) ? `${moment(feedback.created_at).format('DD MMM YYYY HH:mm')} ${ getDifferenceTime(feedback.created_at) }`: '-'}</span>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      ))}
    </>
    );
}

export default IssueFeedbacks;