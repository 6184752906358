/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { 
  IonPage, 
  IonContent, 
  IonInfiniteScroll, 
  IonInfiniteScrollContent, 
  IonItem, 
  IonSelect, 
  IonSelectOption, 
  IonInput, 
  IonModal, 
  IonGrid,
  IonRow,
  IonCol,
  IonDatetime,
  IonButton,
  IonList,
  IonSearchbar,
  IonLabel,
  IonFab,
  IonFabButton,
  IonToast
} from '@ionic/react';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { getListApprovalHistory, resetApprovalDecision, resetListApprovalHistory } from '../../../../actions/approval';
import { getDetailDefine as getTrip } from '../../../../actions/trip';
import back from '../../../../assets/new-icons/back.svg'
import './ApprovalScreen.scss';
import getApprovalTime from '../../../../helpers/getApprovalTime';
import getApprovalStatus from '../../../../helpers/getApprovalStatus';
import ApprovalItem from './ApprovalItem';
import prefernce from '../../../../assets/new-icons/preference-orange.svg';
import rightsmall from '../../../../assets/new-icons/angle-right-small.svg';
import tick from '../../../../assets/new-icons/tick.svg';
import { get as getCompanies } from '../../../../actions/transporter';
import moment from 'moment';
import { getCompaniesSupergroup } from '../../../../actions/companies';

const ApprovalHistoryScreen = props => {
  const { isLoading, rows, trips, user, count, offset } = props;
  
  var searchQry = props.location.search;
  const urlParams = new URLSearchParams(searchQry);

  const maxDateInterval = 6; // days
  const currentDate = moment().toISOString();

  const getParamsQuery = () => {
    var params = [];
    if (searchQry.length > 0) {
      var entries = urlParams.entries();
      for (const entry of entries) {
        params.push({
          type: entry[0],
          value: entry[1]
        });
      };
    };
    
    return params;
  };

  const statuses = [
    { id: 'approve', value: 'approve' },
    { id: 'reject', value: 'reject' }
  ];
  
  const getParamsString = (params) => {
    let params_string = _.map(params, par => par.value.length > 0 ? `&${par.type}=${par.value}` : '');
    if (params_string.length > 0) return params_string.join('');
    
    return '';
  };

  const defaultStartDate = moment().subtract(maxDateInterval, 'days').startOf('day').toISOString();
  const defaultEndDate = moment().endOf('day').toISOString();
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showTransporter, setShowTransporter] = useState(false);
  const [showShipper, setShowShipper] = useState(false);
  const [status, setStatus] = useState();
  const [transporter, setTransporter] = useState([]);
  const [shipper, setShipper] = useState([]);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [searchTransporter, setSearchTransporter] = useState('');
  const [displayedTransporter, setDisplayedTransporter] = useState(props.allTransporter);
  const [searchShipper, setSearchShipper] = useState('');
  const [displayedShipper, setDisplayedShipper] = useState(props.allShipper);
  const [filter, setFilter] = useState({});
  const [mainFilterProperty, setMainFilterProperty] = useState('trip_id');
  const [mainFilterValue, setMainFilterValue] = useState(null);
  const [paramsApproval, setParamsApproval] = useState(getParamsQuery());
  const [toast, setToast] = useState({ message: null, is_show: false });
  

  const goBack = () => {
    props.resetListApprovalHistory();
    props.history.go(-1);
  };

  const onClickApproval = () => {
    props.resetApprovalDecision()
  };

  const openFilter = () => {
    if (filter.status) {
      setStatus(filter.status);
    } else {
      setStatus([]);
    }
    
    if (filter.transporter) {
      setTransporter(filter.transporter);
    } else {
      setTransporter([]);
    }

    if (filter.shipper) {
      setShipper(filter.shipper);
    } else {
      setShipper([]);
    }

    if (filter.startDate) {
      setStartDate(filter.startDate);
    } else {
      setStartDate('')
    }

    if (filter.endDate) {
      setEndDate(filter.endDate);
    } else {
      setEndDate('')
    }

    setShowFilter(true);
  }

  const toggleStatus = (type) => {
    let new_status;
    if (_.find(status, stat => stat.id === type.id, false)) {
      // new_status = _.filter(status, stat => stat.id !== type.id);
      new_status = [];
    } else {
      // new_status = [...status, type];
      new_status = [type]
    };
    setStatus(new_status);
  };

  const toggleTransporter = (val) => {
    let selected_transporter;
    if (_.find(transporter, trp => trp.id === val.id)) {
      selected_transporter = _.filter(transporter, trp => trp.id !== val.id);
    } else {
      selected_transporter = [val];
      filterTransporter('');
      setShowTransporter(false);
    };
    setTransporter(selected_transporter);
  };

  const toggleShipper = (val) => {
    let selected_shipper;
    if (_.find(shipper, spr => spr.id === val.id)) {
      selected_shipper = _.filter(shipper, spr => spr.id !== val.id);
    } else {
      selected_shipper = [val];
      filterShipper('');
      setShowShipper(false);
    };
    setShipper(selected_shipper);
  };

 
  const resetFilter = () => {
    setStatus([]);
    setTransporter([]);
    setShipper([]);
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
  };

  const applyFilter = (offsetValue = 0) => {
    let new_filter = {};
    let new_params = [];
    
    if (status && status.length > 0) {
      new_filter.status = status;
      let params_status = _.map(status, stat => stat.id);
      new_params.push({type: 'status', value: params_status.join(',')});
    };
    
    if (transporter.length > 0) {
      new_filter.transporter = transporter;
      let params_transporter = _.map(transporter, trp => trp.id);
      new_params.push({type: 'company_transport_id', value: params_transporter.join(',')});
    };

    if (shipper.length > 0) {
      new_filter.shipper = shipper;
      let params_shipper = _.map(shipper, spr => spr.id);
      new_params.push({type: 'company_shipper_id', value: params_shipper.join(',')});
    };

    if (startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      if (end.diff(start, 'days') > maxDateInterval) {
        const tempEnd = start.add(maxDateInterval, 'days').toISOString(); 
        setEndDate(tempEnd);
        setToast({ message: `Range maksimal untuk filter tanggal yaitu ${maxDateInterval + 1} hari, tanggal berakhir filter telah berubah menjadi ${moment(tempEnd).format('DD/MM/YYYY')}`, is_show: true });
        return;
      } else {
        new_filter.startDate = startDate;
        new_filter.endDate = endDate;
        new_params.push({ type: 'filter_date', value: `${moment(startDate).toISOString()},${moment(endDate).toISOString()}`});
      }
    };

    if (mainFilterValue != null && mainFilterValue.length > 0) {
      new_filter[mainFilterProperty] = mainFilterValue;
      new_params.push({ type: mainFilterProperty, value: mainFilterValue });
    }

    setParamsApproval(new_params);
    setFilter(new_filter);
    setShowFilter(false);
    props.getListApprovalHistory(offsetValue, getParamsString(new_params));
    setDisableInfiniteScroll(false);
  };

  const loadMore = (e) => {
    applyFilter(offset + 10);
    
    e.target.complete();
    
    if (count > rows.length) {
      setDisableInfiniteScroll(false);
    } else {
      setDisableInfiniteScroll(true);
    };
  };


  const filterTransporter = (val) => {
    setSearchTransporter(val);
    let displayed = props.allTransporter.filter(item => {
      return _.includes(item.value.toLowerCase(), val.toLowerCase());
    });
    setDisplayedTransporter(displayed);
  };

  const filterShipper = (val) => {
    setSearchShipper(val);
    let displayed = props.allShipper.filter(item => {
      return _.includes(item.value.toLowerCase(), val.toLowerCase());
    });
    setDisplayedShipper(displayed);
  };

  const removeFilter = (type, val) => {
    let params_type, new_params;
    let new_filter = _.filter(filter[type], f => f.id !== val.id);
    switch (type) {
      case 'status':
        setStatus(new_filter);
        params_type = 'status';
        break;
      case 'transporter':
        setTransporter(new_filter);
        params_type = 'company_transport_id';
        break;
      case 'shipper':
        setShipper(new_filter);
        params_type = 'company_shipper_id';
        break;
      default:
        break;
    };

    setFilter({
      ...filter,
      [type]: new_filter
    });

    new_params = _.filter(paramsApproval, par => par.type !== params_type);
    new_params.push({type: params_type, value: _.map(new_filter, fil => fil.id).join(',')});
    setParamsApproval(new_params);
    props.getListApprovalHistory(0, getParamsString(new_params));
    setDisableInfiniteScroll(false);
  };

  const hasFilter = () => {
    return  _.get(filter, 'status.length', 0) > 0 || _.get(filter, 'transporter.length', 0) > 0 || _.get(filter, 'startDate.length', 0) > 0 || _.get(filter, 'endDate.length', 0) > 0;
  };

  useEffect(() => {
    applyFilter();
    props.getCompanies().then(result => {
      let res = result.payload.data.map(x => {
        return {
          id: x.id,
          value: x.name,
        };
      });
      
      setDisplayedTransporter(res);
    });

    props.getCompaniesSupergroup().then(result => {
      let res = result.payload.data.rows.map(x => {
        return {
          id: x.id,
          value: x.name,
        };
      });
      
      setDisplayedShipper(res);
    });
  }, []);

  useEffect(() => {
    rows.map(row => {
      if (row.item_type === 'trips' && !trips[row.item_id]) {
        props.getTrip(row.item_id, { populate: 'route_code' });
      }
    })
  }, [rows])

  useEffect(() => {
    applyFilter();
  }, [mainFilterValue, mainFilterProperty])

  return (
    <IonPage className="operator operator-report approval-screen">
      <IonContent className="operator-content">
        <div className="operator-header">
          <span style={{filter: 'invert(1) brightness(200%)', marginRight: '25px', cursor: 'pointer' }} onClick={() => goBack()}><img src={back} alt="back" /></span>
          <span>Riwayat Approval</span>
        </div>

        <div className="approval-content" style={{paddingBottom: disableInfiniteScroll ? '45px' : '0'}}>

        <IonGrid>
          <IonRow>
            <IonCol size-lg="4" size-md="3" size-sm="4">
              <IonSelect
                value={mainFilterProperty}
                onIonChange={e => setMainFilterProperty(e.detail.value)}
                interface="action-sheet"
                style={{
                  marginTop: '13px',
                  border: '1px solid #e0e0e0',
                  borderRadius: '10px',
                  height: '36px',
                  paddingTop: '8px',
                  paddingBottom: '0',
                  marginLeft: '10px',
                  marginRight: '10px'
                }}
              >
                <IonSelectOption value="trip_id">Trip ID</IonSelectOption>
                <IonSelectOption value="shipment_number">Shipment Number</IonSelectOption>
              </IonSelect>
            </IonCol>
            <IonCol size-lg="8" size-md="9" size-sm="8">
              <IonSearchbar
                mode="ios"
                inputmode="search"
                defaultValue={mainFilterValue}
                onIonChange={ (e) => setMainFilterValue(e.detail.value) }
                type='search'
                debounce="500"
                placeholder="Cari ..."
                style={{
                  padding: '0',
                  paddingRight: '10px'
                }}
              ></IonSearchbar>
            </IonCol>
          </IonRow>
        </IonGrid>

        {filter && hasFilter() &&
          <div className="operator-chip-container" style={{padding: '0 20px'}}>

            {filter.status && filter.status.length > 0 && 
              filter.status.map(stat => (
                <div className="operator-chip" key={stat.id} onClick={() => removeFilter('status', stat)}>
                  {stat.value}
                  <span style={{marginLeft: '15px'}}>&times;</span>
                </div>
              ))
            }
            
            {filter.transporter && filter.transporter.length > 0 && 
              filter.transporter.map(trp => (
                <div className="operator-chip" key={trp.id} onClick={() => removeFilter('transporter', trp)}>
                  {trp.value}
                  <span style={{marginLeft: '15px'}}>&times;</span>
                </div>
              ))
            }

            {filter.shipper && filter.shipper.length > 0 && 
              filter.shipper.map(spr => (
                <div className="operator-chip" key={spr.id} onClick={() => removeFilter('shipper', spr)}>
                  {spr.value}
                  <span style={{marginLeft: '15px'}}>&times;</span>
                </div>
              ))
            }

            {filter.startDate && filter.startDate.length > 0 && filter.endDate && filter.endDate.length > 0 && 
              <div className="operator-chip">
                Date : {moment(startDate).format('DD MMM YYYY')} - {moment(endDate).format('DD MMM YYYY')}
              </div>
            }
          </div>
        }

          { rows.length == 0 ?
            <div style={{padding: '30px'}}>No data found</div>
          :
            <div>
              {
                rows.map((row, index) => {
                  row.info_date = getApprovalTime(row, user.id);
                  row.status = getApprovalStatus(row, user.id);
                  return (
                    <ApprovalItem key={index} item={row} trips={trips} onItemClick={ onClickApproval } />
                  )
                })
              }
            </div>
          }
        </div>
        { 
          disableInfiniteScroll ? 
          (
            <div align="center" style={{ marginTop: '0px', marginBottom: '45px' }}>
              <small>Akhir dari data riwayat approval</small>
            </div>
          ) : <></>
        }
        <IonInfiniteScroll 
            threshold="20%"
            disabled={isLoading || disableInfiniteScroll}
            onIonInfinite={(e) => loadMore(e)}
          >
          <IonInfiniteScrollContent loadingSpinner="circular" loadingText="Loading more data..." />
        </IonInfiniteScroll>

        <IonFab vertical="bottom" horizontal="center" slot="fixed">
          <IonFabButton size="small" color="light" onClick={openFilter}>
            <img src={prefernce} alt='' style={{width: '16px', verticalAlign: 'middle'}} />
            <span style={{fontSize: '16px', color: '#fe6600', marginLeft: '12px'}}>Filter</span>
          </IonFabButton>
        </IonFab>

        <IonModal isOpen={showTransporter} cssClass="operator" swipeToClose={true} onDidDismiss={() => setShowTransporter(false)}>
          <IonContent>
            <div className="operator-header">
              <span style={{fontSize: '20px', marginRight: '25px'}} onClick={() => setShowTransporter(false)}>&times;</span>
              <span>Transporter</span>
            </div>
            <div className="operator-filter">
              <IonList>
                <IonSearchbar 
                  class="operator-filter-search" 
                  value={searchTransporter} 
                  onIonChange={e => filterTransporter(e.detail.value)}
                  placeholder="Search Transporter"
                />
                {displayedTransporter.map(trp => (
                  <IonItem key={trp.id} onClick={() => toggleTransporter(trp)}>
                    <IonLabel>{trp.value}</IonLabel>
                    {_.find(transporter, t => t.id === trp.id) &&
                      <img src={tick} alt='' slot="end" />
                    }
                  </IonItem>
                ))}
              </IonList>
            </div>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showShipper} cssClass="operator" swipeToClose={true} onDidDismiss={() => setShowShipper(false)}>
          <IonContent>
            <div className="operator-header">
              <span style={{fontSize: '20px', marginRight: '25px'}} onClick={() => setShowShipper(false)}>&times;</span>
              <span>Shipper</span>
            </div>
            <div className="operator-filter">
              <IonList>
                <IonSearchbar 
                  class="operator-filter-search" 
                  value={searchShipper} 
                  onIonChange={e => filterShipper(e.detail.value)}
                  placeholder="Search Shipper"
                />
                {displayedShipper.map(srp => (
                  <IonItem key={srp.id} onClick={() => toggleShipper(srp)}>
                    <IonLabel>{srp.value}</IonLabel>
                    {_.find(shipper, s => s.id === srp.id) &&
                      <img src={tick} alt='' slot="end" />
                    }
                  </IonItem>
                ))}
              </IonList>
            </div>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showFilter} cssClass="operator" swipeToClose={true} onDidDismiss={() => setShowFilter(false)}>
          <IonContent>
            <div className="operator-header">
              <span style={{fontSize: '20px', marginRight: '25px'}} onClick={() => setShowFilter(false)}>&times;</span>
              <span>Filter</span>
              <span onClick={resetFilter} style={{float: 'right', fontSize: '14px', marginTop: '5px', cursor: 'pointer'}}>Reset</span>
            </div>
            <div className="operator-filter">

              <div className="operator-filter-item">
                <div className="filter-item-title">
                  Status
                  <img src={rightsmall} alt='' className="rightsmall" />
                </div>
                <div className="operator-chip-container">
                  {statuses.map(stat => (
                    <div key={stat.id} className={`operator-chip${_.find(status, s => s.id === stat.id, false) ? ' selected' : ''}`} onClick={() => toggleStatus(stat)}>{stat.value}</div>
                  ))}
                </div>
              </div>

              <div className="operator-filter-item">
                <div className="filter-item-title" onClick={() => setShowTransporter(true)}>
                  Transporter
                  <img src={rightsmall} alt='' className="rightsmall" />
                </div>
                {transporter.length > 0 &&
                  <div className="operator-chip-container">
                    {transporter.map(trp => (
                      <div key={trp.id} className="operator-chip selected">{trp.value}</div>
                    ))}
                  </div>
                }
              </div>
              
              {
                _.get(user, 'supergroup', null) ?
                (
                  <div className="operator-filter-item">
                    <div className="filter-item-title" onClick={() => setShowShipper(true)}>
                      Shipper
                      <img src={rightsmall} alt='' className="rightsmall" />
                    </div>
                    {shipper.length > 0 &&
                      <div className="operator-chip-container">
                        {shipper.map(srp => (
                          <div key={srp.id} className="operator-chip selected">{srp.value}</div>
                        ))}
                      </div>
                    }
                  </div>
                ) : ''  
              }

              <div className="operator-filter-item">
                <div className="filter-item-title">
                  Date
                  <img src={rightsmall} alt='' className="rightsmall" />
                </div>
                <div>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="5">
                        <div className="filter-date-label">Start</div>
                        <IonDatetime 
                          displayFormat="DD/MM/YYYY" 
                          placeholder="Select Date" 
                          value={startDate} 
                          onIonChange={e => setStartDate(e.detail.value)}
                          className="filter-date"
                        />
                      </IonCol>

                      <IonCol size="2" style={{textAlign: 'center', alignSelf: 'center'}}>
                        <div style={{color: '#979797', marginTop: '30px'}}>&mdash;</div>
                      </IonCol>

                      <IonCol size="5" style={{textAlign: 'right'}}>
                        <div className="filter-date-label">End</div>
                        <IonDatetime 
                          displayFormat="DD/MM/YYYY" 
                          placeholder="Select Date" 
                          value={endDate} 
                          onIonChange={e => setEndDate(e.detail.value)}
                          max={currentDate}
                          className="filter-date"
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              </div>
            </div>

            <div className="operator-footer">
              <IonButton expand="block" className="btn-filter" onClick={ () => applyFilter() }>Terapkan</IonButton>
            </div>
          </IonContent>
        </IonModal>

        <IonToast
          isOpen={toast.is_show}
          onDidDismiss={() => setToast({message: null, is_show: false })}
          message={toast.message}
          duration={3000}
        />
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = state => {
  const user = state.user.data;
  const { rows } = state.approval.history;
  const { dictionaryTrip } = state.trip;
  const { countHistory, offsetHistory } = state.approval;
  const allTransporter = state.transporter.lists.map(x => {
    return {
      id: x.id,
      value: x.name,
    };
  });
  const allShipper = state.companies.lists.map(x => {
    return {
      id: x.id,
      value: x.name,
    };
  });
  
  return {
    isLoading : state.approval.history.isLoading || state.trip.loading.findTrip,
    rows,
    trips: dictionaryTrip,
    user,
    count: countHistory,
    offset: offsetHistory,
    allTransporter,
    allShipper
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getListApprovalHistory, getTrip, resetApprovalDecision, getCompanies, resetListApprovalHistory, getCompaniesSupergroup
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalHistoryScreen);
