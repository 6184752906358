import _ from 'lodash';

export const hasRole = (permissions, module, action) => {
  if (permissions) {
    if (_.find(permissions, { 'module': '*' }) && module != 'sysconfig') {
      return true
    }

    var menu = _.filter(permissions, { 'module': module });

    if (menu.length > 0 && action) {
        if (
          _.find(menu, function (m) {
            return m.action == '*'
          })
        ) {
          return true
        }

        var foundAction = _.find(menu, function (m) {
          return m.action == action
        });

        return foundAction ? true : false
    }
    return menu.length > 0 ? true : false
  }

  return false
}