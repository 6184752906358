import React from 'react';
import {IonCard, IonSkeletonText, IonCardHeader, IonCardTitle, IonCardContent, IonGrid, IonRow, IonCol, IonButton, IonRippleEffect} from '@ionic/react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {setOrder} from '../../../../actions/w_trip'
import PackageItem from '../../../../components/PackageItem';

const OrderCards = ({orders=[], showSkeleton=false, modalOrder, setOrder}) => {
  const getShortId  = (id) => {
    if (!id) {
      return '';
    }
    return id.slice(0,8) + id.slice(22);
  };

  const updateOrder = order => (e) => {
    e.preventDefault();
    setOrder(order);
  };

    return (
    <>
      {orders.map(order => {
        order.actual_packages = order.packages ? order.packages.filter(pkg => pkg.actual_quantity) : [];
        return (
        <IonCard className="order-card ion-activatable" key={order.id} onClick={updateOrder(order)}>
          {showSkeleton
          ? (
            <>
              <IonCardHeader>
                <IonCardTitle>
                  <IonSkeletonText animated />
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonSkeletonText animated style={{width: "60%"}}/>
                <IonGrid>
                  <IonRow>
                    <IonCol size="2">
                      <IonButton disabled color="light" expand="block"></IonButton>
                    </IonCol>
                    <IonCol size="2">
                      <IonButton disabled color="light" expand="block"></IonButton>
                    </IonCol>
                    <IonCol size="2">
                      <IonButton disabled color="light" expand="block"></IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </>)
          : (
            <>
              <IonRippleEffect></IonRippleEffect>
              <IonCardHeader>
                <IonCardTitle>
                  <span className="card-title">
                    <strong>{order.shipment_number}</strong>/<span style={{ color: '#9b9b9b' }}>{order.order_number}</span>
                    <span className="ion-float-right" style={{ color: '#9b9b9b' }}>{getShortId(order.id)}</span>
                  </span>
                </IonCardTitle>
              </IonCardHeader>

              {order.actual_packages.length > 0 && <IonCardContent>
                List delivery notes:<br/>
                  <IonGrid>
                    <IonRow>
                    </IonRow>
                    <IonRow>
                      {order.actual_packages && order.actual_packages.map((pkg, i) => (
                        <IonCol size="2" key={i}>
                          <PackageItem type={pkg.package_type}><strong>{pkg.actual_quantity}</strong></PackageItem>
                          {/* <PackageItem><strong>{pkg.updatedQuantity}</strong></PackageItem> */}
                      </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>
              </IonCardContent>}
            </>
          )}
      </IonCard>
      )})}
    </>
    );
};


const mapStateToProps = ({w_trip}) => ({
  modalOrder: w_trip.order
});
const mapDispatchToProps = dispatch => bindActionCreators({setOrder}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps) (OrderCards);
