import React, { useState, memo, useEffect} from 'react';
import { IonMenu, IonItem, IonList, IonContent, IonHeader, IonTitle, IonToolbar, IonAvatar, IonGrid, IonRow, IonCol, IonAlert, IonIcon} from '@ionic/react';
import { withRouter } from "react-router-dom";
import defaultAvatar from '../assets/no-avatar.png';
import { logout} from '../actions/user';
import { logOut, person, home } from 'ionicons/icons'
import taskIcon from '../assets/icons/task.svg'
import scanIcon from '../assets/icons/scan.svg';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
import { Typography } from '@material-ui/core';

const SecuritySideMenu = memo(({match, user, logout, history}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [isActiveVehicleChecklist, setIsActiveVehicleChecklist] = useState(false)
  const image_url = user && user.data && user.data.image_url ? user.data.image_url : defaultAvatar;

  useEffect(() => {
    if (user && user.data && user.data.company && user.data.company.config && user.data.company.config.settings) {
      const parsedSetting = JSON.parse(user.data.company.config.settings)
      checkVehicleChecklist(parsedSetting)
    }
  }, [user])

  const doLogout = () => {
    logout().then(_ => { 
      window.location.href = '/login'
    })
  }

  const urlGen = (path) => {
    return `${match.url}/${path}`;
  };

  const getUserFrontName = () => {
    return user && user?.data && user.data?.name && user.data?.name?.split(" ")[0] 
  }
  
  const getCompanyName = () => {
    return user && user?.data && user?.data?.company && user?.data?.company?.name 
  }

  const checkVehicleChecklist = (setting) => {
    const isActive = setting.vehicle_checklist && setting.vehicle_checklist.is_active
    setIsActiveVehicleChecklist(isActive)
  }

  return (
    <>
      <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'Keluar'}
          message={'Anda yakin ingin keluar?'}
          buttons={[{
            text: 'Batal',
            role: 'cancel',
          }, {
            text: 'Keluar',
            handler: () => doLogout(),
          }]}
        />
      <IonMenu side="start" contentId='mobile-security' type='overlay'>
        <IonHeader>
          <IonToolbar color="primary">
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonAvatar style={{ margin: 'auto' }}>
                    <img src={image_url} alt='avatar'/>
                  </IonAvatar>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol style={{textAlign: 'center'}}>
                  <IonTitle>{getUserFrontName()}</IonTitle>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol style={{textAlign: 'center'}}>
                  <Typography>{getCompanyName()}</Typography>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonHeader>
        <IonContent color="primary">
          <IonList className="sidemenu-list ion-no-padding ion-margin-vertical">
            <IonItem color="primary" lines="none" href={urlGen('home')}>
              <IonIcon icon={home} mode="ios" className="ion-margin-end" size="large"></IonIcon> Home
            </IonItem>
            <IonItem color="primary" lines="none" href={urlGen('scan')}>
              <IonIcon icon={scanIcon} mode="ios" className="ion-margin-end" size="large"></IonIcon> Scan (Get In/Get Out)
            </IonItem>
            {
              isActiveVehicleChecklist &&
              <IonItem color="primary" lines="none" href={urlGen('vehicle-checklist')}>
                <IonIcon icon={taskIcon} mode="ios" className="ion-margin-end" size="large"></IonIcon> Vehicle Checklist
              </IonItem>
            }
            <IonItem color="primary" lines="none" href={urlGen('profile')}>
              <IonIcon icon={person} mode="ios" className="ion-margin-end" size="large"></IonIcon> Profile
            </IonItem>
            <IonItem color="primary" lines="none" onClick={()=>setShowAlert(true)}>
              <IonIcon icon={logOut} mode="ios" style={{ transform: 'rotate(180deg)' }}  className="ion-margin-end" size="large"></IonIcon> Logout
            </IonItem>
          </IonList>
        </IonContent>
      </IonMenu>
    </>
  );
});

const mapstp = ({user}) => ({user});
const mapdtp = dispatch => bindActionCreators({logout}, dispatch);

export default connect(mapstp, mapdtp) (withRouter(SecuritySideMenu));