import React from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonBackButton,
  IonContent,
} from '@ionic/react';
import {connect} from 'react-redux';
import { useParams } from 'react-router';
import _ from 'lodash';
import home from '../../../../assets/icons/home-white.svg';
import { Link } from 'react-router-dom';

const OrderDetailPackage = (props) => {
  const { id } = useParams();
  const { data } = props;
  const packages = _.get(data, 'packages', []) || [];

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="ritase-toolbar" color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/distributor/inbound/detail/${id}`}></IonBackButton>
          </IonButtons>
          <IonTitle>Muatan</IonTitle>
          <IonButtons slot="end">
            <Link to={'/distributor/inbound/ongoing'}>
              <div style={{ marginRight: '20px' }}>
                <img src={home} alt=""/>
              </div>
            </Link>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="trip-detail">
          {packages.map((item, i) => (
            <div key={i} className="order-section">
              <div className="order-content">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <div style={{ fontWeight: 'bold' }}>
                      <span>
                        { _.get(item, 'sku') || _.get(item, 'obd') }
                      </span>
                      {!_.isNil(_.get(item, 'sku')) &&
                        <span style={{ color: '#9B9B9B', marginLeft: '10px' }}>
                          { _.get(item, 'obd', '') }
                        </span>
                      }
                    </div>

                    <div style={{ color: '#404040', marginTop: '5px' }}>
                      {_.get(item, 'description', '-')}
                    </div>
                  </div>

                  <div style={{ textAlign: 'center' }}>
                    <div className="package-background">
                    { _.get(item, 'delivered_quantity') || 
                      _.get(item, 'actual_quantity') || 
                      _.get(item, 'quantity', 0) ||
                      0
                    }
                    </div>
                    
                    {_.get(item, 'package_type') &&
                      <div style={{ color: '#9B9B9B', marginTop: '5px' }}>
                        {item.package_type}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </IonContent>
    </IonPage>
  );
}

const mapStateToProps = ({order: {inbound, orderDetail: {loading, data, error}}}) => {
  return ({
    inbound,
    loading,
    data,
    error,
  })
};

export default connect(mapStateToProps)(OrderDetailPackage);
