import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonBadge, IonButton, IonFooter, IonCard, IonTextarea, IonItem, IonSpinner, IonModal, IonToast} from '@ionic/react';
import _ from 'lodash';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { detail as getDetail, resetIssueDetail, addComment, close as closeIssue } from '../../../../actions/issue';
import './IssueDetailScreen.scss';
import IssueFeedbacks from './IssueFeedbacks'; 
import back from '../../../../assets/new-icons/back.svg';
import questionMarkIcon from '../../../../assets/new-icons/question-mark-orange.svg';


const IssueDetailScreen = (props) => {
  const { data, user } = props;
  const [id, setID] = useState(props.match.params.id);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [postFeedback, setPostFeedback] = useState(false);
  const [newFeedback, setNewFeedback] = useState('');
  const [closingIssue, setClosingIssue] = useState(false);
  const [closingMessage, setClosingMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  if (id !== props.match.params.id) {
    setID(props.match.params.id);
  };

  useEffect(() => {
    if (id || !postFeedback || !closingIssue) {
      props.getDetail(id);
    }
  }, [id, postFeedback, closingIssue]);

  const goBack = () => {
    props.history.push('/operator/issue');
  };

  const COLORS = {
    closed: '#4a4a4a',
    vendor: '#009abf',
    client: '#f23d3d'
  }

  const getStatus = (issue) => {
    let obj = {};
    if (issue.status == 'open') {
      if (issue.last_action_role == 'vendor') {
           obj.code = 'vendor'
           obj.value = 'Waiting Shipper to Respond';
      } else if (issue.last_action_role == 'client') {
          obj.code = 'client';
          obj.value = 'Waiting Transporter to Respond';
      }
    } else if (issue.status == 'closed') {
        obj.code = 'closed';
        obj.value = 'Ticket Closed';
    }
    return obj;
  }

  const sendRespond = () => {
    if (!postFeedback && newFeedback == '') {
      setErrorMessage('Feedback is mandatory');
      setShowToast(true);
    } else if (!postFeedback && newFeedback != '') {
      let payload = { message: newFeedback };
      setPostFeedback(true);
      props.addComment(id, payload)
      .then(result => {
        if (result && result.payload && result.payload.data && result.payload.data.errorMessage) throw result.payload.data;
        setPostFeedback(false);
        setNewFeedback('');
        setErrorMessage('');
      })
      .catch(err => {
        setErrorMessage(err.errorMessage);
        setShowToast(true);
        setPostFeedback(false);
      });
    }
  }

  const closeIssue = () => {
    if (!closingIssue && closingMessage == '') {
      setErrorMessage('Reason is mandatory');
      setShowToast(true);
    } else if (!closingIssue && closingMessage != '') {
      let payload = { message: closingMessage, user_name: user.name };
      setClosingIssue(true);
      props.closeIssue(id, payload)
      .then(result => {
        if (result && result.payload && result.payload.data && result.payload.data.errorMessage) throw result.payload.data;
        setClosingIssue(false);
        setClosingMessage('');
        setErrorMessage('');
        setShowConfirmation(false);
      })
      .catch(err => {
        setErrorMessage(err.errorMessage);
        setShowToast(true);
        setClosingIssue(false);
      });
    }
  }

  return (
    <IonPage className="operator order-detail">
      <IonContent className="operator-content">
        <div className="operator-header">
          <span style={{filter: 'invert(1) brightness(200%)', marginRight: '25px'}} onClick={() => goBack()}><img src={back} alt="back" /></span>
          <span>Issue Detail</span>
        </div>

        <div className="order-section">
          <div className="order-content">
            <IonGrid>
              <IonRow className="order-row">
                <IonCol size="12" className="order-row-title">
                  Created By
                </IonCol>
                <IonCol size="12" style={{ marginTop: '8px' }}>
                  { data.logs && data.logs.length > 0 && data.logs[0].user && data.logs[0].user.name }
                </IonCol>
              </IonRow>
              <IonRow className="order-row">
                <IonCol size="12" className="order-row-title">
                  Created At
                </IonCol>
                <IonCol size="12" style={{ marginTop: '8px' }}>
                  { !_.isNil(data.created_at) ? moment(data.created_at).format('DD/MM/YYYY, HH:mm') : '-' }
                </IonCol>
              </IonRow>
              <IonRow className="order-row">
                <IonCol size="12" className="order-row-title">
                  Reason
                </IonCol>
                <IonCol size="12" style={{ marginTop: '8px' }}>
                  {_.get(data, 'reason.description', '')}
                </IonCol>
              </IonRow>
              <IonRow className="order-row">
                <IonCol size="12" className="order-row-title">
                  Description
                </IonCol>
                <IonCol size="12" style={{ marginTop: '8px' }}>
                {_.get(data, 'description', '')}
                </IonCol>
              </IonRow>
              <IonRow className="order-row">
                <IonCol size="12" className="order-row-title">
                  Status
                </IonCol>
                <IonCol size="12" style={{ marginTop: '8px' }}>
                <IonBadge style={{ padding: '5px 8px', borderRadius: '10px', backgroundColor: COLORS[getStatus(data).code] }}>{getStatus(data).value}</IonBadge>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>

        <span style={{ color: '#595959', marginLeft: '20px' }}>Feedbacks</span>

        <div className="order-section" style={{ marginTop: '10px' }}>
          <div className="order-content">
            <IssueFeedbacks feedbacks={ _.get(data, 'conversation.messages', []) }/>
            { data.status == 'open' &&
              <IonGrid>
                <IonRow>
                  <IonCard style={{ width: '100%' }}>
                    <IonTextarea placeholder="Add Feedback" value={newFeedback} onIonChange={e => setNewFeedback(e.target.value)}></IonTextarea>
                  </IonCard>
                </IonRow>
                <IonRow>
                  <IonCol size="12">
                    <IonItem lines="none">
                      <IonButton slot="end" onClick={()=> sendRespond()}>{ postFeedback ? <IonSpinner name="dots"></IonSpinner> : <span style={{ padding: '8px 15px'}}>Send Respond</span> }</IonButton>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
            }
          </div>
        </div>
        
        

      </IonContent>
      { data.status == 'open' && 
        <IonFooter>
          <div className="order-section">
            <div className="order-content">
            <IonButton expand="block" onClick={() => setShowConfirmation(true) }>Close Issue</IonButton>
            </div>
          </div>
        </IonFooter>
      }

    <IonModal
      isOpen={showConfirmation}
      cssClass='modal-close-issue'
      swipeToClose={true}
      onDidDismiss={() => setShowConfirmation(false)}>
        <IonGrid style={{ width: '100%' }}>
          <IonRow>
            <IonCol size="12">
              <div className="ion-text-center">
                <img src={questionMarkIcon} style={{height: '20%', width: '20%', margin: '4% auto 3%'}}/>
              </div> 
            </IonCol>
            <IonCol size="12">
              <div className="ion-text-center" style={{ paddingBottom: '3%'}}>
                <span style={{color: '#595959', fontSize: '16px', fontWeight: '600' }}>Do you want to close this issue?</span>
              </div>
            </IonCol>
            <IonCol size="12">
              <div className="ion-text-center">
                <textarea style={{width: '100%'}} rows="3" placeholder="Write the reason" onChange={(e) => setClosingMessage(e.target.value) }></textarea>
              </div>
            </IonCol>        
            <IonCol size="6">
              <IonButton color="medium" expand="block" onClick={() => setShowConfirmation(false)}>Back</IonButton>
            </IonCol>
            <IonCol size="6">
              <IonButton expand="block" onClick={()=> closeIssue()}>{ closingIssue ? <IonSpinner name="dots"></IonSpinner> : <span>Yes</span> }</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
    </IonModal>

    <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false) }
        message={errorMessage}
        color="danger"
        position="middle"
        duration={2000}
    />

    </IonPage>
  );
};

const mapStateToProps = state => {
  return {
    data: state.issue.detail,
    user: state.user.data
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getDetail, resetIssueDetail, addComment, closeIssue
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueDetailScreen);