import React from 'react';
import _ from 'lodash';
import './ApprovalLogApprover.scss';

const ApprovalLogApprover = ({ obj }) => {

  return (
    <>
      { 
        obj.status != null 
        ?
          <span>
            <span className={`status ${ _.get(obj, 'status').toLowerCase() }`}> { _.get(obj, 'status') }</span> by { _.get(obj, 'approver.name') }
            <span class="timestamp is-bottom">{ _.get(obj, 'formatted_date', '-') }</span>
          </span>
        : obj.status === null && !obj.is_completed_approval 
        ? 
        <span className="waiting-approval">Menunggu approval dari { _.get(obj, 'approver.name') }</span>
        : <></>
      }
    </>
  );
}

export default ApprovalLogApprover;