/* eslint-disable */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IonPage, IonContent, IonSpinner, IonButton, IonBadge } from '@ionic/react';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { getListApproval, resetApprovalDecision, countListApprovalHistory } from '../../../../actions/approval';
import { getDetailDefine as getTrip } from '../../../../actions/trip';
import back from '../../../../assets/new-icons/back.svg'
import './ApprovalScreen.scss';
import getApprovalTime from '../../../../helpers/getApprovalTime';
import getApprovalStatus from '../../../../helpers/getApprovalStatus';
import history from '../../../../assets/new-icons/history.svg';
import ApprovalItem from './ApprovalItem';
import moment from 'moment';

const ApprovalScreen = props => {
  const { isLoading, rows, trips, user, countHistory } = props;

  const getParamsString = (params) => {
    let params_string = _.map(params, par => par.value.length > 0 ? `&${par.type}=${par.value}` : '');
    if (params_string.length > 0) return params_string.join('');
    
    return '';
  };

  const historyParams = [
    { type: 'filter_date', value: `${moment().subtract(6, 'days').startOf('day').toISOString()},${moment().endOf('day').toISOString()}` }
  ];


  useEffect(() => {
    props.getListApproval()
  }, []);

  useEffect(() => {
    if (countHistory == 0) {
      props.countListApprovalHistory(0, getParamsString(historyParams))
    }
  }, [countHistory])

  useEffect(() => {
    rows.map(row => {
      if (row.item_type === 'trips') {
        props.getTrip(row.item_id, { populate: 'route_code' });
      }
    })
  }, [rows])

  const goBack = () => {
    props.history.go(-1);
  };

  const onClickApproval = () => {
    props.resetApprovalDecision()
  };

  return (
    <IonPage className="operator operator-report approval-screen">
      <IonContent className="operator-content">
        <div className="operator-header">
          <span style={{filter: 'invert(1) brightness(200%)', marginRight: '25px', cursor: 'pointer' }} onClick={() => goBack()}><img src={back} alt="back" /></span>
          <span>Approval</span>

          <div className="history-approval-header">
            <Link to='/operator/approval/history'>
              <IonButton className="approval-button" fill="clear">
                <IonBadge className="notification-badge" color="danger">{ countHistory > 99 ? '99+' : countHistory }</IonBadge>
                <img src={history} alt="history" />
              </IonButton>
            </Link>
          </div>
        </div>

        <div className="approval-content">
          { isLoading ?
            <div className="center-vh">
              <IonSpinner name="crescent" />
            </div>
          : !isLoading && rows.length == 0 ?
            <div style={{padding: '30px'}}>No data found</div>
          :
            <div>
              {
                rows.sort((firstEl, secondEl) => {
                  const firstElTime = getApprovalTime(firstEl, user.id);
                  const secondElTime = getApprovalTime(secondEl, user.id);
                  return new Date(secondElTime) - new Date(firstElTime);
                }).map((row, index) => {
                  row.info_date = getApprovalTime(row, user.id);
                  row.status = getApprovalStatus(row, user.id);
                  return (
                    <ApprovalItem key={index} item={row} trips={trips} onItemClick={ onClickApproval } />
                  )
                })
              }
            </div>
          }
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = state => {
  const user = state.user.data;
  const { rows } = state.approval.list;
  const { dictionaryTrip } = state.trip;
  const { countHistoryCriteria } = state.approval;

  return {
    isLoading : state.approval.list.isLoading || state.trip.loading.findTrip,
    rows,
    trips: dictionaryTrip,
    user,
    countHistory: countHistoryCriteria
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getListApproval, getTrip, resetApprovalDecision, countListApprovalHistory
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalScreen);
