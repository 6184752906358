import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonButton, IonInput, IonModal, IonSearchbar, IonList, IonItem, IonLabel, IonSelect, IonSelectOption, IonTextarea, IonCheckbox, IonDatetime, IonToast, IonSpinner, IonAlert, IonFooter, IonIcon } from '@ionic/react';
import { bindActionCreators } from 'redux';
import { getParams as getReasons } from '../../../../actions/reason';
import { create as createIssue, get as getIssues } from '../../../../actions/issue';
import { getTripsParam as getTrips, resetTrips } from '../../../../actions/w_trip';
import _ from 'lodash';
import back from '../../../../assets/new-icons/back.svg';
import tick from '../../../../assets/new-icons/tick.svg';
import questionMark from '../../../../assets/new-icons/question-mark-orange.svg';
import './CreateIssueScreen.scss'
import TripIssueCards from './TripIssueCards';
import TripIssueCard from './TripIssueCard';

const CreateIssueScreen = (props) => {

  const [currentStep, setCurrentStep] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');
  const [item, setItem] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [isLoadingShipment, setLoadingShipment] = useState(props.isLoadingTrips);
  const [searchShipmentNumber, setSearchShipmentNumber] = useState('');
  const [displayedTrips, setDisplayedTrips] = useState([]);
  const [selectedTrip, setSelectedTrip] = useState();
  const [showReason, setShowReason] = useState(false);
  const [showReasonInfo, setShowReasonInfo] = useState(false);
  const [searchReason, setSearchReason] = useState('');
  const [displayedReason, setDisplayedReason] = useState(props.allReason);
  const [reason, setReason] = useState('');
  const [location, setLocation] = useState();
  const [displayedLocation, setDisplayedLocation] = useState([]);
  const [title, setTitle] = useState('');
  const [notes, setNotes] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const maxStep = 1;

  useEffect(() => {
    let container = document.getElementById('createIssueContainer');
    container.scrollToTop();
  }, [currentStep]);

  useEffect(() => {
    setDisplayedReason(props.allReason);
  }, [props.allReason])

  useEffect(() => {
    setDisplayedTrips(props.allTrips);
  }, [props.allTrips])

  useEffect(() => {
    setLoadingShipment(props.isLoadingTrips);
  }, [props.isLoadingTrips])

  useEffect(() => {
    props.getReasons(`type=collab_tickets&company_shipper_id=${props.user.company_id}&populate=collab_ticket_extras`);
  }, []);

  useEffect(() => {
    const ionSelects = document.querySelectorAll('ion-select');
    ionSelects.forEach((sel) => {
      let icon = sel.shadowRoot.querySelectorAll('.select-icon-inner');
      // if (icon.length <= 0) {
      //   return;
      // }
      
      icon.forEach((elem) => {
        elem.setAttribute('style', 'display: none;');
      });
    });
  });

  useEffect(() => {
    if (item.shipment_number && item.contact_id && item.description && item.title && item.reason_id) {
      submitIssue(item);
    }
  }, [item])

  const goBack = () => {
    if (currentStep == 0) {
      props.history.goBack();
      props.resetTrips();
    } else {
      setCurrentStep(currentStep - 1)
    }
  }

  const checkForm = () => {
    let has_error = false;
    let msg = '';
    let temp = {};
    switch (currentStep) {
      case 0:
        if (!selectedTrip || !selectedTrip.id) {
            msg = 'Trip harus dipilih';
            has_error = true;
            break;
        } else {
          has_error = false;

          let tempLocation = [];
          selectedTrip.orders.map((order) => {
            let oriObj = {
              id: order.origin_contact_id,
              value: order.origin_contact_name
            };
            let destObj = {
              id: order.destination_contact_id,
              value: order.destination_contact_name
            };
            tempLocation.push(oriObj, destObj);
          })

          setDisplayedLocation(tempLocation);
          
          temp = {
            shipment_number: selectedTrip.shipment_number
          }

          setItem({...item, ...temp});
        }
        break;
      case 1:
        if (!reason) {
          msg = `Reason harus diisi`;
          has_error = true;
          break;
        }
        else if (!location) {
          msg = `Location harus diisi`;
          has_error = true;
          break;
        }
        else if (!title) {
          msg = `Title harus diisi`;
          has_error = true;
          break;
        }
        else if (!notes) {
          msg = `Notes/description harus diisi`;
          has_error = true;
          break;
        } else {
          has_error = false;
          temp = {
            contact_id: location,
            description: notes,
            title: title,
            reason_id: reason.id
          }
          setItem({...item, ...temp});
        }
        break;
      default:
        break;
    }

    setShowToast(has_error);
    setMessage(msg);
    if (!has_error && currentStep <= maxStep - 1) setCurrentStep(currentStep + 1);
  };

  const submitIssue = (data) => {
    setLoading(true);
    props.createIssue(data).then((result) => {
      let tempResult = result;
      if (_.get(result, 'payload.data.errorMessage') || _.get(result, 'payload.status') !== 200) {
        setMessage(_.get(result, 'payload.data.errorMessage'));
        setLoading(false);
        setShowAlert(true);
      } else {
        props.getIssues().then(res => {
          setLoading(false);
          setShowSuccessAlert(true);
        })
      }
    });
  }

  const getParamsString = (params) => {
    let params_string = _.map(params, par => par.value.length > 0 ? `&${par.type}=${par.value}` : '');
    if (params_string.length > 0) return params_string.join('');
    
    return '';
  };

  const onSearchShipmentNumber = (e) => {
    if(e.keyCode == 13) {
      let new_params = [];
      new_params.push({type: 'flags', value: 'shipper_has_collab_tickets'});
      new_params.push({type: 'populate', value: 'driver,company_shipper'});
      if (searchShipmentNumber.includes('TDS-TRI')) {
        new_params.push({type: 'id', value: searchShipmentNumber});
      } else {
        new_params.push({type: 'shipment_number', value: searchShipmentNumber});
      }
      props.getTrips(0, getParamsString(new_params));
   }
  }

  const onSelectTrip = (trip) => {
    setSelectedTrip(trip);
  }

  const filterReason = (val) => {
    setSearchReason(val);
    let displayed = props.allReason.filter(item => {
      return item.value.toLowerCase().includes(val.toLowerCase());
    });
    setDisplayedReason(displayed);
  };

  const toggleReason = (val) => {
    let selected_reason = reason.id === val.id ? '' : val;
    if (reason.id !== val.id) setShowReason(false);
    setReason(selected_reason);
  };

  const getReasonDescription = (reason, language = 'id') => {
    let tempDesc = _.get(reason, 'collab_ticket_extras.description', null);
    if (tempDesc) {
      let objDesc = JSON.parse(tempDesc);
      if (Object.keys(objDesc).length > 0 && objDesc[language]) {
        return objDesc[language];
      } 
      return '-';
    }
    return '-';
  };

  const onSuccessAlert = (status) => {
    setShowSuccessAlert(status);
    props.history.push('/operator/issue');
  }

  return (
    <IonPage className="operator create-issue">
      <IonContent className="operator-content" id="createIssueContainer" scrollEvents={true}>
        <div className="operator-header">
          <span style={{filter: 'invert(1) brightness(200%)', marginRight: '25px'}} onClick={() => goBack()}><img src={back} alt="back" /></span>
          <span>Create Issue</span>
        </div>

        <div className="cr-issue-section cr-issue-step" style={{ padding: '15px 0px'}}>
            <div className={`cr-issue-step-item ${currentStep == 0 ? 'active' : '' }`}>1. Select Shipment</div>
            <div className="cr-issue-step-item">&nbsp;&nbsp;&gt;&nbsp;&nbsp;</div>
            <div className={`cr-issue-step-item ${currentStep == 1 ? 'active' : '' }`}>2. Select Reason</div>
        </div>

        <div className="cr-issue-section" style={{ minHeight: '100%' }}>
          {currentStep === 0 ?
            <div className="cr-issue-form" style={{ height: '100%' }}>
              <div className="cr-issue-form-item">
                <input type="text" value={searchShipmentNumber} onChange={e => setSearchShipmentNumber(e.target.value)} placeholder="Search Shipment Number / ID Trip" style={{ width: '100%', padding: '8px 10px' }} onKeyUp={ onSearchShipmentNumber } />
              </div>

              <div className="cr-issue-form-item" style={{ height: '100%' }}>
                { displayedTrips.length === 0 && !isLoadingShipment ?
                  <div className="center-vh">No trip found</div>
                : displayedTrips.length > 0 && !isLoadingShipment ? 
                  <TripIssueCards trips={displayedTrips} onClick={onSelectTrip} />
                : <div className="center-vh">
                    <IonSpinner name="crescent" />
                  </div> 
                }
              </div>
            </div>
          : 
            <div className="cr-issue-form">
              <div className="cr-issue-form-item">
                <TripIssueCard trip={selectedTrip} />
              </div>

              <div className="cr-issue-form-item">
                <label>Reason &nbsp;<img src={questionMark} alt='' style={{width: '5%', transform: 'translateY(25%)' }} onClick={ () => setShowReasonInfo(true) }/></label> 
                <div className="cr-issue-select" style={{color: _.get(reason, 'value') ? '#000000' : '#bdbdbd'}} onClick={() => setShowReason(true)}>
                  {_.get(reason, 'value', 'Select Reason')}
                </div>
              </div>

              <div className="cr-issue-form-item">
                <label>Location</label>
                  <IonSelect value={location} onIonChange={e => setLocation(e.detail.value)} interface="action-sheet" placeholder="Select location" className="cr-issue-select">
                    {
                      displayedLocation.map(loc => (
                        <IonSelectOption value={loc.id}>{loc.value}</IonSelectOption>
                      ))
                    }
                  </IonSelect>
              </div>

              <div className="cr-issue-form-item">
                <label>Title</label>
                <IonInput value={title} placeholder="Input Title" onIonChange={(e) => setTitle(e.target.value)}></IonInput>
              </div>

              <div className="cr-issue-form-item">
                <label>Notes / Description</label>
                <IonTextarea rows={5} value={notes} placeholder="Input your notes" onIonChange={(e) => setNotes(e.target.value)} type="textarea"></IonTextarea>
              </div>
            </div> 
          }
        </div>

        <IonModal isOpen={showReason} cssClass="operator" swipeToClose={true} onDidDismiss={() => filterReason('')} backdropDismiss={false}>
          <IonContent>
            <div className="operator-header">
              <span style={{fontSize: '20px', marginRight: '25px'}} onClick={() => setShowReason(false)}>&times;</span>
              <span>Reason</span>
            </div>
            <div className="operator-filter">
              <IonList>
                <IonSearchbar 
                  class="operator-filter-search" 
                  value={searchReason} 
                  onIonChange={e => filterReason(e.detail.value)}
                  placeholder="Search Reason"
                />
                {displayedReason.map(dr => (
                  <IonItem key={dr.id} onClick={() => toggleReason(dr)}>
                    <IonLabel>{dr.value}</IonLabel>
                    {reason.id === dr.id &&
                      <img src={tick} alt='' slot="end" />
                    }
                  </IonItem>
                ))}
              </IonList>
            </div>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showReasonInfo} cssClass="operator" swipeToClose={true} onDidDismiss={() => setShowReasonInfo(false)} backdropDismiss={false}>
          <IonContent>
            <div className="operator-header">
              <span style={{fontSize: '20px', marginRight: '25px'}} onClick={() => setShowReasonInfo(false)}>&times;</span>
              <span>Informasi Reason</span>
            </div>
            <div className="operator-filter" style={{ padding: '5%' }}>
                <div style={{color: '#595959'}}>Berikut detil informasi, item pelaporan :</div>
                {displayedReason.map((dr, index) => (
                  <div key={index} style={{ marginTop: '12px' }}>
                    <div style={{color: '#595959'}}>{ index + 1 }. {dr.value}</div>
                    <div style={{color: '#9b9b9b'}}>{ getReasonDescription(dr, 'id') }</div>
                  </div>
                ))}
    
            </div>
          </IonContent>
        </IonModal>

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={message}
          duration={2000}
          color="danger"
        />

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => { setMessage(); setShowAlert(false) }}
        header={'Error'}
        message={ message || 'Terjadi kesalahan. Silakan coba lagi.'}
        buttons={['OK']}
      />

      <IonAlert
        isOpen={showSuccessAlert}
        onDidDismiss={() => onSuccessAlert(false) }
        header={'Issue berhasil dibuat'}
        subHeader={'Anda akan kembali ke list issue'}
        buttons={[
          {
            text: 'OK',
            cssClass: 'secondary',
            handler: () => {
              onSuccessAlert(false);
              props.resetTrips();
            }
          }
        ]}
      />
      </IonContent>

      <div className="cr-issue-section cr-issue-footer">
        <IonFooter>
          <div className="issue-section">
            <div className="issue-content">
            <IonButton expand="block" onClick={checkForm} disabled={isLoading}>{ isLoading ? <IonSpinner name="crescent" /> : currentStep != maxStep - 1 ? 'Submit Issue' : 'Next'}</IonButton>
            </div>
          </div>
        </IonFooter>
      </div>
    </IonPage>
  );
};

const mapStateToProps = state => {
  const user = state.user.data;
  const allReason = state.reason.reasonsParams.map(x => {
    return {
      id: x.id,
      value: x.description,
      collab_ticket_extras: x.collab_ticket_extras
    }
  });

  return {
    allTransporter: state.transporter.lists,
    allContacts: state.contact.lists,
    allVehicleType: state.vehicle_type.lists,
    allReason,
    allTrips : state.w_trip.trips,
    isLoadingTrips: state.w_trip.isLoading,
    user
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
     getReasons, createIssue, getTrips, getIssues, resetTrips
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateIssueScreen);