/* eslint-disable */
import React from 'react';
import {IonCard, IonRouterLink, IonCardContent, IonRow, IonCol, IonHeader, IonBadge, IonFooter } from '@ionic/react';
import _ from 'lodash';
import moment from 'moment';

const IssueCards = ({issues=[]}) => {

  const COLORS = {
    closed: '#4a4a4a',
    vendor: '#009abf',
    client: '#f23d3d'
  }

  const getStatus = (issue) => {
    let obj = {};
    if (issue.status == 'open') {
      if (issue.last_action_role == 'vendor') {
           obj.code = 'vendor'
           obj.value = 'Waiting Shipper to Respond';
      } else if (issue.last_action_role == 'client') {
          obj.code = 'client';
          obj.value = 'Waiting Transporter to Respond';
      }
    } else if (issue.status == 'closed') {
        obj.code = 'closed';
        obj.value = 'Ticket Closed';
    }
    return obj;
  }

  const tdsSingkat = (str) => {
    let tdsArr = str.split('-');
    tdsArr[2] = tdsArr[2].substr(-6);
    return tdsArr.join('-');
  }

    return (
    <>
      {issues.map(issue => (
        <IonRouterLink key={issue.id} routerLink={`/operator/issue/${issue.id}`}>
          <IonCard>
            <IonHeader style={{padding: '15px', backgroundColor: '#f5f5f5', zIndex: '0'}}>
              <IonRow>
                <IonCol>
                  { issue.id && issue.id.length > 0 && <span>{tdsSingkat(issue.id)}</span> }
                  <span style={{float: 'right'}}>{ !_.isNil(issue.created_at) ? moment(issue.created_at).format('DD/MM/YYYY, HH:mm') : '-' }</span>
                </IonCol>
              </IonRow>
            </IonHeader>

            <IonCardContent style={{ backgroundColor: '#fff'}}>
              <IonRow>
                <IonCol style={{ color: '#595959', fontWeight: '600'}}>
                {_.get(issue, 'reason.description', '')}
                </IonCol>
              </IonRow>
              <IonRow style={{ marginTop: '6px' }}>
                <IonCol style={{ color: '#595959'}}>
                  { issue.logs && issue.logs.length > 0 && issue.logs[0].user && issue.logs[0].user.name }
                </IonCol>
              </IonRow>
              <IonRow style={{ marginTop: '6px' }}>
                <IonCol>
                  <IonBadge style={{ padding: '5px 8px', borderRadius: '10px', backgroundColor: COLORS[getStatus(issue).code] }}>{getStatus(issue).value}</IonBadge>
                </IonCol>
              </IonRow>
            </IonCardContent>

            <IonFooter style={{padding: '15px', backgroundColor: '#f5f5f5', zIndex: '0'}}>
              <IonRow>
                <IonCol>
                  { _.get(issue, 'orders[0].trip_id', '') }
                </IonCol>
              </IonRow>
            </IonFooter>
          </IonCard>
        </IonRouterLink>
      ))}
    </>
    );
}

export default IssueCards;
