import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { IonPage, IonRouterOutlet, IonAlert } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import HomeScreen from './HomeScreen';
import ScanScreen from './ScanScreen';
import ProfileScreen from './ProfileScreen';
import VehicleChecklistScreen from './VehicleChecklistScreen';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {logout} from '../../../actions/user';
import SecuritySideMenu from '../../../components/SecuritySideMenu';
import DetailVehicleChecklistScreen from './VehicleChecklistScreen/DetailVehicleChecklistScreen'

const SecurityContainer = (props) => {
  const {match, token, history, data, logout} = props;
  const [showAlert, setShowAlert] = useState(false);
  const [isActiveVehicleChecklist, setIsActiveVehicleChecklist] = useState(false)

  useEffect(() => {
    if (!token) {
      return history.replace('/login')
    }

    if (data && data.company && data.company.config && data.company.config.settings) {
      const parsedSetting = JSON.parse(data.company.config.settings)
      checkVehicleChecklist(parsedSetting)
    }

    if (data && data.role && !checkRole(data.role.name.toLowerCase(), ['security','gatekeeper', 'gatekeeper - kino'])) {
      setShowAlert(true);
    }
  }, [data, token]);

  const checkRole = (roleName, roleNames) => {
    const isExists = roleNames.indexOf(roleName);
    return isExists >= 0;
  }

  const urlGen = (path) => {
    return `${match.url}/${path}`;
  };

  const doLogout = () => {
    logout().then(res => {
    });
    history.replace('/login');
  }

  const checkVehicleChecklist = (setting) => {
    const isActive = setting.vehicle_checklist && setting.vehicle_checklist.is_active
    setIsActiveVehicleChecklist(isActive)
  }

  return (
    <>
      <SecuritySideMenu/>
      <IonPage id='mobile-security'>
        <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            header={'Informasi'}
            backdropDismiss={false}
            message={'Mohon maaf, saat ini halaman ini hanya dapat diakses oleh security.'}
            buttons={[{text: 'OK', handler: () => doLogout()}]}
        />
        {
          isActiveVehicleChecklist &&
          <IonReactRouter>
            <IonRouterOutlet>
              <Route path={urlGen('home')} component={HomeScreen} />
              <Route path={urlGen('scan')} component={ScanScreen} />
              <Route path={urlGen('profile')} component={ProfileScreen} />
              <Route path={urlGen('vehicle-checklist/:id')} component={DetailVehicleChecklistScreen} />
              <Route path={urlGen('vehicle-checklist')} component={VehicleChecklistScreen} />
  
              <Route path={match.url} exact render={() => <Redirect to={urlGen('home')}/>} />
            </IonRouterOutlet>
          </IonReactRouter>
        }
        {
          !isActiveVehicleChecklist &&
          <IonReactRouter>
            <IonRouterOutlet>
              <Route path={urlGen('home')} component={HomeScreen} />
              <Route path={urlGen('scan')} component={ScanScreen} />
              <Route path={urlGen('profile')} component={ProfileScreen} />
                
              <Route path={match.url} exact render={() => <Redirect to={urlGen('home')}/>} />
            </IonRouterOutlet>
          </IonReactRouter>
        }
      </IonPage></>
  );
}

const mapstp = ({user: {token, data}}) => {
  return {
    token,
    data,
  }
};

const mapdtp = dispatch => bindActionCreators({logout}, dispatch);

export default connect(mapstp, mapdtp)(SecurityContainer);
