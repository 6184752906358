import React, {useState, useEffect} from 'react';
import { IonPage, IonContent, IonHeader, IonToolbar, IonButton, IonButtons, IonTitle, IonBackButton, IonIcon,IonFooter, IonSlides, IonSlide, IonAlert, IonSpinner, IonLoading} from '@ionic/react';
import {arrowBack} from 'ionicons/icons';
import './TripAuthorization.scss';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {getTripById, closeOrder} from '../../../../actions/w_trip';
import SignaturePad from 'react-signature-pad-wrapper';
import QrReader from 'react-qr-reader';
import { validateQR } from './validator';
import srcToFile from "../../../../helpers/srcToFile";
import Swal from 'sweetalert2';
import S3Provider from '../../../../helpers/S3Provider';
import Axios from 'axios';
import { client } from '../../../../store';

const TripAuthorizationScreen = ({trip, isLoading, notification, packageUpdated, getTripById, history, user}) => {
  const [adminSignaturePad, setAdminSignaturePad] = useState();
  const [driverSignaturePad, setDriverSignaturePad] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [slider, setSlider] = useState();
  const [isQrError, setIsQrError] = useState('');
  const [showQrReader, setShowQrReader] = useState(false);
  const [screenTitle, setScreenTitle] = useState('Tanda Tangan Anda');
  const [showLoading, setShowLoading] = useState(false);
  const {id} = useParams();
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    allowTouchMove: false
  };

  useEffect(() => {
    if (packageUpdated === true) {
      Swal.fire({
        title: 'Sukses',
        text: "Barang berhasil dimuat  ke kendaraan dan telah diverifikasi Pengemudi",
        type: 'success',
        showCancelButton: false,
        confirmButtonText: 'TUTUP',
        confirmButtonClass: 'swal-update-package',
      }).then((result) => {
        history.push('/warehouse/trips');
      });
    }
  }, [packageUpdated])

  const initSlider = (el) => {
    // fix slider not rendered correctly
    el.target.swiper.update();
    setSlider(el.target.swiper);
  };

  const clearSignature = (signaturePad) => () => {
    if (signaturePad) {
      signaturePad.clear();
    }
  };

  const uploadSignature = async (signature) => {
    const signatureAdmin = signature.toDataURL();
    const timestamp = new Date();
    const file = await srcToFile(signatureAdmin, 'signature_admin_' + timestamp.getTime() + '.jpg', "image/jpeg")
    const fileUrl = await S3Provider.upload(file);

    return fileUrl;
  }

  const createAlert = message => {
    setAlertMessage(message);
    setShowAlert(true);
  };

  const slides = [
    {
      title: 'TANDA TANGAN ANDA'
    }, {
      title: 'SCAN QR CODE',
      showQrReader: true
    },
    {
      title: 'TANDA TANGAN PENGEMUDI'
    }
  ]

  const setSlideProperty = () => {
    if (slider) {
      let index = slider.activeIndex;
      let slideProperty = slides[index];
      setScreenTitle(slideProperty.title);
      setShowQrReader(slideProperty.showQrReader);  
    }
  }
  
  const goToNextSlide = () => {
    if (slider) {
      if (slider.activeIndex === 0) {
        if (adminSignaturePad.isEmpty()) {
          createAlert('Tanda tangan admin tidak boleh kosong');
        } else {
          slider.slideNext();
          setSlideProperty();
        }
      } else if (slider.activeIndex === 1) {
          slider.slideNext();
          setSlideProperty();
      } else {
         if (adminSignaturePad.isEmpty()) {
          createAlert('Tanda tangan driver tidak boleh kosong');
        } else {
          slider.slideNext();
          setSlideProperty();
        }
      }
    }
  }
  
  const authorizeTrip = async () => {
    if (adminSignaturePad.isEmpty()) {
      createAlert('Tanda tangan driver tidak boleh kosong');
    } else {
      setShowLoading(true);
      const adminSignature = await uploadSignature(adminSignaturePad);
      const driverSignature = await uploadSignature(driverSignaturePad);
      const orders = [];
      trip.orders.map(order => {
        orders.push(client.put('orders/' + order.id, {
          additional_documents: [
            {
              image_url: adminSignature,
              remarks: "admin warehouse " + user.name
            },
            {
              image_url: driverSignature,
              remarks: "driver warehouse " + (trip.driver && trip.driver.name) 
            }
          ]
        }))
      })
      const resPutOrders = await Axios.all(orders);
      setShowLoading(false);
      if (resPutOrders.some(r => r.status === 200)) {
        Swal.fire({
          title: 'Sukses',
          text: 'Barang berhasil dimuat ke kendaraan dan telah diverifikasi oleh Pengemudi',
          icon: 'success',
          confirmButtonText: 'TUTUP',
          confirmButtonColor: '#cecece'
        }).then(() => {
          history.push('/warehouse/trips');
        })
      } else {
        Swal.fire({
          title: 'Gagal',
          text: 'Terjadi kesalahan',
          icon: 'error',
          confirmButtonText: 'TUTUP',
          confirmButtonColor: '#cecece'
        }).then(() => {
          history.push('/warehouse/trips');
        })
      }
    }
  }

  const goToPrevSlide = () => {
    if (slider) {
      if (slider.activeIndex !== 0) {
        slider.slidePrev();
        setSlideProperty();
      }
    }
  }

  useEffect(() => {
    if (!trip) {
      history.push(`/warehouse/trip/${id}`);
    }
  }, [trip]);

  useEffect(() => {
  }, [adminSignaturePad]);


  useEffect(() => {
  }, [driverSignaturePad]);

  const handleScan = data => {
    if (data) {
      try {
        data = JSON.parse(data);
      } catch (e) {
      }

      if (validateQR(data, process.env.REACT_APP_KEY)) {
        if (data && data.data && data.data.value && data.data.value === id) {
          goToNextSlide();
        } else {
          createAlert('Trip tidak valid, trip terbaca: ' + data.data.value);
        }
      } else {
        createAlert('QR tidak valid');
      }
    }
  }

  const handleError = err => {
    setIsQrError(err);
    createAlert(`Pastikan Anda mengizinkan untuk menggunakan kamera perangkat Anda. ${err}`);
  };

  return (
  <IonPage>
    <IonAlert 
      isOpen={showAlert}
      onDidDismiss={() => setShowAlert(false)}
      header="Peringatan"
      message={alertMessage}
      buttons={['Oke']}
    />
    <IonHeader>
      <IonToolbar className="ritase-toolbar">
        <IonButtons slot="start">
          { (!slider || (slider && slider.activeIndex === 0)) && <IonBackButton defaultHref={`/warehouse/trip/${id}`} color="primary"></IonBackButton> }
          { slider && (slider.activeIndex === 1 || slider.activeIndex === 2) && <IonButton onClick={goToPrevSlide} color="primary" expand="block" shape="round"><IonIcon icon={arrowBack}/></IonButton> }
        </IonButtons>
        <IonTitle color="primary">{screenTitle}</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent style={{textAlign: 'left'}}>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Loading...'}
      />
      <IonSlides pager={true} options={slideOpts} autoplay={0} loop={false} style={{height: '100%'}} onIonSlidesDidLoad={initSlider}>
        <IonSlide>
          <div>
            <h4 className="instruction">Silakan berikan tanda tangan Anda di bawah ini</h4>
            <div className="signature-box">
            
            <SignaturePad redrawOnResize={true} ref={ref => setAdminSignaturePad(ref)}/>
            <div className="reset-box">
              <IonButton expand="full" className="reset-button" color="light" size="large" onClick={clearSignature(adminSignaturePad)}>ULANGI</IonButton>
            </div>
            </div>
          </div>
        </IonSlide>
        <IonSlide>
          <div>
              <h4 className="instruction">Silahkan minta pengemudi menge-scan Code dibawah melalui aplikasi Driver App Ritase.</h4>
            <div className="qr-reader-box">
              {!isQrError && showQrReader && <QrReader
                delay={1000}
                onError={handleError}
                onScan={handleScan}
                style={{ width: '100%', marginTop: 86 }}
              />}

              {!isQrError && <p>Arahkan QR code driver ke kotak diatas</p>}
              {isQrError &&  `Terjadi kesalahan ${isQrError}`}
            </div>
          </div>
        </IonSlide>
        <IonSlide>
          <div>
              <h4 className="instruction">Silahkan minta tanda tangan pengemudi di bawah ini untuk menyelesaikan muat barang.</h4>
            <div className="signature-box">
            <SignaturePad redrawOnResize={true} ref={ref => setDriverSignaturePad(ref)}/>
            <div className="reset-box">
              <IonButton expand="full" className="reset-button" color="light" size="large" onClick={clearSignature(driverSignaturePad)}>ULANGI</IonButton>
            </div>
            </div>
          </div>
        </IonSlide>
    </IonSlides>
      
      
    </IonContent>
    <IonFooter>
        <IonToolbar>
          { slider && slider.activeIndex === 0 && (<IonButton expand="block" onClick={goToNextSlide}>
            Selanjutnya
          </IonButton>)}
          { slider && slider.activeIndex === 2 && (<IonButton disabled={isLoading} expand="block" onClick={authorizeTrip}>
            {isLoading ? <IonSpinner color="light" /> : 'Selesai'}
          </IonButton>)}
        </IonToolbar>
      </IonFooter>
  </IonPage>
);
};

const mapStateToProps = ({w_trip, user}) => ({
  trip: w_trip.trip,
  isLoading: w_trip.isLoading,
  packageUpdated: w_trip.packageUpdated,
  user: user.data
});
const mapDispatchToProps = dispatch => bindActionCreators({ getTripById, closeOrder}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps) (TripAuthorizationScreen);