import React from 'react';
import {IonCard, IonCardContent, IonRow, IonCol, IonFooter, IonButton } from '@ionic/react';
import _ from 'lodash';
import './TripIssueCards.scss';

const TripIssueCard = ({trip={}, onClick, compareObj = null}) => {
    return (
    <>
      <IonCard className={`main-card ${ compareObj && compareObj(trip) ? 'active' : ''}`} onClick={ () => {}}>
        <IonCardContent style={{ backgroundColor: '#fff'}}>
          <IonRow>
            <IonCol style={{ color: '#9b9b9b'}}>
              Shipment Number 
            </IonCol>
            <IonCol style={{ color: '#4a4a4a'}}>
            { _.get(trip, 'shipment_number', '') }
            </IonCol>
          </IonRow>
          <IonRow style={{ marginTop: '6px' }}>
            <IonCol style={{ color: '#9b9b9b'}}>
              Driver
            </IonCol>
            <IonCol style={{ color: '#4a4a4a'}}>
              { _.get(trip, 'driver.name', '') }
            </IonCol>
          </IonRow>
          <IonRow style={{ marginTop: '6px' }}>
            <IonCol>
              <div className="item-content">
                <div className="origin">
                  <div className="trip-dot" style={{backgroundColor: '#fff'}}></div>
                  <div className="trip-place-name">{_.get(trip, 'orders[0].origin_contact_name', '')}</div>
                </div>

                <div className="destination">
                  <div className="trip-dot" style={{backgroundColor: '#fe6600'}}></div>
                  <div className="trip-place-name">{_.get(trip, 'orders[0].destination_contact_name', '')}</div>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonCardContent>

        { onClick ?
          <IonFooter style={{padding: '15px', backgroundColor: '#f5f5f5', zIndex: '0'}}>
            <IonRow >
              <IonCol className="ion-text-center ">
                <IonButton size="small" fill="clear" onClick={ () => onClick(trip) }>Pilih</IonButton>
              </IonCol>
            </IonRow>
          </IonFooter>
          : ''
        }
      </IonCard>
    </>
    );
}

export default TripIssueCard;