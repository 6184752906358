import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, IonGrid, IonRow, IonCol, IonBadge } from '@ionic/react';
import moment from 'moment';
import approval from '../../../../assets/new-icons/approval-orange.svg';
import './ApprovalScreen.scss';
import group from '../../../../assets/new-icons/group-orange.svg';

const ApprovalItem = ({ item, trips, onItemClick }) => {
  return (
    <>
      <Link key={item.id} to={`/operator/approval/detail/${item.id}`} onClick={ () => onItemClick }>
        <IonGrid>
          <IonCard>
            <IonRow>
              <IonCol size="2">
                <img src={approval} height="100%" alt=""/>     
              </IonCol>
              <IonCol size="10">
                <IonCardHeader>
                  <IonCardTitle>
                    <span>{_.get(item, 'template.name', '-')}</span>
                    <span style={{ float: 'right'}}><img src={group} alt='' /></span> 
                  </IonCardTitle>
                </IonCardHeader>
                
                <IonCardContent>
                  Terdapat transporter assignment dengan rute <span style={{ fontWeight: '600' }}>{_.get(trips[item.item_id], 'route_code.description', '')}</span> dan nama transporter <span style={{ fontWeight: '600' }}>{_.get(JSON.parse(item.schema), 'callback_properties.approve.payload.payload.assign_to.company_name', '-')}</span> yang menunggu approval anda
                </IonCardContent>
                
                <IonItem>
                  <small style={{ color: '#fe6600', fontStyle: 'italic'}}>{ moment(item.info_date).fromNow() }</small>
                  <IonBadge slot="end" className={ `badge ${ item.status }` }>{ item.status }</IonBadge>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonCard>
        </IonGrid>
      </Link>
    </>
  );
}

export default ApprovalItem;