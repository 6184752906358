import React from 'react';
import {IonCard, IonImg, IonRouterLink, IonCardTitle, IonCardContent, IonSkeletonText, IonGrid, IonRow, IonCol, IonRippleEffect} from '@ionic/react';
import truckIcon from '../../../../assets/icons/truck.svg'
import _ from 'lodash';

const TripCards = ({trips=[], showSkeleton=false}) => {

  const getShortId  = (id) => {
    if (!id) {
      return '';
    }
    return id.slice(0,8) + id.slice(22);
  }
  
    return (
    <>
      {trips.map(trip => (
        <IonRouterLink key={trip.id} routerLink={'/warehouse/trip/' + trip.id} >
          <IonCard className="trip-card ion-activatable">
          <IonRippleEffect></IonRippleEffect>
            { showSkeleton ? 
              (
                <>
                  <IonCardContent>
                    <IonSkeletonText animated style={{width: '50%'}}/>
                    <IonSkeletonText animated style={{width: '40%'}}/>
                    <IonSkeletonText animated style={{width: '40%'}}/>
                    <hr />
                    <IonSkeletonText animated style={{width: '80%'}}/>
                  </IonCardContent>
                </>
              )
              :
              (
                <>
                  <IonCardContent>
                    <IonCardTitle>
                      {showSkeleton
                        ? <IonSkeletonText animated />
                        : (<>
                          <IonGrid>
                            <IonRow>
                              <IonCol>
                                <IonImg className="truck-icon" alt="Truck icon" src={truckIcon}/>
                                <span className="card-title">{trip.vehicle.license_plate}</span>
                              </IonCol>
                              <IonCol className="trip-id">
                                <span className="card-title" style={{ color: '#9e9e9e' }}>{getShortId(trip.id)}</span>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                          </>)
                      }
                    </IonCardTitle>
                    Driver: <strong>{_.get(trip, 'driver.name', 'Belum diassign')}</strong> <br/>
                    Jenis Kendaraan: <strong>{_.get(trip, 'vehicle.type.name', '-')}</strong> <br/>
                    Transporter: <strong>{_.get(trip, 'company_transport.name', '-')}</strong> <br/>
                    <hr style={{ background: '#e0e0e0' }} />
                    List delivery notes<br/>
                    <strong>{trip.orders.map(order => order.shipment_number).join(', ')}</strong>
                  </IonCardContent>
                </>
              )
            }
        </IonCard>
      </IonRouterLink>
      ))}
    </>
    );
}

export default TripCards;