import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonInfiniteScroll, IonInfiniteScrollContent, IonSearchbar, IonToast, IonAlert } from '@ionic/react';
import _ from 'lodash';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { getDetail, resetDetail, exportReport } from '../../../../actions/report';
import './ReportDetailScreen.scss';
import back from '../../../../assets/new-icons/back.svg';
import search from '../../../../assets/new-icons/search.svg';
import share from '../../../../assets/new-icons/share.svg';
import download from '../../../../assets/new-icons/download.svg';

const ReportDetailScreen = (props) => {
  const { data, count, offset } = props;
  const [id, setID] = useState(props.match.params.id);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
  const [params, setParams] = useState('');
  const [showSearchbar, setShowSearchbar] = useState(false);
  const [searchOrder, setSearchOrder] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  if (id !== props.match.params.id) {
    setID(props.match.params.id);
  };

  useEffect(() => {
    props.getDetail(id).then(result => {
      if (_.get(result, 'payload.data.errorMessage')) {
        setError(result.payload.data.errorMessage);
      } else if (_.get(result, 'payload.data.rows.length', 0) <= 0) {
        setError('Report not found');
      }
    }).catch(err => {
      setError(err);
      console.error(err);
    });
  }, [id]);

  const goBack = () => {
    props.history.push('/operator/report');
  };

  const loadMore = (e) => {
    props.getDetail(id, offset + 10, params);
    
    e.target.complete();
    
    if (count > data.length) {
      setDisableInfiniteScroll(false);
    } else {
      setDisableInfiniteScroll(true);
    };
  };

  const filterBySearch = (val) => {
    setSearchOrder(val);
    setParams(`&search=${val}`);
    props.getDetail(id, 0, `&search=${val}`);
    setDisableInfiniteScroll(false);
  };

  const hideSearchBar = () => {
    setSearchOrder('');
    setShowSearchbar(false);
    setParams('');
    props.getDetail(id, 0, '');
    setDisableInfiniteScroll(false);
  };

  const shareReport = () => {
    var textarea = document.createElement("textarea");
    document.body.appendChild(textarea);
    textarea.value = window.location.href;
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setShowToast(true)
  };

  const onExportReport = () => {
    props.exportReport(id, params).then(function(result) {
      if (result.payload.status === 200) {
        if (result.payload.data.errorMessage) {
          setMessage(result.payload.data.errorMessage);
          setShowMessage(true);
        } else {
          window.location.href = result.payload.data.url;
        }
      } else {
        setMessage(`Error: ${result.payload.status}`);
        setShowMessage(true);
      }
    })
  };

  return (
    <IonPage className="operator operator-report">
      <IonContent className="operator-content">
        <div className="operator-header" style={{padding: showSearchbar ? '19px 20px' : '30px 20px'}}>
          {!showSearchbar ? 
            <span>
              <span style={{filter: 'invert(1) brightness(200%)', marginRight: '25px'}} onClick={() => goBack()}><img src={back} alt="back" /></span>
              <span>Report Detail</span>
              <div className="report-header">
                <img src={search} alt="" onClick={() => setShowSearchbar(true)} />
                <img src={share} alt="" onClick={shareReport} />
                <img src={download} alt="" onClick={onExportReport} />
              </div>
            </span>
          :
            <span style={{display: 'inline-flex', width: '100%'}}>
              <IonSearchbar 
                class="operator-searchbar" 
                value={searchOrder} 
                onIonChange={e => filterBySearch(e.detail.value)}
                placeholder="Cari Order ID, Trip ID"
              />
              <div style={{lineHeight: '2.4'}} onClick={hideSearchBar}>Cancel</div>
            </span>
          }
        </div>

        <div className="report-detail-header">
          <IonGrid>
            <IonRow>
              <IonCol>
                <div className="report-title">Jumlah shipment</div>
                <div className="report-data">{count} shipment</div>
              </IonCol>
              <IonCol>
                <div className="report-title">Tanggal</div>
                <div className="report-data">{_.get(data, '[0].header.updated_at') ? moment(data[0].header.updated_at).format('DD MMMM YYYY, HH:mm') : '-'}</div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        <div className="report-detail-list">
          {_.get(data, 'length', 0) > 0 && data.map((item, i) => (
            <IonGrid key={item.id} className="report-detail-item">
              <IonRow>
                <IonCol size={4} className="report-item-title">No</IonCol>
                <IonCol size={8} className="report-item-data">{i + 1}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol size={4} className="report-item-title">Order ID</IonCol>
                <IonCol size={8} className="report-item-data">{item.order_id}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol size={4} className="report-item-title">Trip ID</IonCol>
                <IonCol size={8} className="report-item-data">{item.trip_id}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol size={4} className="report-item-title">Shipment Number</IonCol>
                <IonCol size={8} className="report-item-data">{_.get(item, 'order.shipment_number', '-')}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol size={4} className="report-item-title">Origin</IonCol>
                <IonCol size={8} className="report-item-data">{_.get(item, 'order.origin_contact_name', '-')}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol size={4} className="report-item-title">Destination</IonCol>
                <IonCol size={8} className="report-item-data">{_.get(item, 'order.destination_contact_name', '-')}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol size={4} className="report-item-title">Last Update</IonCol>
                <IonCol size={8} className="report-item-data">{moment(item.updated_at).format('DD MMM YYYY, HH:mm')}</IonCol>
              </IonRow>
            </IonGrid>
          ))}
        </div>

        <IonInfiniteScroll 
          threshold="10px"
          disabled={disableInfiniteScroll}
          onIonInfinite={(e) => loadMore(e)}
        >
          <IonInfiniteScrollContent loadingSpinner="circular" />
        </IonInfiniteScroll>

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message="Link copied."
          duration={1000}
        />

        <IonToast
          isOpen={showMessage}
          onDidDismiss={() => setShowMessage(false)}
          message={message}
          position="top"
          color="danger"
          duration={3000}
        />

        <IonAlert
          isOpen={error.length > 0}
          onDidDismiss={() => goBack()}
          header={'Error!'}
          message={error}
          buttons={['OK']}
        />
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = state => {
  const { detail, countDetail, offsetDetail } = state.report;
  return {
    data: detail,
    count: countDetail,
    offset: offsetDetail
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getDetail, resetDetail, exportReport
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetailScreen);