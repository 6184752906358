import React from 'react';
import {Redirect} from 'react-router-dom';
import ls from 'local-storage';
import LoginContainer from './containers/LoginContainer';
import SecurityContainer from './containers/SecurityContainer';
import WarehouseContainer from './containers/WarehouseContainer'
import OperatorContainer from './containers/OperatorContainer';
import DistributorContainer from "./containers/DistributorContainer";

const token = ls.get('USER_TOKEN');
const path = ls.get('USER_ROUTE');

export default [
  {
    path: '/login',
    render: props => (token && path ? <Redirect to={path} /> : <LoginContainer {...props} />),
  },
  {
    path: '/security',
    component: SecurityContainer,
  },
  {
    path: '/warehouse',
    component: WarehouseContainer,
  },
  {
    path: '/distributor',
    component: DistributorContainer,
  },
  {
    path: '/operator',
    component: OperatorContainer,
  }
]
