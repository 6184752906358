import React, {useState, useEffect} from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonBackButton,
  IonContent,
  IonButton,
} from '@ionic/react';
import './InboundDetailScreen.scss';
import './CreateReport.scss';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {closeOrder} from '../../../../actions/w_trip';
import {show} from "../../../../actions/order";
import DeliveryReportScreen from './DeliveryReportScreen';
import { Tabs, AppBar, Tab, createMuiTheme, ThemeProvider, Chip } from '@material-ui/core';
import OrderDetailScreen from './OrderDetailScreen';
import _ from 'lodash';
import home from '../../../../assets/icons/home-white.svg';
import back from '../../../../assets/new-icons/back.svg';
import { Link } from 'react-router-dom';

const InboundDetailScreen = ({getOrderById, data}) => {
  const query = {
    populate: 'trip.vehicle.type,trip.driver,trip.company_transport,packages,company_transport,consignee_reports,status,origin,destination,trip.tasks,relatedtrips.tasks,load',
  };
  const {id} = useParams();
  const [currentTab, setCurrentTab] = useState(0);

  const tabs = [
    { id: 0, label: 'Detail Order' },
    { id: 1, label: 'Laporan Pengiriman' },
  ]

  useEffect(() => {
    getOrderById(id, query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#3caf9c',
      },
      secondary: {
        light: '#0066ff',
        main: '#0044ff',
        contrastText: '#ffcc00',
      },
    },
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="ritase-toolbar" color="primary">
          <IonButtons slot="start">
            {/* <IonBackButton defaultHref="/distributor/inbound/ongoing"></IonBackButton> */}
            <IonButton
              href="/distributor/inbound/ongoing"
              fill="clear"
              shape="round"
            >
              <div>
                <img src={back} alt="" style={{ filter: 'brightness(100)' }}/>
              </div>
            </IonButton>
          </IonButtons>
          <IonTitle>Detail Order</IonTitle>
          <IonButtons slot="end">
            <IonButton
              href="/distributor/inbound/ongoing"
              fill="clear"
              shape="round"
              style={{ marginRight: '20px' }}
            >
              <div>
                <img src={home} alt=""/>
              </div>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ThemeProvider theme={theme}>
          <AppBar position="fixed" color="default" className="ritase-tabs">
            <Tabs
              value={currentTab}
              onChange={(e, index) => setCurrentTab(index)}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab 
                className="ritase-menu-button ritase-tab" 
                label="Detail Order"
                {...a11yProps(0)}
              />
              <Tab 
                className="ritase-menu-button ritase-tab" 
                label={<div>
                  Laporan Pengiriman
                  {_.get(data, 'consignee_reports.length', 0) > 0 &&
                    <Chip
                      size="small"
                      label={_.get(data, 'consignee_reports.length', 0)}
                      style={{ backgroundColor: '#F23D3D', color: '#fff', marginLeft: '5px' }}
                    />
                  }
                </div>}
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>
        </ThemeProvider>

        {currentTab === 0 ?
          <OrderDetailScreen id={id} query={query} index={0} getOrderById={() => getOrderById(id, query)} />
        :
          <DeliveryReportScreen id={id} query={query} index={1} />
        }
      </IonContent>
    </IonPage>
  );
}

const mapStateToProps = ({order: {inbound, orderDetail: {loading, data, error}}}) => {
  return ({
    inbound,
    loading,
    data,
    error,
  })
};

const mapDispatchToProps = dispatch => bindActionCreators({closeOrder, getOrderById: show}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InboundDetailScreen);
