/* eslint-disable */
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IonPage, IonContent, IonModal, IonButton, IonItem, IonInfiniteScroll, IonInfiniteScrollContent, IonLabel, IonSelect, IonSelectOption, IonInput, IonGrid, IonRow, IonCol, IonFab, IonFabButton, IonIcon } from '@ionic/react';
import _ from 'lodash';
import { get as getIssues } from '../../../../actions/issue';
import { getParams as getReasons } from '../../../../actions/reason';
import { bindActionCreators } from 'redux';
import './IssueScreen.scss';
import IssueCards from './IssueCards'
import refresh from '../../../../assets/new-icons/refresh-orange.svg';
import prefernce from '../../../../assets/new-icons/preferncewhite.svg';
import add from '../../../../assets/new-icons/tambah.svg';

const IssueScreen = props => {
  const { user } = props;
  var searchQry = props.location.search;
  const urlParams = new URLSearchParams(searchQry);

  const getParamsQuery = () => {
    var params = [];
    if (searchQry.length > 0) {
      var entries = urlParams.entries();
      for (const entry of entries) {
        params.push({
          type: entry[0],
          value: entry[1]
        });
      };
    };

    return params;
  };

  const statusIssues = [
    { id: 'transporter', value: 'Waiting Transporter to Respond' },
    { id: 'shipper', value: 'Waiting Shipper to Respond' },
    { id: 'closed', value: 'Ticket Closed' },
  ];

  const [showFilter, setShowFilter] = useState(false);
  const [shipmentNumber, setShipmentNumber] = useState();
  const [tripId, setTripId] = useState();
  const [statusIssue, setStatusIssue] = useState();
  const [reason, setReason] = useState();
  const [filter, setFilter] = useState({});
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
  const [paramsOrd, setParamsOrder] = useState(getParamsQuery());
  const [displayedReason, setDisplayedReason] = useState(props.allReason)

  useEffect(() => {
    let search = props.location.search.length > 0 ? props.location.search.replace('?', '&') : '';
    props.getIssues(0, search);
    props.getReasons(`type=collab_tickets&company_shipper_id=${props.user.company_id}`);
  }, []);

  useEffect(() => {
    setDisplayedReason(props.allReason);
    initFilter();
  }, [props.allReason])


  const initFilter = () => {
    let res = {};
    if (urlParams.has('reason_id')) {
      if (Array.isArray(displayedReason) && displayedReason.length > 0) {
        let selectedReason = displayedReason.find((r) => r.id == urlParams.get("reason_id"));
        if (selectedReason) {
          res.reason = selectedReason;
          setReason(res.reason);
        }
      }
    }

    setFilter(res);
  };

  const openFilter = () => {
    if (filter.shipmentNumber) {
      setShipmentNumber(filter.shipmentNumber);
    } else {
      setShipmentNumber();
    }

    if (filter.statusIssue) {
      setStatusIssue(filter.statusIssue);
    } else {
      setStatusIssue();
    }

    if (filter.tripId) {
      setTripId(filter.tripId);
    } else {
      setTripId();
    }

    setShowFilter(true);
  }


  const resetFilter = () => {
    setShipmentNumber();
    setStatusIssue();
    setTripId();
  };

  const hasFilter = () => {
    return _.get(filter, 'shipmentNumber') || _.get(filter, 'statusIssue') || _.get(filter, 'tripId') || _.get(filter, 'reason');
  };

  const applyingFilter = () => {
    let new_filter = {};
    let new_params = [];

    if (shipmentNumber) {
      new_filter.shipmentNumber = shipmentNumber;
      new_params.push({type: 'shipment_number', value: shipmentNumber});
    }

    if (tripId) {
      new_filter.tripId = tripId;
      new_params.push({type: 'trip_id', value: tripId});
    }

    if (statusIssue && Object.keys(statusIssue).length > 0) {
      new_filter.statusIssue = statusIssue;
      if (statusIssue.id == 'transporter') {
        new_params.push({type: 'status', value: 'open'});
        new_params.push({type: 'last_action_role', value: 'client'});
      } else if (statusIssue.id == 'shipper') {
        new_params.push({type: 'status', value: 'open'});
        new_params.push({type: 'last_action_role', value: 'vendor'});
      } else if (statusIssue.id == 'closed') {
        new_params.push({type: 'status', value: 'closed'});
      }
    }

    if (reason) {
      new_filter.reason = reason;
      new_params.push({type: 'reason_id', value: reason.id });
    }

    setParamsOrder(new_params);
    setFilter(new_filter);
    setShowFilter(false);
    props.getIssues(0, getParamsString(new_params));
    setDisableInfiniteScroll(false);
  }

  const removingFilter = (type, val) => {
    let params_type, new_params, new_filter;
    if (val) {
      new_filter = _.filter(filter[type], f => f.id !== val.id);
    }

    switch (type) {
      case 'shipmentNumber':
        new_filter = null;
        setShipmentNumber();
        params_type = 'shipment_number';
        break;
      case 'tripId':
        new_filter = null;
        setTripId();
        params_type = 'trip_id';
        break;
      case 'statusIssue':
        new_filter = null;
        setStatusIssue();
        params_type = ['last_action_role', 'status'];
        break;
      case 'reason':
        new_filter = null;
        setReason();
        params_type = 'reason_id';
        break;
      default:
        break;
    };

    setFilter({
      ...filter,
      [type]: new_filter
    });
    if (Array.isArray(params_type)) {
      new_params = _.filter(paramsOrd, par => {
        return !params_type.includes(par.type);
      })
    } else {
      new_params = _.filter(paramsOrd, par => par.type !== params_type);
    }

    if (new_filter) {
      new_params.push({type: params_type, value: _.map(new_filter, fil => fil.id).join(',')});
    }
    setParamsOrder(new_params);
    props.getIssues(0, getParamsString(new_params));
    setDisableInfiniteScroll(false);
  };

  const loadMore = (e) => {
    props.getIssues(props.offset + 10, getParamsString(paramsOrd));

    e.target.complete();

    if (props.count > props.issues.length) {
      setDisableInfiniteScroll(false);
    } else {
      setDisableInfiniteScroll(true);
    };
  };

  const getParamsString = (params) => {
    let params_string = _.map(params, par => par.value.length > 0 ? `&${par.type}=${par.value}` : '');
    if (params_string.length > 0) return params_string.join('');

    return '';
  };

  const refreshIssues = () => {
    props.getIssues(0, getParamsString(paramsOrd));
  };

  const compareWith = (firstObj, secObj) => {
    return firstObj && secObj ? firstObj.id === secObj.id : firstObj === secObj;
  };

  return (
    <IonPage className="operator operator-order">
      <IonContent className="operator-content">
        <div className="operator-header" style={{padding: '30px 20px'}}>
            <span>
              All Issue
              <div className="order-header">
                <img src={prefernce} alt='' onClick={() => openFilter() } />
                <img src={refresh} alt='' style={{backgroundColor: '#fff', padding: '4px', borderRadius: '50%'}} onClick={() => refreshIssues()} />
              </div>
            </span>
        </div>
        {filter && hasFilter() &&
          <div className="operator-chip-container" style={{padding: '0 20px'}}>
            {filter.shipmentNumber &&
              <div className="operator-chip" onClick={() => removingFilter('shipmentNumber', null)}>
                Shipment Number : { filter.shipmentNumber }
                <span style={{marginLeft: '15px'}}>&times;</span>
              </div>
            }

            {filter.tripId &&
              <div className="operator-chip" onClick={() => removingFilter('tripId', null)}>
                Trip ID : { filter.tripId }
                <span style={{marginLeft: '15px'}}>&times;</span>
              </div>
            }

            {filter.statusIssue && Object.keys(filter.statusIssue).length > 0 &&
              <div className="operator-chip" onClick={() => removingFilter('statusIssue', null)}>
                Status : { filter.statusIssue.value }
                <span style={{marginLeft: '15px'}}>&times;</span>
              </div>
            }

            {filter.reason && Object.keys(filter.reason).length > 0 &&
              <div className="operator-chip" onClick={() => removingFilter('reason', null)}>
                Reason : { filter.reason.value }
                <span style={{marginLeft: '15px'}}>&times;</span>
              </div>
            }
          </div>
        }


        <div className="order-content" style={{paddingBottom: disableInfiniteScroll ? '45px' : '0'}}>
          {props.issues.length === 0 ?
            <div>No data found</div>
          : <IssueCards issues={props.issues} />
          }
        </div>

        <IonInfiniteScroll
          threshold="10px"
          disabled={disableInfiniteScroll}
          onIonInfinite={(e) => loadMore(e)}
        >
          <IonInfiniteScrollContent loadingSpinner="circular" />
        </IonInfiniteScroll>

        <IonModal cssClass="operator" swipeToClose={true} onDidDismiss={() => setShowFilter(false) }>
          <IonContent>
            <div className="operator-header">
              <span style={{fontSize: '20px', marginRight: '25px'}} onClick={() => setShowFilter(false) }>&times;</span>
              <span>Filter</span>
              <span onClick={resetFilter} style={{float: 'right', fontSize: '14px', marginTop: '5px', cursor: 'pointer'}}>Reset</span>
            </div>
            <div className="operator-filter">
              <IonItem>
                <IonLabel position="stacked">Status</IonLabel>
                <IonSelect interface="action-sheet" value={statusIssue} compareWith={compareWith} placeholder="" onIonChange={e => setStatusIssue(e.target.value)}>
                  { statusIssues.map(issue => (
                    <IonSelectOption key={issue.id} value={issue}>
                      {issue.value}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Shipment Number</IonLabel>
                <IonInput value={shipmentNumber} placeholder="" onIonChange={e => setShipmentNumber(e.target.value)}></IonInput>
              </IonItem>
            </div>

            <div className="operator-footer">
              <IonButton expand="block" className="btn-filter" onClick={applyingFilter}>Terapkan</IonButton>
            </div>
          </IonContent>
        </IonModal>

        <IonModal
          isOpen={showFilter}
          cssClass='modal-filter-issue'
          swipeToClose={true}
          onDidDismiss={() => setShowFilter(false)}>
            <IonGrid style={{ width: '100%', padding: '3%' }}>
              <IonRow style={{ marginBottom: '10px'}}>
                <IonCol size="12" style={{ margin: '3% 0'}}>
                  <span style={{ color: '#000000', fontSize: '18px', fontWeight: '800' }}>Filter</span>
                </IonCol>
                <IonCol size="12">
                  <IonLabel position="stacked">Shipment Number</IonLabel>
                  <IonInput value={shipmentNumber} placeholder="Enter shipment number" onIonChange={e => setShipmentNumber(e.target.value)}></IonInput>
                </IonCol>
                <IonCol size="12">
                  <IonLabel position="stacked">Trip ID</IonLabel>
                  <IonInput value={tripId} placeholder="Enter trip ID" onIonChange={e => setTripId(e.target.value)}></IonInput>
                </IonCol>
                <IonCol size="12">
                  <IonLabel position="stacked">Status</IonLabel>
                  <IonSelect interface="action-sheet" value={statusIssue} compareWith={compareWith} placeholder="Choose status" onIonChange={e => setStatusIssue(e.target.value)}>
                    { statusIssues.map(issue => (
                      <IonSelectOption key={issue.id} value={issue}>
                        {issue.value}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonCol>
                <IonCol size="6">
                  <IonButton color="medium" expand="block" onClick={() => resetFilter() }>Reset</IonButton>
                </IonCol>
                <IonCol size="6">
                  <IonButton expand="block" onClick={()=> applyingFilter()}>Filter</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
        </IonModal>
      </IonContent>

      <Link to='/operator/issue/create'>
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton>
              <IonIcon icon={add}  />
            </IonFabButton>
          </IonFab>
        </Link>
    </IonPage>
  )
};

const mapStateToProps = state => {
  const { list, count, offset } = state.issue;
  const user = state.user.data;

  const allReason = state.reason.reasonsParams.map(x => {
    return {
      id: x.id,
      value: x.description,
      collab_ticket_extras: x.collab_ticket_extras
    }
  });

  return {
    issues: list,
    count,
    offset,
    allReason,
    user
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getIssues, getReasons
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueScreen);
