import React, { Component } from 'react';
import './PackageItem.scss';

const PackageItem = ({ children, type }) => {
  return (
    <div className="package-container ion-text-center">
      <div className="package-box">
        <div className="line"></div>
        <div className="package-item">{children}</div>
      </div>
      {type && <strong style={{ color: '#9b9b9b', whiteSpace: 'pre' }}>{type}</strong>}
    </div>
  )

}

export default PackageItem;