import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonButton, IonFooter, IonSpinner, IonModal, IonToast, IonItemDivider, IonBadge } from '@ionic/react';
import _ from 'lodash';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import './ApprovalDetailScreen.scss';
import back from '../../../../assets/new-icons/back.svg';
import questionMarkIcon from '../../../../assets/new-icons/question-mark-orange.svg';
import { getDetailApproval, setApprovalDecision, resetApprovalDecision, getListApproval } from '../../../../actions/approval';
import { getDetailDefine as getTrip } from '../../../../actions/trip';
import formatRupiah from "../../../../helpers/formatRupiah";
import { GET_DETAIL_TRIP } from '../../../../actions/trip';
import Swal from 'sweetalert2';
import ContactAddress from './ContactAddress';
import getApprovalTime from '../../../../helpers/getApprovalTime';
import getApprovalStatus from '../../../../helpers/getApprovalStatus';
import getApprovalLogs from '../../../../helpers/getApprovalLogs';
import { getUser } from '../../../../actions/user';
import ApprovalLogTimeline from './ApprovalLogTimeline';
import { getAdditionalInfo } from '../../../../actions/allocation_prices';
import { get as getCompanies } from '../../../../actions/transporter';

const ApprovalDetailScreen = (props) => {
  const { isLoading, data, trip, response, user, additionalInfo, transporters } = props;
  const [id, setID] = useState(props.match.params.id);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [mainOrder, setMainOrder] = useState({});
  const [originContacts, setOriginContacts] = useState([]);
  const [destinationContacts, setDestinationContacts] = useState([]);
  const [decision, setDecision] = useState(null);
  const [dataToast, setDataToast] = useState({});
  const [status, setStatus] = useState(null);
  const [showApprovalStep, setShowApprovalStep] = useState(false);
  const [approvalStepLogs, setApprovalStepLogs] = useState([]);

  if (id !== props.match.params.id) {
    setID(props.match.params.id);
  };

  useEffect(() => {
    if (id) {
      props.getDetailApproval(id);
      props.getCompanies()
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      if (data.item_type === 'trips') {
        props.getTrip(data.item_id, { populate: 'vehicle_type,route_code,orders.origin_contact.location,orders.destination_contact.location' }, GET_DETAIL_TRIP);
        props.getAdditionalInfo(data.item_id);
      }

      const status = getApprovalStatus(data, user.id);
      setStatus(status);

      let logs = getApprovalLogs(data);
      if (logs.length > 0) {
        Promise.all(logs.map(log => getAdditionalApprovalnformation(log)))
        .then(result => {
          logs = _.cloneDeep(result);
          return groupAndSortingApproval(logs);
        })
        .then(groupLogs => {
          setApprovalStepLogs(groupLogs);
        });
      }
    }
  }, [data])

  useEffect(() => {
    if (trip && data && trip.id === data.item_id) {
      const tempMainOrder = _.find(trip.orders, order => order.sequence === 0);
      const tempOriginContacts = _.uniqBy(trip.orders, (order) => order.origin_contact_id).map(order => order.origin_contact);
      const tempDestinationContacts = _.uniqBy(trip.orders, (order) => order.destination_contact_id).map(order => order.destination_contact);

      setMainOrder(tempMainOrder);
      setOriginContacts(tempOriginContacts);
      setDestinationContacts(tempDestinationContacts);
    }
  }, [trip])

  const goBack = () => {
    props.resetApprovalDecision();
    props.getListApproval();
    props.history.go(-1);
  };

  const handleConfirmationApproval = (isShow, decision = null) => {
    setDecision(decision);
    setShowConfirmation(isShow);
  }

  const handleApproval = () => {
    props.resetApprovalDecision();

    const payload = {
      event_type: data.event_type,
      decision,
      item_id: data.item_id,
      item_type: data.item_type
    }

    props.setApprovalDecision(payload);
  }

  useEffect(() => {
    if (response != null && !response.errorMessage) {
      const toast = {};

      if (response) {
        toast.message = decision == 'reject' ? 'Approval berhasil ditolak' : 'Approval berhasil disetujui';
        toast.color = 'primary';
      } else { 
        toast.message = decision == 'reject' ? 'Gagal menolak approval' : 'Gagal menyetujui approval';
        toast.color = 'danger';
      }
      setDataToast(toast);
      setShowConfirmation(false);

      if (response) {
        Swal.fire({
          title: 'Berhasil',
          text: toast.message,
          type: 'success',
          showCancelButton: false,
          confirmButtonText: 'OK',
          confirmButtonColor: '#fe6600',
        }).then((result) => {
          goBack();
        });
      } else {
        setShowToast(true);
      }
    } else if (response && response.errorMessage) {
      const toast = {
        color: 'danger',
        message: response.errorMessage
      };

      setDataToast(toast);
      setShowToast(true);
      setShowConfirmation(false);
    }
  }, [response]);


  const handleDismissToast = () => {
    setDataToast({});
    setShowToast(false)
  }

  const getAdditionalApprovalnformation = (log) => {
    log.formatted_date = log.date && moment(log.date).format('DD MMM YYYY, HH:mm:ss');

    if (log.type === 'approver') {
      return props.getUser(log.approver_id).then(result => {
        log.approver = _.get(result, 'payload.data', {});
        log.status = log.is_approved != null && log.is_approved ? 'Approved' : log.is_approved != null && !log.is_approved ? 'Rejected' : null;
        return log;
      })
    } else {
      return log;
    }
  }

  const groupAndSortingApproval = (logs) => {
    let lastStatus = true;
    const groupApprovalLogs = _.groupBy(logs, 'group');
    const approvals = _.map(Object.keys(groupApprovalLogs), (key) => {
      if (groupApprovalLogs[key] && groupApprovalLogs[key].length > 0) {
        const firstElementApprover = groupApprovalLogs[key][0];
        if (firstElementApprover.type === 'assign') return firstElementApprover;
        else if (firstElementApprover.type === 'approver') {
          const tempStatus = lastStatus;
          lastStatus = firstElementApprover.is_approved_approval === null || (firstElementApprover.is_approved_approval !== null && firstElementApprover.is_approved_approval);
          if (tempStatus) {
            return {
              type: firstElementApprover.type,
              group: firstElementApprover.group,
              conditional_type: firstElementApprover.conditional_type_approval,
              is_approved: firstElementApprover.is_approved_approval,
              is_completed: firstElementApprover.is_completed_approval,
              approvers: groupApprovalLogs[key]
            };
          } else {
            return {};
          }
        }
      }
    })

    const approvalGroupLogs = _.filter(approvals, approval => Object.keys(approval).length > 0);
    
    // sorting
    return approvalGroupLogs.map(approvalLog => {
      if (approvalLog && approvalLog.approvers && approvalLog.approvers.length > 1) {
        approvalLog.approvers = approvalLog.approvers.sort((firstEl, secondEl) => {
          if (firstEl.date === undefined) {
            return -1
          }

          if (secondEl.date === undefined) {
            return 1
          }

          return new Date(secondEl.date) - new Date(firstEl.date)
        })
      }
      return approvalLog
    })
  }

  return (
    <IonPage className="operator operator-report approval-detail-screen">
      <IonContent className="operator-content">
        <div className="operator-header">
          <span style={{filter: 'invert(1) brightness(200%)', marginRight: '25px', cursor: 'pointer'}} onClick={() => goBack()}><img src={back} alt="back" /></span>
          <span>Detil Approval</span>
        </div>

        { isLoading ?
            <div className="center-vh">
              <IonSpinner name="crescent" />
            </div>
          : (
            <div className="approval-detail-section">
              <div className="approval-detail-section-title">
                {_.get(data, 'template.name', '-')}
              </div>
              <div className="approval-detail-content">
                <IonGrid>
                  <IonRow className="approval-detail-row">
                    <IonCol size="3" className="approval-detail-row-title">
                      Transporter
                    </IonCol>
                    <IonCol size="9">
                      {_.get(data, 'schema.callback_properties.approve.payload.payload.assign_to.company_name', '-')}
                    </IonCol>
                  </IonRow>

                  <IonRow className="approval-detail-row">
                    <IonCol size="3" className="approval-detail-row-title">
                      Harga
                    </IonCol>
                    <IonCol size="9">
                      {_.get(data, 'schema.callback_properties.approve.payload.payload.assign_to.price') ? formatRupiah(data.schema.callback_properties.approve.payload.payload.assign_to.price) : '-'}
                    </IonCol>
                  </IonRow>

                  <IonRow className="approval-detail-row">
                    <IonCol size="3" className="approval-detail-row-title">
                      Rute
                    </IonCol>
                    <IonCol size="9">
                      {_.get(trip, 'route_code.description', '-')}
                    </IonCol>
                  </IonRow>

                  <IonRow className="approval-detail-row">
                    <IonCol size="3" className="approval-detail-row-title">
                      Asal
                    </IonCol>
                    <IonCol size="9">
                      {
                        originContacts && originContacts.length > 0 && originContacts.map(origin => (
                          <ContactAddress key={origin.id} contact={origin} />
                        ))
                      }
                    </IonCol>
                  </IonRow>

                  <IonRow className="approval-detail-row">
                    <IonCol size="3" className="approval-detail-row-title">
                      Tujuan
                    </IonCol>
                    <IonCol size="9">
                      {
                        destinationContacts && destinationContacts.length > 0 && destinationContacts.map(destination => (
                          <ContactAddress key={destination.id} contact={destination} />
                        ))
                      }
                    </IonCol>
                  </IonRow>

                  <IonRow className="approval-detail-row">
                    <IonCol size="3" className="approval-detail-row-title">
                      Tanggal Muat
                    </IonCol>
                    <IonCol size="9">
                      {_.get(mainOrder, 'ready_time') ? moment(mainOrder.ready_time).format('DD MMMM YYYY, HH:mm') : '-'}
                    </IonCol>
                  </IonRow>

                  <IonRow className="approval-detail-row">
                    <IonCol size="3" className="approval-detail-row-title">
                      Order Number
                    </IonCol>
                    <IonCol size="9">
                      {_.get(mainOrder, 'order_number')}
                    </IonCol>
                  </IonRow>

                  <IonRow className="approval-detail-row">
                    <IonCol size="3" className="approval-detail-row-title">
                      Tipe Kendaraan
                    </IonCol>
                    <IonCol size="9">
                      {_.get(trip, 'vehicle_type.name')} ({_.get(trip, 'vehicle_type.new_code')})
                    </IonCol>
                  </IonRow>

                  <IonRow className="approval-detail-row">
                    <IonCol size="3" className="approval-detail-row-title" style={{ 'transform' : 'translateY(15%)' }}>
                      Langkah Approval
                    </IonCol>
                    <IonCol size="9">
                      <IonButton size="small" fill="clear" style={{ 'margin': 0 }} onClick={ () => setShowApprovalStep(true) }>Lihat</IonButton>
                    </IonCol>
                  </IonRow>

                  <IonRow className="approval-detail-row">
                    <IonCol size="12">
                      <IonItemDivider />
                    </IonCol>
                  </IonRow>

                  <IonRow className="approval-detail-row">
                    <IonCol size="12">
                      <h4>Informasi Tambahan</h4>
                    </IonCol>
                  </IonRow>

                  <IonRow className="approval-detail-row">
                    <IonCol size="3" className="approval-detail-row-title">
                      Transporter Tier 1
                    </IonCol>
                    <IonCol size="9">
                      { _.get(transporters.find((transporter) => transporter.id === _.get(additionalInfo, 'company_transport_id')), 'name', '-') }
                    </IonCol>
                  </IonRow>

                  <IonRow className="approval-detail-row">
                    <IonCol size="3" className="approval-detail-row-title">
                      Harga
                    </IonCol>
                    <IonCol size="9">
                      { _.get(additionalInfo, 'price', null) ? formatRupiah(additionalInfo.price) : '-' }
                    </IonCol>
                  </IonRow>

                  <IonRow className="approval-detail-row">
                    <IonCol size="3" className="approval-detail-row-title">
                      Harga Rata-Rata
                    </IonCol>
                    <IonCol size="9">
                      { _.get(additionalInfo, 'average_price', null) ? formatRupiah(additionalInfo.average_price) : '-' }
                    </IonCol>
                  </IonRow>
                  {
                    _.get(trip, 'assigned_reason', null)
                    ? (
                      <IonRow className="approval-detail-row">
                        <IonCol size="3" className="approval-detail-row-title">
                          Alasan
                        </IonCol>
                        <IonCol size="9">
                          {_.get(trip, 'assigned_reason')}
                        </IonCol>
                      </IonRow>
                    ) : ''
                  }
                  
                  <IonRow className="approval-detail-row">
                    <IonCol size="12">
                      <IonItemDivider />
                    </IonCol>
                  </IonRow>

                  <IonRow className="approval-detail-row">
                    <IonCol size="6" sizeXs="12">
                      <small style={{ color: '#fe6600', fontStyle: 'italic'}}>{ moment(getApprovalTime(data, user.id)).fromNow() }</small>
                    </IonCol>
                    { 
                      status 
                      ? 
                      ( 
                        <IonCol size="6" sizeXs="12" push="4" pushXs="8" pushSm="10">
                          <IonBadge className={ `badge ${status}` }>{status}</IonBadge>
                        </IonCol> 
                      )
                      : <></> 
                    }
                  </IonRow>
                </IonGrid>
              </div>
            </div>
          )
        }
      </IonContent>

      { !status ? (
      <IonFooter>
          <div className="approval-section">
            <div className="approval-content">
            <IonGrid>
                <IonRow>
                  <IonCol size="6">
                    <IonButton color="danger" expand="block" onClick={() => handleConfirmationApproval(true, 'reject') }>Reject</IonButton>
                  </IonCol>
                  <IonCol size="6">
                    <IonButton color="primary" expand="block" onClick={() => handleConfirmationApproval(true, 'approve') }>Approve</IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>
      </IonFooter>
      ) : <></> }
      
      <IonModal
        isOpen={showConfirmation}
        cssClass='modal-approval'
        swipeToClose={false}
        backdropDismiss={false}
      >
          <IonGrid style={{ width: '100%' }}>
            <IonRow>
              <IonCol size="12">
                <div className="ion-text-center">
                  <img src={questionMarkIcon} style={{height: '20%', width: '20%', margin: '4% auto 3%'}}/>
                </div> 
              </IonCol>
              <IonCol size="12">
                <div className="ion-text-center" style={{ paddingBottom: '3%'}}>
                  <span style={{color: '#595959', fontSize: '16px', fontWeight: '600' }}>Apakah anda yakin akan <span style={{ fontWeight: '900' }}>{decision === 'reject' ? 'menolak' : 'menyetujui'}</span> approval ini ?</span>
                </div>
              </IonCol>      
              <IonCol size="6">
                <IonButton color="medium" expand="block" onClick={() => { if(!isLoading) {handleConfirmationApproval(false)} }}>Tidak</IonButton>
              </IonCol>
              <IonCol size="6">
                <IonButton expand="block" onClick={() => { if(!isLoading) { handleApproval()} }}>{ isLoading ? <IonSpinner name="dots"></IonSpinner> : <span>Ya</span> }</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
      </IonModal>

      <IonModal isOpen={showApprovalStep} cssClass="operator" swipeToClose={true} onDidDismiss={() => setShowApprovalStep(false)}>
          <IonContent>
            <div className="operator-header">
              <span style={{fontSize: '20px', marginRight: '25px'}} onClick={() => setShowApprovalStep(false)}>&times;</span>
              <span>Langkah Approval</span>
            </div>
            <div className="operator-filter" style={{ padding: '24px 20px' }}>
              <ApprovalLogTimeline items={approvalStepLogs} reverse={true} />
            </div>
          </IonContent>
        </IonModal>

      <IonToast
          isOpen={showToast}
          onDidDismiss={() => handleDismissToast() }
          message={dataToast.message}
          color={dataToast.color}
          position="top"
          duration={4000}
      />
    </IonPage>
  );
};

const mapStateToProps = state => {
  const isLoading = state.approval.detail.isLoading || state.approval.set.isLoading || state.user.user.isLoading;
  const user = state.user.data;
  const { data, trip, additionalInfo } = state.approval.detail;
  const { response } = state.approval.set;
  const transporters = state.transporter.lists;
  return {
    isLoading,
    data,
    trip,
    response,
    user,
    additionalInfo,
    transporters
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getDetailApproval, getTrip, setApprovalDecision, resetApprovalDecision, getListApproval, getUser, getAdditionalInfo, getCompanies
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalDetailScreen);