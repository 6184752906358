import _ from 'lodash';

const getApprovalLogs = (data) => {
  let schema = data && data.schema;
  if (schema && typeof schema != 'object') {
    schema = JSON.parse(schema);
  }
  
  if (schema) {
    let logs = [];
    let approval_records = data.approval_records;
    logs.push({
      type: 'assign',
      group: 'assign',
      company_transport_id: _.get(schema, 'callback_properties.approve.payload.payload.assign_to.company_transport_id', '-'),
      company_transport: {
        id: _.get(schema, 'callback_properties.approve.payload.payload.assign_to.company_transport_id', '-'),
        name: _.get(schema, 'callback_properties.approve.payload.payload.assign_to.company_name', '-')
      },
      date: data.created_at
    });

    schema.payload.map((element, index) => {
      element.base_approval.map(approver => {
        let tempDate = approval_records.find(approval_record => approval_record.user_id == approver.approver_id)
        logs.push({
          type: 'approver',
          group: `approver_${index+1}`,
          approver_id: approver.approver_id,
          is_approved: approver.is_approved,
          is_completed_approval: element.is_completed,
          is_approved_approval: element.is_approved,
          conditional_type_approval: element.conditional_type,
          date: tempDate && tempDate.action_at
        })
      })
    });

    return logs;
  }

  return [];
}

export default getApprovalLogs;