import React, {useState, useEffect} from 'react';
import { IonPage, IonContent, IonHeader, IonToolbar, IonButton, IonButtons, IonTitle, IonMenuButton, IonIcon, IonSearchbar, IonSpinner, IonImg, IonText} from '@ionic/react';
import { menuController } from '@ionic/core';
import { clock } from 'ionicons/icons'
import errorImage from '../../../../assets/error.png';
import TripCards from './TripCards'
import './TripList.scss';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {getTrips} from '../../../../actions/w_trip';

const TripListScreen = ({trips, isLoading, errorMessage, getTrips}) => {
  const [searchText, setSearchText] = useState('');
  const [offset, setOffset] = useState(0);
  // menuController.swipeGesture(true);

  useEffect(() => {
    getTrips(offset, 'inprogress', {search: searchText ? searchText : undefined}).then(data => {
      
    });
  }, [searchText]);

  return (
  <IonPage className="homescreen">
    <IonHeader>
      <IonToolbar className="ritase-toolbar" color="primary">
        <IonButtons slot="start">
          <IonMenuButton auto-hide="true"></IonMenuButton>
        </IonButtons>
        <IonTitle>Warehouse</IonTitle>
        <IonButtons slot="end">
          <IonButton shape="round" className="trip-history">
            <IonIcon icon={clock}/>
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent style={{textAlign: 'left'}}>
      <IonSearchbar mode="ios" style={{ '--background': '#fff' }} className="search-box" inputmode="search" value={searchText} debounce="500" onIonChange={e => setSearchText(e.detail.value)} placeholder="Cari no driver, cari DN"></IonSearchbar>
      {
        isLoading ? 
          (<>
            <IonSpinner name="crescent" style={{width: '100%'}}/>
            <TripCards trips={[...Array(5)].map((u, i) => ({id: i}))} showSkeleton={true}/>
          </>)
        :
          trips.length > 0 ?       
          <TripCards trips={trips}/>
          : (<>
              <IonImg src={errorImage} className="error-image"/>
              <IonText class="error-description">
                Trip tidak ditemukan
              </IonText>
            </>)
      }
    </IonContent>
  </IonPage>
);
}

const mapStateToProps = ({w_trip}) => ({
  trips: w_trip.trips,
  isLoading: w_trip.isLoading,
  errorMessage: w_trip.errorMessage
});
const mapDispatchToProps = dispatch => bindActionCreators({getTrips}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps) (TripListScreen);