import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { IonPage, IonTabs, IonRouterOutlet, IonTabBar, IonLabel, IonTabButton, IonAlert } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import HomeScreen from './HomeScreen';
import OrderScreen from './OrderScreen';
import ReportScreen from './ReportScreen';
import ProfileScreen from './ProfileScreen';
import ImpersonateScreen from './ImpersonateScreen';
import home from '../../../assets/new-icons/homegrey.svg';
import homeActive from '../../../assets/new-icons/home-orange.svg';
import order from '../../../assets/new-icons/ordergrey.svg';
import orderActive from '../../../assets/new-icons/order-orange.svg';
import report from '../../../assets/new-icons/reportgrey.svg';
import reportActive from '../../../assets/new-icons/report-orange.svg';
import profile from '../../../assets/new-icons/profilegrey.svg';
import profileActive from '../../../assets/new-icons/profile-orange.svg';
import tambah from '../../../assets/new-icons/tambah.svg';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {logout} from '../../../actions/user';
import {resetOrderDetail} from '../../../actions/order';
import {resetIssueDetail} from '../../../actions/issue';
import './OperatorScreen.scss'
import OrderDetailScreen from './OrderDetailScreen';
import OrderDetailTrack from './OrderDetailScreen/OrderDetailTrack';
import ReportDetailScreen from './ReportDetailScreen';
import IssueScreen from './IssueScreen';
import IssueDetailScreen from './IssueDetailScreen';
import CreateOrderScreen from './CreateOrderScreen';
import CreateIssueScreen from './CreateIssueScreen';
import ApprovalScreen from './ApprovalScreen';
import ApprovalDetailScreen from './ApprovalDetailScreen';
import ApprovalHistoryScreen from './ApprovalScreen/ApprovalHistoryScreen';
import {get} from 'lodash';

const OperatorContainer = (props) => {
  const {match, location, token, history, data, logout, hide_create_order} = props;
  const [active, setActive] = useState(location.pathname);
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    if (!token) {
      doLogout(location.pathname);
    }
  }, [token]);

  useEffect(() => {
    if (data && data.role && !checkRole(data.role.name.toLowerCase(), ['owner','operator'])) {
      setShowAlert(true);
    }
  }, [data]);

  const checkRole = (roleName, roleNames) => {
    const isExists = roleNames.indexOf(roleName);
    return isExists >= 0;
  }

  const onChangeTab = (url) => {
    setActive(url);
    props.resetOrderDetail();
    props.resetIssueDetail();
  };

  const urlGen = (path) => {
    return `${match.url}/${path}`;
  };

  const doLogout = (url) => {
    let params = url ? `?redirect=${url}` : '';
    logout().then(res => {
      window.location.href = `/login${params}`
    }).catch(error => window.location.href = `/login${params}`);
  }

  return (
    <IonPage>
      <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'Informasi'}
          backdropDismiss={false}
          message={'Mohon maaf, saat ini halaman ini hanya dapat diakses oleh owner dan operator.'}
          buttons={[{text: 'OK', handler: () => doLogout()}]}
        />
      <IonReactRouter>
        <ion-tabs>
          <IonRouterOutlet>
            <Route path={urlGen('home')} component={HomeScreen} />
            <Route path={urlGen('order/create')} component={CreateOrderScreen} />
            <Route path={urlGen('order/:id/track/:idtrip')} component={OrderDetailTrack} />
            <Route path={urlGen('order/:id')} component={OrderDetailScreen} />
            <Route path={urlGen('order')} component={OrderScreen} />
            <Route path={urlGen('report/:id')} component={ReportDetailScreen} />
            <Route path={urlGen('report')} component={ReportScreen} />
            <Route path={urlGen('issue/create')} component={CreateIssueScreen} />
            <Route path={urlGen('issue/:id')} component={IssueDetailScreen} />
            <Route path={urlGen('issue')} component={IssueScreen} />
            <Route path={urlGen('group-impersonate')} component={ImpersonateScreen} />
            <Route path={urlGen('profile')} component={ProfileScreen} />
            <Route path={urlGen('approval/history')} component={ApprovalHistoryScreen} />
            <Route path={urlGen('approval/detail/:id')} component={ApprovalDetailScreen} />
            <Route path={urlGen('approval')} component={ApprovalScreen} />
            <Route path={match.url} exact render={() => <Redirect to={urlGen('home')}/>} />
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton onClick={() => onChangeTab(urlGen('home'))} tab="home" href={urlGen('home')}>
              <img src={active === urlGen('home') ? homeActive : home} alt=""/>
              <IonLabel>Home</IonLabel>
            </IonTabButton>
            <IonTabButton onClick={() => onChangeTab(urlGen('order'))} tab="order" href={urlGen('order')}>
              <img src={active === urlGen('order') ? orderActive : order} alt=""/>
              <IonLabel>Order</IonLabel>
            </IonTabButton>
            <IonTabButton style={{ display: !hide_create_order ? 'block' : 'none' }} onClick={() => onChangeTab(urlGen('order/create'))} tab="order/create" href={urlGen('order/create')}>
              <div className="btn-create"><img src={tambah} alt="" /></div>
            </IonTabButton>
            {/* <IonTabButton tab="report">
              <img src={active === urlGen('report') ? reportActive : report} alt=""/>
              <IonLabel>Report</IonLabel>
            </IonTabButton> */}
            <IonTabButton onClick={() => onChangeTab(urlGen('report'))} tab="report" href={urlGen('report')}>
              <img src={active === urlGen('report') ? reportActive : report} alt=""/>
              <IonLabel>Report</IonLabel>
            </IonTabButton>
            <IonTabButton onClick={() => onChangeTab(urlGen('profile'))} tab="profile" href={urlGen('profile')}>
              <img src={active === urlGen('profile') ? profileActive : profile} alt=""/>
              <IonLabel>Profile</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </ion-tabs>
      </IonReactRouter>
    </IonPage>
  );
}

const parseJSON = (json_string) => {
  try {
    return JSON.parse(json_string);
  } catch (error) {
    return {}
  }
}

const mapstp = ({user: {token, data}}) => {
  const raw_custom_dashboard = data && data.company && parseJSON(data.company.config.custom_dashboard);
  const order_actions = get(raw_custom_dashboard, ['app.order', 'actions'], []);
  return {
    token,
    data,
    hide_create_order: order_actions.includes('hide_order_button'),
  };
};

const mapdtp = dispatch => bindActionCreators({logout, resetOrderDetail, resetIssueDetail}, dispatch);

export default connect(mapstp, mapdtp)(OperatorContainer);