import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonButton, IonInput, IonModal, IonSearchbar, IonList, IonItem, IonLabel, IonSelect, IonSelectOption, IonTextarea, IonCheckbox, IonDatetime, IonToast, IonSpinner, IonAlert } from '@ionic/react';
import { bindActionCreators } from 'redux';
import { getLists as getContacts } from '../../../../actions/contact';
import { get as getCompanies } from '../../../../actions/transporter';
import { get as getVehicleType } from '../../../../actions/vehicle_type';
import { create as createOrder, get as getOrders } from '../../../../actions/order';
import _ from 'lodash';
import moment from 'moment';
import back from '../../../../assets/new-icons/back.svg';
import location from '../../../../assets/new-icons/location.svg';
import land from '../../../../assets/new-icons/land.svg';
import land_inactive from '../../../../assets/new-icons/land_inactive.svg';
import time from '../../../../assets/new-icons/time.svg';
import time_inactive from '../../../../assets/new-icons/time_inactive.svg';
import leftsmall from '../../../../assets/new-icons/leftsmall.svg';
import rightsmall from '../../../../assets/new-icons/angle-right-small.svg';
import tick from '../../../../assets/new-icons/tick.svg';
import tambah from '../../../../assets/new-icons/plus.svg';
import calendar from '../../../../assets/new-icons/calendar.svg';
import './CreateOrderScreen.scss'
import Swal from 'sweetalert2';

const CreateOrderScreen = (props) => {
  const defaultPackage = {
    package_type: '',
    description: '',
    quantity: 1,
    dimension: '',
    weight: ''
  };
  const defaultVehicle = {
    quantity: 1,
    vehicleType: ''
  };
  const company_broker_id = process.env.REACT_APP_COMPANY_BROKER_ID;
  const [currentStep, setCurrentStep] = useState(0);
  const [vendorType, setVendorType] = useState('oncall')
  const [transporter, setTransporter] = useState('');
  const [searchTransporter, setSearchTransporter] = useState('');
  const [displayedTransporter, setDisplayedTransporter] = useState(props.allTransporter);
  const [showTransporter, setShowTransporter] = useState(false);
  const [origin, setOrigin] = useState('');
  const [searchContacts, setSearchContacts] = useState('');
  const [showOrigin, setShowOrigin] = useState(false);
  const [destination, setDestination] = useState('');
  const [showDestination, setShowDestination] = useState(false);
  const [poNumber, setPoNumber] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [shipmentNumber, setShipmentNumber] = useState('');
  const [loadType, setLoadType] = useState('full');
  const [fleetType, setFleetType] = useState('0');
  const [vehicleType, setVehicleType] = useState('');
  const [searchVehicleType, setSearchVehicleType] = useState('');
  const [displayedVehicleType, setDisplayedVehicleType] = useState(props.allVehicleType);
  const [showVehicleType, setShowVehicleType] = useState(false);
  const [packages, setPackages] = useState([defaultPackage]);
  const [instruction, setInstruction] = useState('');
  const [insurance, setInsurance] = useState(false);
  const [insuranceFee, setInsuranceFee] = useState('');
  const [readyTime, setReadyTime] = useState(moment().format());
  const [arrivalTime, setArrivalTime] = useState(moment().add(1, 'days').format());
  const [trackable, setTrackable] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');
  const [item, setItem] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [multiVehicle, setMultiVehicle] = useState([defaultVehicle]);
  const [description, setDescription] = useState('');
  const [currentVehicle, setCurrentVehicle] = useState({type: 'single'});
  const [shipmentType, setShipmentType] = useState('domestic');
  const [isTransporterRitase, setIsTransporterRitase] = useState(false);
  
  useEffect(() => {
    let container = document.getElementById('createOrderContainer');
    container.scrollToTop();
  }, [currentStep]);

  useEffect(() => {
    setDisplayedTransporter(props.allTransporter);
  }, [props.allTransporter])

  useEffect(() => {
    setDisplayedVehicleType(props.allVehicleType);
  }, [props.allVehicleType])

  useEffect(() => {
    props.getCompanies();
    props.getContacts();
    props.getVehicleType();
  }, []);

  useEffect(() => {
    filterContacts('');
  }, [isTransporterRitase]);

  useEffect(() => {
    const ionSelects = document.querySelectorAll('ion-select');
    ionSelects.forEach((sel) => {
      let icon = sel.shadowRoot.querySelectorAll('.select-icon-inner');
      // if (icon.length <= 0) {
      //   return;
      // }
      
      icon.forEach((elem) => {
        elem.setAttribute('style', 'display: none;');
      });
    });
  });

  const goBack = () => {
    props.history.goBack();
  }

  const toggleTransporter = (val) => {
    let selected_transporter = transporter.id === val.id ? '' : val;
    setIsTransporterRitase(val.id === company_broker_id);
    if (transporter.id !== val.id) setShowTransporter(false);
    setTransporter(selected_transporter);
  };

  const toggleContacts = (val) => {
    if (showOrigin) {
      let selected_origin = origin.id === val.id ? '' : val;
      if (origin.id !== val.id) setShowOrigin(false);
      setOrigin(selected_origin);
    } if (showDestination) {
      let selected_destination = destination.id === val.id ? '' : val;
      if (destination.id !== val.id) setShowDestination(false);
      setDestination(selected_destination);
    }
  };

  const toggleVehicleType = (val) => {
    if (currentVehicle.type === 'single') {
      let selected_vehicle = vehicleType.id === val.id ? '' : val;
      if (vehicleType.id !== val.id) setShowVehicleType(false);
      setVehicleType(selected_vehicle);
    } else {
      let current_vehicle_id = _.get(multiVehicle, `[${currentVehicle.i}].vehicleType.id`, '');
      let selected_vehicle = current_vehicle_id === val.id ? '' : val;
      if (current_vehicle_id !== val.id) {
        setShowVehicleType(false);
      }
      editMultiVehicle('vehicleType', selected_vehicle, currentVehicle.i)
    };
  };

  const filterTransporter = (val) => {
    setSearchTransporter(val);
    let displayed = props.allTransporter.filter(item => {
      return _.includes(item.name.toLowerCase(), val.toLowerCase());
    });
    setDisplayedTransporter(displayed);
  };

  const filterContacts = (val) => {
    setSearchContacts(val);
    let params = val && val.length > 0 ? `&search=${val}` : '';
    if (isTransporterRitase) params += '&has_area=1';
    props.getContacts(params);
  };

  const filterVehicleType = (val) => {
    setSearchVehicleType(val);
    let displayed = props.allVehicleType.filter(item => {
      let str = '';
      if (_.get(item, 'override.name')) {
        str = `${item.override.name} - ${item.override.max_dimension} CBM / ${item.override.max_load} Kg - (${item.override.code})`;
      } else {
        str = `${item.name} - ${item.max_dimension} CBM / ${item.max_load} Kg - (${item.new_code})`;
      }
      return _.includes(str.toLowerCase(), val.toLowerCase());
    });
    setDisplayedVehicleType(displayed);
  };

  const editPackage = (type, val, idx) => {
    let pack = packages;
    pack[idx][type] = val;
    setPackages(pack);
  };

  const addPackage = () => {
    setPackages([...packages, defaultPackage]);
  };

  const editMultiVehicle = (type, val, idx) => {
    let vehicle = multiVehicle;
    vehicle[idx][type] = val;
    setMultiVehicle(vehicle);
  };

  const addMultiVehicle = () => {
    setMultiVehicle([...multiVehicle, defaultVehicle]);
  };

  const openVechileType = (type, i) => {
    setCurrentVehicle({type, i});
    setShowVehicleType(true);
  };

  const checkForm = () => {
    let has_error = false;
    let msg = '';
    let temp = {};
    switch (currentStep) {
      case 0:
        if (!origin.id || !destination.id) {
          msg = `${!origin.id ? 'Asal' : 'Tujuan'} harus diisi`;
          has_error = true;
          break;
        } else {
          has_error = false;
          temp = {
            origin: origin.location,
            origin_contact_id: origin.id,
            origin_contact_name: origin.name,
            origin_contact_number: origin.phone_number,
            destination: destination.location,
            destination_contact_id: destination.id,
            destination_contact_name: destination.name,
            destination_contact_number: destination.phone_number
          };
          if (isTransporterRitase) {
            temp.origin_area_id = _.get(origin, 'contact_areas[0].area_id', null);
            temp.destination_area_id = _.get(destination, 'contact_areas[0].area_id', null);
          }
          if (_.get(transporter, 'id', false)) {
            temp.company_transport = transporter;
            temp.company_transport_id = transporter.id;
            if (transporter.id === company_broker_id) {
              temp.vendor_type = vendorType;
            }
          }
          setItem({...item, ...temp});
        }
        break;
      case 1:
        if (loadType !== 'partai' && !vehicleType.id) {
          msg = `Tipe kendaraan harus diisi`;
          has_error = true;
          break;
        } else if (insurance && insuranceFee < 1) {
          msg = `Biaya asuransi harus diisi`;
          has_error = true;
          break;
        } else if (fleetType === '1' && !shipmentType) {
          msg = 'Tipe shipment harus diisi';
          has_error = true;
          break;
        } else {
          let total_quantity = 0;
          let total_dimension = 0;
          let total_load = 0;

          if (loadType === 'partai') {
            _.each(multiVehicle, vehicle => {
              has_error = has_error || !vehicle.quantity || !vehicle.vehicleType.id;
            });
            if (has_error) {
              msg = 'Lengkapi jumlah dan tipe kendaraan';
              break;
            }
          } else {
            _.each(packages, pack => {
              has_error = has_error || !pack.package_type || !pack.description || !pack.quantity || !pack.dimension || !pack.weight;
              total_quantity += parseInt(pack.quantity, 10);
              total_dimension += parseInt(pack.dimension, 10);
              total_load += parseInt(pack.weight, 10);
            });

            if (has_error) {
              msg = 'Lengkapi isi muatan';
              break;
            } else {
              let vehicle_dimension = _.get(vehicleType, 'override.max_dimension', vehicleType.max_dimension);
              let vehicle_load = _.get(vehicleType, 'override.max_load', vehicleType.max_load);
              if (total_dimension > vehicle_dimension) {
                msg = `Total dimensi melebihi ${vehicle_dimension} CBM`
                has_error = true;
                break;
              }
              if (total_load > vehicle_load) {
                msg = `Total dimensi melebihi ${vehicle_load} Kg`
                has_error = true;
                break;
              }
            }
          }
          
          has_error = false;
          temp = {
            po_number: poNumber.length > 0 ? poNumber : null,
            order_number: orderNumber.length > 0 ? orderNumber : null,
            shipment_number: shipmentNumber.length > 0 ? shipmentNumber : null,
            is_ftl: loadType === 'less' ? 0 : 1,
            type_id: loadType !== 'partai' ? vehicleType.id : null,
            packages: loadType !== 'partai' ? packages : null,
            instruction: instruction.length > 0 ? instruction : null,
            has_insurance: insurance,
            insurance_fee: insurance ? insuranceFee : null,
            total_quantity,
            total_dimension,
            total_load,
            shipment_type: fleetType === '1' ? shipmentType : null
          };
          setItem({...item, ...temp});
        }
        break;
      case 2:
        if (!readyTime || !arrivalTime) {
          msg = `Estimasi waktu ${!readyTime ? 'penjemputan' : 'sampai'} harus diisi`;
          has_error = true;
          break;
        } else if (moment(readyTime).isSameOrBefore(moment())) {
          msg = `Estimasi waktu harus lebih besar dari waktu sekarang`;
          has_error = true;
          break;
        } else if (moment(readyTime).isSameOrAfter(arrivalTime)) {
          msg = `Estimasi waktu sampai harus lebih besar dari waktu penjemputan`;
          has_error = true;
          break;
        } else {
          has_error = false;
          temp = {
            ready_time: moment.utc(readyTime).format(),
            expected_arrival: moment.utc(arrivalTime).format(),
            is_trackable: trackable
          };
          setItem({...item, ...temp});
          submitOrder({...item, ...temp});
        }
        break;
      default:
        break;
    }

    setShowToast(has_error);
    setMessage(msg);
    if (!has_error) setCurrentStep(currentStep + 1);
  };

  const submitOrder = (data) => {
    setLoading(true);
    data.created_via = loadType !== 'partai' ? 'single' : 'group';
    _.each(data, (item, key) => {
      if (_.isNull(item)) delete data[key];
    });

    let promises = [];
    if (loadType === 'partai') {
      _.each(multiVehicle, vehicle => {
        for (let i = 0; i < vehicle.quantity; i++) {
          let temp = {
            ...data,
            packages: [{
              package_type: 'Other',
              description: description.length > 0 ? description : null,
              quantity: 1,
              dimension: 0,
              weight: 0
            }],
            type_id: vehicle.vehicleType.id
          };

          promises.push(props.createOrder(temp));
        };
      });
    } else {
      promises.push(props.createOrder(data));
    };

    Promise.all(promises).then(results => {
      let success = 0;
      let error = [];
      _.each(results, res => {
        if (_.get(res, 'payload.data.errorMessage') || _.get(res, 'payload.status') !== 200) {
          error.push(_.get(res, 'payload.data'));
        } else {
          success++;
        }
      });

      if (error.length > 0) {
        setLoading(false);
        setShowAlert(true);
        console.error(error);
      } else {
        props.getOrders().then(res => {
          setLoading(false);
          Swal.fire({
            title: 'Order behasil dibuat!',
            text: "Order anda sudah berhasil dibuat. Untuk melihat order yang telah anda buat, tap tombol Lihat Order di bawah ini.",
            type: 'success',
            showCancelButton: false,
            confirmButtonText: 'Lihat Order',
            confirmButtonColor: '#fe6600',
            customClass: {
              container: 'cr-order-sucess'
            }
          }).then((result) => {
            props.history.push('/operator/order');
          });
        });
      };
    });
  };

  return (
    <IonPage className="operator create-order">
      <IonContent className="operator-content" id="createOrderContainer" scrollEvents={true}>
        <div className="operator-header">
          <span style={{filter: 'invert(1) brightness(200%)', marginRight: '25px'}} onClick={() => goBack()}><img src={back} alt="back" /></span>
          <span>Create Order</span>
        </div>

        <div className="cr-order-section cr-order-step">
          <div className={`cr-order-step-item active`}>
            <div className="img img-location"><img src={location} alt="" /></div>
            <div>Tujuan</div>
          </div>
          <div className={`cr-order-step-item${currentStep >= 1 ? ' active' : ''}`}>
            <div className="img img-land"><img src={currentStep >= 1 ? land : land_inactive} alt="" /></div>
            <div>Muatan</div>
          </div>
          <div className={`cr-order-step-item${currentStep >= 2 ? ' active' : ''}`}>
            <div className="img img-time"><img src={currentStep >= 2 ? time : time_inactive} alt="" /></div>
            <div>Jadwal</div>
          </div>
        </div>

        <div className="cr-order-section">
          {currentStep === 0 ?
            <div className="cr-order-form">
              <div className="cr-order-form-item">
                <label>Transporter</label>
                <div className="cr-order-select" style={{color: _.get(transporter, 'name') ? '#000000' : '#bdbdbd'}} onClick={() => setShowTransporter(true)}>
                  {_.get(transporter, 'name', 'Pilih transporter')}
                </div>
              </div>

              {_.get(transporter, 'id', false) && transporter.id === company_broker_id &&
                <div className="cr-order-form-item">
                  <IonSelect value={vendorType} onIonChange={e => setVendorType(e.detail.value)} interface="action-sheet" placeholder="Tipe Vendor" className="cr-order-select">
                    <IonSelectOption value="oncall">On Call</IonSelectOption>
                    <IonSelectOption value="dedicated">Dedicated</IonSelectOption>
                  </IonSelect>
                </div>
              }

              <div className="cr-order-form-item">
                <label>Asal</label>
                <div className="cr-order-select" style={{color: _.get(origin, 'name') ? '#000000' : '#bdbdbd'}} onClick={() => setShowOrigin(true)}>
                  {_.get(origin, 'name', 'Pilih dari kontak')}
                </div>
              </div>

              <div className="cr-order-form-item">
                <label>Alamat</label>
                <IonInput value={_.get(origin, 'location.address', '')} disabled placeholder="Terisi otomatis"></IonInput>
              </div>

              <div className="cr-order-form-item">
                <label>Kota</label>
                <IonInput value={_.get(origin, 'location.city', '')} disabled placeholder="Terisi otomatis"></IonInput>
              </div>

              <div className="cr-order-form-item">
                <label>Telepon</label>
                <IonInput value={_.get(origin, 'phone_number', '')} disabled placeholder="Terisi otomatis"></IonInput>
              </div>

              <div className="cr-order-form-item">
                <label>Tujuan</label>
                <div className="cr-order-select" style={{color: _.get(destination, 'name') ? '#000000' : '#bdbdbd'}} onClick={() => setShowDestination(true)}>
                  {_.get(destination, 'name', 'Pilih dari kontak')}
                </div>
              </div>

              <div className="cr-order-form-item">
                <label>Alamat</label>
                <IonInput value={_.get(destination, 'location.address', '')} disabled placeholder="Terisi otomatis"></IonInput>
              </div>

              <div className="cr-order-form-item">
                <label>Kota</label>
                <IonInput value={_.get(destination, 'location.city', '')} disabled placeholder="Terisi otomatis"></IonInput>
              </div>

              <div className="cr-order-form-item">
                <label>Telepon</label>
                <IonInput value={_.get(destination, 'phone_number', '')} disabled placeholder="Terisi otomatis"></IonInput>
              </div>
            </div>
          : currentStep === 1 ?
            <div className="cr-order-form">
              <div className="cr-order-form-item">
                <label>PO Number</label>
                <IonInput value={poNumber} placeholder="PO Number" onIonChange={(e) => setPoNumber(e.detail.value)}></IonInput>
              </div>

              <div className="cr-order-form-item">
                <label>Order Number</label>
                <IonInput value={orderNumber} placeholder="Order Number" onIonChange={(e) => setOrderNumber(e.detail.value)}></IonInput>
              </div>

              <div className="cr-order-form-item">
                <label>Shipment Number</label>
                <IonInput value={shipmentNumber} placeholder="Shipment Number" onIonChange={(e) => setShipmentNumber(e.detail.value)}></IonInput>
              </div>

              <div className="cr-order-form-item">
                <label>Tipe Muatan</label>
                <IonSelect value={loadType} onIonChange={e => setLoadType(e.detail.value)} interface="action-sheet" placeholder="Tipe Muatan" className="cr-order-select">
                  <IonSelectOption value="less">Less</IonSelectOption>
                  <IonSelectOption value="full">Full</IonSelectOption>
                  <IonSelectOption value="partai">Partai</IonSelectOption>
                </IonSelect>
              </div>

              {loadType === 'partai' && 
                <div>
                  {multiVehicle.map((vehicle, i) => (
                    <div key={i}>
                      <IonGrid>
                        <IonRow>
                          <IonCol size={3}>
                            <div className="cr-order-form-item">
                              <label>Jumlah</label>
                              <IonInput value={vehicle.quantity} placeholder="Jumlah" onIonChange={(e) => editMultiVehicle('quantity', e.detail.value, i)} type="number"></IonInput>
                            </div>
                          </IonCol>
                          <IonCol size={9} style={{paddingLeft: '25px'}}>
                            <div className="cr-order-form-item">
                              <label>Tipe Kendaraan</label>
                              <div className="cr-order-select" style={{color: _.get(vehicle, 'vehicleType.id') ? '#000000' : '#bdbdbd', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => openVechileType('multi', i)}>
                              {_.get(vehicle, 'vehicleType.override.name', false) ?
                                `${vehicle.vehicleType.override.name} - ${vehicle.vehicleType.override.max_dimension} CBM / ${vehicle.vehicleType.override.max_load} Kg - (${vehicle.vehicleType.override.code})`
                              : _.get(vehicle, 'vehicleType.id', false) ?
                                `${vehicle.vehicleType.name} - ${vehicle.vehicleType.max_dimension} CBM / ${vehicle.vehicleType.max_load} Kg - (${vehicle.vehicleType.new_code})`
                              :
                                'Pilih tipe kendaraan'
                              }
                            </div>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </div>
                  ))}

                  <div className="cr-order-add-package" onClick={addMultiVehicle}>
                    <img src={tambah} alt="" />
                    Tambah kendaraan
                  </div>

                  <div className="cr-order-form-item">
                    <label>Deskripsi</label>
                    <IonInput value={description} placeholder="Informasi barang yang dimuat" onIonChange={(e) => setDescription(e.detail.value)}></IonInput>
                  </div>
                </div>
              }

              <div className="cr-order-form-item">
                <label>Tipe Armada</label>
                <IonSelect value={fleetType} onIonChange={e => setFleetType(e.detail.value)} interface="action-sheet" placeholder="Tipe Armada" className="cr-order-select">
                  <IonSelectOption value="0">Truck Load</IonSelectOption>
                  <IonSelectOption value="1">Container Load</IonSelectOption>
                </IonSelect>
              </div>

              {fleetType === '1' &&
                <div className="cr-order-form-item">
                  <label>Tipe Shipment</label>
                  <IonSelect value={shipmentType} onIonChange={e => setShipmentType(e.detail.value)} interface="action-sheet" placeholder="Tipe Shipment" className="cr-order-select">
                    <IonSelectOption value="domestic">Domestic</IonSelectOption>
                    <IonSelectOption value="export">Export</IonSelectOption>
                    <IonSelectOption value="import">Import</IonSelectOption>
                  </IonSelect>
                </div>
              }

              {loadType !== 'partai' &&
                <div>
                  <div className="cr-order-form-item">
                    <label>Tipe Kendaraan</label>
                    <div className="cr-order-select" style={{color: _.get(vehicleType, 'id') ? '#000000' : '#bdbdbd'}} onClick={() => openVechileType('single', null)}>
                      {_.get(vehicleType, 'override.name', false) ?
                        `${vehicleType.override.name} - ${vehicleType.override.max_dimension} CBM / ${vehicleType.override.max_load} Kg - (${vehicleType.override.code})`
                      : _.get(vehicleType, 'id', false) ?
                        `${vehicleType.name} - ${vehicleType.max_dimension} CBM / ${vehicleType.max_load} Kg - (${vehicleType.new_code})`
                      :
                        'Pilih tipe kendaraan'
                      }
                    </div>
                  </div>

                  <div style={{fontWeight: '500', marginBottom: '15px'}}>Isi Muatan</div>

                  {packages.map((pack, i) => (
                    <div key={i}>
                      <div className="cr-order-form-item">
                        <label>Tipe</label>
                        <IonSelect value={pack.package_type} onIonChange={e => editPackage('package_type', e.detail.value, i)} interface="action-sheet" placeholder="Tipe" className="cr-order-select">
                          <IonSelectOption value="pallet">Pallet</IonSelectOption>
                          <IonSelectOption value="crate">Crate</IonSelectOption>
                          <IonSelectOption value="box">Box</IonSelectOption>
                          <IonSelectOption value="tray">Tray</IonSelectOption>
                          <IonSelectOption value="other">Other</IonSelectOption>
                        </IonSelect>
                      </div>

                      <div className="cr-order-form-item">
                        <label>Deskripsi</label>
                        <IonInput value={pack.description} placeholder="Deskripsi Muatan" onIonChange={(e) => editPackage('description', e.detail.value, i)}></IonInput>
                      </div>

                      <IonGrid>
                        <IonRow>
                          <IonCol>
                            <div className="cr-order-form-item">
                              <label>Jumlah</label>
                              <IonInput value={pack.quantity} placeholder="Jumlah" onIonChange={(e) => editPackage('quantity', e.detail.value, i)} type="number" min={1}></IonInput>
                            </div>
                          </IonCol>

                          <IonCol style={{padding: '0 20px'}}>
                            <div className="cr-order-form-item">
                              <label>Dimensi (CBM)</label>
                              <IonInput value={pack.dimension} placeholder="Dimensi" onIonChange={(e) => editPackage('dimension', e.detail.value, i)} type="number" min={1}></IonInput>
                            </div>
                          </IonCol>

                          <IonCol>
                            <div className="cr-order-form-item">
                              <label>Berat (Kg)</label>
                              <IonInput value={pack.weight} placeholder="Berat" onIonChange={(e) => editPackage('weight', e.detail.value, i)} type="number" min={1}></IonInput>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </div>
                  ))}

                  <div className="cr-order-add-package" onClick={addPackage}>
                    <img src={tambah} alt="" />
                    Tambah isi muatan
                  </div>
                </div>
              }
                
              <div className="cr-order-form-item">
                <label>Instruksi</label>
                <IonTextarea value={instruction} placeholder="Masukkan instruksi" onIonChange={(e) => setInstruction(e.detail.value)} type="textarea"></IonTextarea>
              </div>

              <div className="cr-order-form-item">
                <label>Gunakan Asuransi</label>
                <IonCheckbox checked={insurance} onIonChange={e => setInsurance(e.detail.checked)} className="cr-order-checkbox" color="primary" />
              </div>

              <div className="cr-order-form-item">
                <label>Biaya</label>
                <IonInput value={insuranceFee} placeholder="Rp 100.000" onIonChange={(e) => setInsuranceFee(e.detail.value)} disabled={!insurance}></IonInput>
              </div>
            </div>
          : 
            <div className="cr-order-form">
              <div className="cr-order-form-item cr-order-form-date">
                <label>Estimasi waktu penjemputan</label>
                <div style={{display: 'flex', borderBottom: '1px solid #e0e0e0', paddingBottom: '10px'}}>
                  <img src={calendar} alt="" />
                  <IonDatetime 
                    displayFormat="DD MMM YYYY, HH:mm" 
                    placeholder="Estimasi waktu penjemputan" 
                    value={readyTime} 
                    onIonChange={e => setReadyTime(e.detail.value)}
                    className="filter-date"
                    min={moment().format()}
                  />
                </div>
              </div>

              <div className="cr-order-form-item cr-order-form-date">
                <label>Estimasi waktu sampai</label>
                <div style={{display: 'flex', borderBottom: '1px solid #e0e0e0', paddingBottom: '10px'}}>
                  <img src={calendar} alt="" />
                  <IonDatetime 
                    displayFormat="DD MMM YYYY, HH:mm" 
                    placeholder="Estimasi waktu sampai" 
                    value={arrivalTime} 
                    onIonChange={e => setArrivalTime(e.detail.value)}
                    className="filter-date"
                    min={moment().format()}
                  />
                </div>
              </div>

              <div className="cr-order-form-item">
                <label>Dapat dilacak secara publik</label>
                <IonCheckbox checked={trackable} onIonChange={e => setTrackable(e.detail.checked)} className="cr-order-checkbox" color="primary" />
                <div style={{color: '#bdbdbd', fontSize: '11px', marginTop: '5px'}}>Jika diaktifkan, order dapat dilacak melalui url</div>
              </div>
            </div>
          }
        </div>

        <div className="cr-order-section cr-order-footer">
          {currentStep < 2 ?
            <IonGrid>
              <IonRow>
                <IonCol style={{paddingRight: '10px'}}>
                  {currentStep > 0 &&
                    <IonButton color="light" expand="block" className="cr-order-btn" onClick={() => setCurrentStep(currentStep - 1)}>
                      <img src={leftsmall} alt="" style={{marginRight: '15px'}} />
                      Sebelumnya
                    </IonButton>
                  }
                </IonCol>
                <IonCol style={{paddingLeft: '10px'}}>
                  <IonButton color="primary" expand="block" className="cr-order-btn" onClick={checkForm}>
                    Selanjutnya
                    <img src={rightsmall} alt="" style={{marginLeft: '15px'}} />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          :
            <IonButton color="primary" expand="block" className="cr-order-btn" onClick={checkForm} disabled={isLoading}>
              {isLoading ? <IonSpinner name="crescent" /> : 'Buat Order'}
            </IonButton>
          }
        </div>
          
        <IonModal isOpen={showOrigin || showDestination} cssClass="operator" swipeToClose={true} onDidDismiss={() => filterContacts('')} backdropDismiss={false}>
          <IonContent>
            <div className="operator-header">
              {showOrigin &&
                <span style={{fontSize: '20px', marginRight: '25px'}} onClick={() => setShowOrigin(false)}>&times;</span>
              }
              {showDestination &&
                <span style={{fontSize: '20px', marginRight: '25px'}} onClick={() => setShowDestination(false)}>&times;</span>
              }
              <span>{showOrigin ? 'Asal' : 'Tujuan'}</span>
            </div>
            <div className="operator-filter">
              <IonList>
                <IonSearchbar 
                  class="operator-filter-search" 
                  value={searchContacts} 
                  onIonChange={e => filterContacts(e.detail.value)}
                  placeholder="Search Origin"
                />
                {props.allContacts.map(contact => (
                  <IonItem key={contact.id} onClick={() => toggleContacts(contact)}>
                    <IonLabel>{contact.name}</IonLabel>
                    {((showOrigin && origin.id === contact.id) || (showDestination && destination.id === contact.id)) &&
                      <img src={tick} alt='' slot="end" />
                    }
                  </IonItem>
                ))}
              </IonList>
            </div>
          </IonContent>
        </IonModal>
        
        <IonModal isOpen={showTransporter} cssClass="operator" swipeToClose={true} onDidDismiss={() => filterTransporter('')} backdropDismiss={false}>
          <IonContent>
            <div className="operator-header">
              <span style={{fontSize: '20px', marginRight: '25px'}} onClick={() => setShowTransporter(false)}>&times;</span>
              <span>Transporter</span>
            </div>
            <div className="operator-filter">
              <IonList>
                <IonSearchbar 
                  class="operator-filter-search" 
                  value={searchTransporter} 
                  onIonChange={e => filterTransporter(e.detail.value)}
                  placeholder="Search Transporter"
                />
                {displayedTransporter.map(trp => (
                  <IonItem key={trp.id} onClick={() => toggleTransporter(trp)}>
                    <IonLabel>{trp.name}</IonLabel>
                    {transporter.id === trp.id &&
                      <img src={tick} alt='' slot="end" />
                    }
                  </IonItem>
                ))}
              </IonList>
            </div>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showVehicleType} cssClass="operator" swipeToClose={true} onDidDismiss={() => filterVehicleType('')} backdropDismiss={false}>
          <IonContent>
            <div className="operator-header">
              <span style={{fontSize: '20px', marginRight: '25px'}} onClick={() => setShowVehicleType(false)}>&times;</span>
              <span>Vehicle Type</span>
            </div>
            <div className="operator-filter">
              <IonList>
                <IonSearchbar 
                  class="operator-filter-search" 
                  value={searchVehicleType} 
                  onIonChange={e => filterVehicleType(e.detail.value)}
                  placeholder="Search Vehicle Type"
                />
                {displayedVehicleType.map(vehicle => (
                  <IonItem key={vehicle.id} onClick={() => toggleVehicleType(vehicle)}>
                    <IonLabel>
                      {_.get(vehicle, 'override.name', false) ?
                        `${vehicle.override.name} - ${vehicle.override.max_dimension} CBM / ${vehicle.override.max_load} Kg - (${vehicle.override.code})`
                      :
                        `${vehicle.name} - ${vehicle.max_dimension} CBM / ${vehicle.max_load} Kg - (${vehicle.new_code})`
                      }
                    </IonLabel>
                    {((currentVehicle.type === 'single' && vehicleType.id === vehicle.id) || 
                      (currentVehicle.type === 'multi' && _.get(multiVehicle, `[${currentVehicle.i}].vehicleType.id`, '') === vehicle.id)) &&
                      <img src={tick} alt='' slot="end" />
                    }
                  </IonItem>
                ))}
              </IonList>
            </div>
          </IonContent>
        </IonModal>
      
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={message}
          duration={2000}
          color="danger"
        />

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Error'}
        message={'Terjadi kesalahan. Silakan coba lagi.'}
        buttons={['OK']}
      />
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = state => {
  return {
    allTransporter: state.transporter.lists,
    allContacts: state.contact.lists,
    allVehicleType: state.vehicle_type.lists
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getContacts, getCompanies, getVehicleType, createOrder, getOrders
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrderScreen);