import { IonBadge, IonCard, IonCardContent, IonCol, IonGrid, IonRow } from '@ionic/react';
import moment from 'moment';
import React from 'react';

export const VehicleChecklistStatus = ({ item, clickable, onClick }) => (
  <IonCard onClick={()=>clickable && onClick()}>
    <IonCardContent style={{padding: 0}}>
      <IonGrid>
        <IonRow>
          <IonCol size='8'>
            <IonGrid>
              <IonRow>
                {item.vehicle && item.vehicle.license_plate !== "0" ? item.vehicle.license_plate : '-'}{item.driver && ', '+ (item.driver.name && item.driver.name)}
              </IonRow>
              <IonRow>
                {item.transporter && item.transporter.name}
              </IonRow>
            </IonGrid>
          </IonCol>
          <IonCol size='4'>
            <IonGrid>
              <IonRow className='ion-justify-content-end'>
                <IonBadge color={item.status === 'reject' ? 'danger' : 'primary'}>
                  {item.status === 'accept' ? 'Diterima' : item.status === 'reported' ? 'Dilaporkan' : 'Ditolak'}
                </IonBadge>
              </IonRow>
              <IonRow className='ion-justify-content-end'>
                {item.created_at && moment(item.created_at).format('L')}
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCardContent>
  </IonCard>
)