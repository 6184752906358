import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux'
import { IonPage, IonContent, IonButton, IonCard, IonCardHeader, IonCardTitle, IonCardContent } from '@ionic/react';
import './ImpersonateScreen.scss';
import { getDataGroup, impersonate, getData } from "../../../../actions/user";
import {bindActionCreators} from 'redux';
import Swal from 'sweetalert2';

const ImpersonateScreen = ({
  user,
  getDataGroup,
  company_groups,
  impersonate,
  getData
}) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    getDataGroup();
  }, [getDataGroup]);

  const impersonateUser = async () => {
    const impersonateCompany = company_groups.find(
      (group) => group.id === selected
    );
    if (impersonateCompany && selected) {
      const response = await impersonate(impersonateCompany);
      if (response.payload && response.payload.status === 200) {
        await getData();
        Swal.fire(
          "Change group",
          `Change group to ${impersonateCompany.name} success!`,
          "success",
        )
      }
    }
  };

  return (
    <IonPage className="operator operator-profile">
      <IonContent className="operator-content">
        <div className="operator-header profile-header">
          <div>
            Change Group
            {/* <img src={edit} alt='' style={{float: 'right'}} /> */}
          </div>
          <div
            style={{
              display: "inline-flex",
              marginTop: "40px",
              alignItems: "center",
            }}
          >
            <div className="profile-logo">{user && user.name.charAt(0)}</div>
            <div style={{ fontSize: "16px" }}>
              <div style={{ fontWeight: "bold", marginBottom: "6px" }}>
                {user && user.name}
              </div>
              <div>{user && user.company.name}</div>
            </div>
          </div>
        </div>

        <div className="profile-content">
          {company_groups.map((group) => (
            <IonCard
              key={group.id}
              style={{
                "--background": "#fff",
                border: (selected === group.id || (!selected && (user.company.id === group.id))) ? "2px solid #fe6600" : "none",
              }}
              onClick={() => setSelected(group.id)}
            >
              <IonCardHeader>
                <IonCardTitle>{group.name}</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                Company Type: {group.company_type}
                <hr />
                {selected === group.id && (
                  <IonButton onClick={impersonateUser}>IMPERSONATE</IonButton>
                )}
              </IonCardContent>
            </IonCard>
          ))}
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = state => {
  console.log(state);
  return {
    user: state.user.data,
    company_groups: state.user.company_groups
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getDataGroup, impersonate, getData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImpersonateScreen);