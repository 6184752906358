import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux'
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonSearchbar,
  IonGrid,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonCard,
  IonCardContent,
  IonRouterLink,
  IonRippleEffect,
  IonIcon,
} from '@ionic/react';
import { add, arrowBack } from 'ionicons/icons'
import './AdminScreen.scss';
import {bindActionCreators} from 'redux';
import _ from 'lodash';
import { getUsers } from '../../../../actions/user';
import TripCard from '../components/TripCard';
import { Link } from 'react-router-dom';

const AdminScreen = ({getAdmin, data, loading, error}) => {
  const [query, setQuery] = useState({
    user_type: 'admin',
    populate: 'role',
    limit: 10,
  })
  const [searchType, setSearchType] = useState('name')
  const [searchText, setSearchText] = useState('')
  
  useEffect(() => {
    getAdmin(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeSearchType = value => {
    setSearchType(value)
    searchAdmin(value)
  }
  
  const searchAdmin = (type) => {
    let newQuery = { ...query }
    if (type === 'id') {
      if (searchText) {
        newQuery.id = searchText
      } else {
        delete newQuery.id
      }
      delete newQuery.search
      delete newQuery.searchFields
    } else {
      if (searchText) {
        delete newQuery.id
        newQuery.search = searchText
        newQuery.searchFields = type
      } else {
        delete newQuery.search
        delete newQuery.searchFields
      }
    }
    setQuery(newQuery)
    getAdmin(newQuery)
  }

  const nextData = () => {
    const limit = _.get(query, 'limit', 10);
    const currentPage = _.get(data, 'offset', 0) / limit + 1;
    const offset = currentPage * limit;
    getAdmin({
      ...query,
      offset,
    })
  }
  
  return (
    <IonPage className="homescreen">
      <IonHeader>
        <IonToolbar className="ritase-toolbar" color="primary">
          <IonButtons slot="start">
            <Link to="/distributor/inbound/ongoing">
              <IonButton color="light">
                <IonIcon slot="icon-only" icon={arrowBack} />
              </IonButton>
            </Link>
          </IonButtons>
          <IonTitle>Daftar Admin</IonTitle>
          <IonButtons slot="end">
            <Link to="/distributor/admin/create">
              <IonButton color="secondary" fill="solid">
                <IonIcon slot="start" icon={add} />
                Tambah Admin
              </IonButton>
            </Link>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size-lg="2" size-md="3" size-sm="4">
              <IonSelect
                value={searchType}
                onIonChange={e => onChangeSearchType(e.detail.value)}
                interface="action-sheet"
                style={{
                  marginTop: '13px',
                  border: '1px solid #e0e0e0',
                  borderRadius: '10px',
                  height: '36px',
                  paddingTop: '8px',
                  paddingBottom: '0',
                }}
              >
                <IonSelectOption value="id">ID</IonSelectOption>
                <IonSelectOption value="name">Nama</IonSelectOption>
                <IonSelectOption value="phone_number">Nomor Kontak</IonSelectOption>
                <IonSelectOption value="email">Email</IonSelectOption>
              </IonSelect>
            </IonCol>
            <IonCol size-lg="10" size-md="9" size-sm="8">
              <form
                noValidate
                onSubmit={(e) => {
                  e.preventDefault()
                  searchAdmin(searchType)
                  return false;
                }}
              >
                <IonSearchbar
                  mode="ios"
                  inputmode="search"
                  defaultValue={searchText}
                  onIonChange={e => setSearchText(e.detail.value)}
                  type='search'
                  placeholder="Cari Admin..."
                  style={{
                    padding: '0'
                  }}
                ></IonSearchbar>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>

        {!loading && !error && _.get(data, 'rows.length', 0) === 0 && 
          <div style={{textAlign: 'center', marginTop: '20px'}}>
            Data tidak ditemukan
          </div>
        }

        {_.get(data, 'rows', []).map(user => (
          <IonRouterLink key={user.id} routerLink={`/distributor/admin/${user.id}`}>
            <IonCard className="admin-card ion-activatable">
              <IonRippleEffect />
              <IonCardContent>
                <div className="admin-name">
                  <span>{ user.name }</span>
                  {_.get(user, 'role.name') &&
                    <span> - { user.role.name }</span>
                  }
                </div>

                <div>
                  <div className="admin-phone">
                    { user.phone_number }
                  </div>
                  <div className="admin-email">
                    { user.email }
                  </div>
                </div>
              </IonCardContent>
            </IonCard>
          </IonRouterLink>
        ))}

        {loading && 
          <div>
            <TripCard loading/>
            <TripCard loading/>
            <TripCard loading/>
          </div>
        }

        {!loading && _.get(data, 'rows.length', 0) < _.get(data, 'count') && 
          <div>
            <IonButton 
              onClick={nextData} 
              expand="block"
              fill="clear"
            >
              Muat lebih banyak
            </IonButton>
          </div>
        }
      </IonContent>
    </IonPage>
  );
}

const mapStateToProps = ({ user: { users: { data, loading, error } } }) => {
  return ({
    data,
    loading,
    error,
  })
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getAdmin: getUsers
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdminScreen);
