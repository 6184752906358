/* eslint-disable react-hooks/exhaustive-deps, no-unused-vars, eqeqeq */
import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import { IonPage, IonContent, IonHeader, IonTitle, IonToolbar, isPlatform, IonAlert, IonCard, IonCardContent, IonButtons, IonMenuButton, IonText } from '@ionic/react';
import './ScanScreen.scss';
import QrReader from 'react-qr-reader';
import { detect } from 'detect-browser';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {findTrip, findTripByShipmentNumber, updateTrip, UPDATE_TRIP, FIND_TRIP, FIND_TRIP_BY_SHIPMENT_NUMBER} from '../../../../actions/trip';
import Preloader from '../../../../components/Preloader';
import moment from 'moment';
import {validateQR} from './validator';
import Swal from 'sweetalert2';

const browser = detect();
const ScanScreen = ({findTrip, findTripByShipmentNumber, updateTrip, trip, history, user}) => {
  const [tripId, setTripId] = useState(null);
  const [isQrError, setIsQrError] = useState('');
  const [isSupported, setIsSupported] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({
    header: '',
    message: '',
    buttons: ['Ok'],
  });
  const [showAlternative, setShowAlternative] = useState(false);

  useEffect(() => {
    if (browser && browser.name) {
      if (isPlatform('ios') && !browser.name !== 'safari') {
        setIsSupported(false);
      }
    }
  }, []);

  useEffect(() => {
    if (trip.trip) {
      if (trip.trip.errorMessage) {
        setAlertContent({
          onDidDismiss: () => setShowAlert(false),
          header: 'Terjadi kesalahan',
          message: trip.trip.errorMessage,
          buttons: ['Ok'],
        });
        setShowAlert(true);
      } else {
        updateTripGateTime(trip.trip);
      }
    }
  }, [trip.trip]);

  /**
   * Update trip gate in / out time
   * @return {[type]} [description]
   */
  const updateTripGateTime = (trip) => {
    let body = {};
    if (trip.id) {
      const {origin_gate_in, origin_gate_out, destination_gate_in, destination_gate_out} = trip;
      const origin_gate_in_range = moment().diff(moment(origin_gate_in), 'minutes');
      const destination_gate_in_range = moment().diff(moment(destination_gate_in), 'minutes');
      if (!origin_gate_in && user.company_id == trip.company_shipper_id) {
        body.origin_gate_in = moment().utc();
      } else if (origin_gate_in && !origin_gate_out && origin_gate_in_range < 30 && user.company_id == trip.company_shipper_id) {
        setAlertContent({
          onDidDismiss: () => setShowAlert(false),
          header: 'Informasi!',
          message: 'Anda sudah melakukan gate in, Anda dapat melakukan scan gate out 30 menit kemudian.',
          buttons: ['Ok'],
        });
        setShowAlert(true);
        return false;
      } else if (!origin_gate_out && user.company_id == trip.company_shipper_id) {
        body.origin_gate_out = moment().utc();
      } else if (!destination_gate_in && user.company_id != trip.company_shipper_id) {
        body.destination_gate_in = moment().utc();
      } else if (destination_gate_in && !destination_gate_out && destination_gate_in_range < 30 && user.company_id != trip.company_shipper_id) {
        setAlertContent({
          onDidDismiss: () => setShowAlert(false),
          header: 'Informasi!',
          message: 'Anda sudah melakukan gate in, Anda dapat melakukan scan gate out 30 menit kemudian.',
          buttons: ['Ok'],
        });
        setShowAlert(true);
        return false;
      } else if (destination_gate_in && !destination_gate_out && user.company_id != trip.company_shipper_id) {
        body.destination_gate_out = moment().utc();
      } else {
        setAlertContent({
          onDidDismiss: () => setShowAlert(false),
          header: 'Gate Out Sukses!',
          message: `
                    <div class="alert-custom">
                      <p class="text-muted">Shipment Number</p>
                      <p>${trip.shipment_number}</p>
                      <br />
                      <p class="text-muted">Driver</p>
                      <p>${trip.driver && trip.driver.name ? trip.driver.name : ''}</p>
                      <br />
                      <p class="text-muted">Kendaraan</p>
                      <p>${_.get(trip, 'vehicle_type.name', '')} - ${_.get(trip, 'vehicle.license_plate')}</p>
                      <br />
                      <p class="text-muted">Gate In</p>
                      <p>
                        ${ user.company_id == trip.company_shipper_id ?
                          body.origin_gate_in ? moment(body.origin_gate_in).local().format('dddd, DD MMMM YYYY, HH:mm') : moment(trip.origin_gate_in).local().format('dddd, DD MMMM YYYY, HH:mm') :
                          body.destination_gate_in ? moment(body.destination_gate_in).local().format('dddd, DD MMMM YYYY, HH:mm') : moment(trip.destination_gate_in).local().format('dddd, DD MMMM YYYY, HH:mm')
                        }
                      </p>
                    </div>
                  `,
          buttons: ['Ok'],
        });
        setShowAlert(true);

        return false;
      }

      updateTrip(trip.id, body).then(res => {
        let msg = {};
        if (res.type === `${UPDATE_TRIP}_SUCCESS`) {
          var {payload: {data}} = res;
          if (data.errorMessage) {
            msg = {
              onDidDismiss: () => setShowAlert(false),
              header: 'Gagal!',
              message: data.errorMessage,
              buttons: ['Ok'],
            }
          } else {
            if (trip) {
              if (body && (body.destination_gate_in || body.origin_gate_in)) {
                msg = {
                  onDidDismiss: () => setShowAlert(false),
                  header: 'Gate In Sukses',
                  message: `
                    <div class="alert-custom">
                      <p class="text-muted">Shipment Number</p>
                      <p>${trip.shipment_number}</p>
                      <br />
                      <p class="text-muted">Driver</p>
                      <p>${trip.driver && trip.driver.name ? trip.driver.name : ''}</p>
                      <br />
                      <p class="text-muted">Kendaraan</p>
                      <p>${_.get(trip, 'vehicle_type.name', '')} - ${_.get(trip, 'vehicle.license_plate')}</p>
                      <br />
                      <p class="text-muted">Gate In</p>
                      <p>${moment(body.destination_gate_in || body.origin_gate_in).local().format('dddd, DD MMMM YYYY, HH:mm')}</p>
                    </div>
                  `,
                  buttons: ['Ok'],
                }
              } else if (body && (body.destination_gate_out || body.origin_gate_out)) {
                msg = {
                  onDidDismiss: () => setShowAlert(false),
                  header: 'Gate Out Sukses',
                  message: `
                    <div class="alert-custom">
                      <p class="text-muted">Shipment Number</p>
                      <p>${trip.shipment_number}</p>
                      <br />
                      <p class="text-muted">Driver</p>
                      <p>${trip.driver && trip.driver.name ? trip.driver.name : ''}</p>
                      <br />
                      <p class="text-muted">Kendaraan</p>
                      <p>${_.get(trip, 'vehicle_type.name', '')} - ${_.get(trip, 'vehicle.license_plate')}</p>
                      <br />
                      <p class="text-muted">Gate Out</p>
                      <p>${moment(body.destination_gate_out || body.origin_gate_out).local().format('dddd, DD MMMM YYYY, HH:mm')}</p>
                    </div>
                  `,
                  buttons: ['Ok'],
                }
              }
            }
          }
        }
        setAlertContent(msg);
        setShowAlert(true);
      });
    }
  }

  const handleScan = data => {
    if (data) {
      try {
        data = JSON.parse(data);
      } catch (e) {

      }

      if (validateQR(data, process.env.REACT_APP_KEY)) {
        findTrip(data.data.value, {populate: 'vehicle,driver,vehicle_type'}).then(res => {
          if (res.type === `${FIND_TRIP}_FAIL` && res.error && res.error.response) {
            switch (res.error.response.status) {
              case 401:
                history.push('/login');
                setAlertContent({
                  onDidDismiss: () => setShowAlert(false),
                  header: 'Sesi Anda telah habis',
                  message: 'Silahkan masuk kembali',
                  buttons: ['Ok'],
                });
                setShowAlert(true);
                break;
              default:
              setAlertContent({
                onDidDismiss: () => setShowAlert(false),
                message: 'Terjadi kesalahan yang tidak diketahui',
                buttons: ['Ok'],
              });
              setShowAlert(true);
            }
          }
        });
        setTripId(data.data.value);
      } else {
        // Validasi trip id
        setAlertContent({
          onDidDismiss: () => {
            setShowAlert(false)
            setShowAlternative(true)
          },
          header: 'Terjadi kesalahan',
          message: 'QR tidak valid',
          buttons: ['Coba lagi'],
        });
        setShowAlert(true);
      }
    }
  }

  const handleError = err => {
    setIsQrError(err);
    setAlertContent({
      onDidDismiss: () => setShowAlert(false),
      header: 'Tidak dapat membaca QR Code!',
      message: `Pastikan Anda mengizinkan untuk menggunakan kamera perangkat Anda. ${err}`,
      buttons: ['Ok'],
    });
    setShowAlert(true);
  }

  const modalConfirmShipmentNumber = async () => {
    const { value: shipment_number } = await Swal.fire({
      icon: 'info',
      text: 'Silakan masukkan Shipment Number',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value.length > 0) {
            resolve()
          } else {
            resolve('Shipment Number tidak valid')
          }
        })
      }
    })
    if (!shipment_number) {
      return;
    }
    findTripByShipmentNumber(shipment_number, {populate: 'vehicle,driver,vehicle_type' }).then(res => {
      if (res.type === `${FIND_TRIP_BY_SHIPMENT_NUMBER}_FAIL` && res.error && res.error.response) {
        switch (res.error.response.status) {
          case 401:
            history.push('/login');
            setAlertContent({
              onDidDismiss: () => setShowAlert(false),
              header: 'Sesi Anda telah habis',
              message: 'Silahkan masuk kembali',
              buttons: ['Ok'],
            });
            setShowAlert(true);
            break;
          default:
          setAlertContent({
            onDidDismiss: () => setShowAlert(false),
            message: 'Terjadi kesalahan yang tidak diketahui',
            buttons: ['Ok'],
          });
          setShowAlert(true);
        }
      }
    });
  }

  return (
    <IonPage className="scan-page">
      {trip.loading.findTrip || trip.loading.updateTrip ? <Preloader /> : null}
      <IonAlert
        isOpen={showAlert}
        {...alertContent}
      />
      <IonHeader>
        <IonToolbar className="ritase-toolbar" color="primary">
          <IonButtons slot="start">
            <IonMenuButton auto-hide="true"></IonMenuButton>
          </IonButtons>
        <IonTitle style={{ textAlign: 'left', marginLeft: '-30px'}}>Scan</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {!isQrError && <h5 style={{marginTop: 76}}>Scan (Get In/Get Out)</h5>}

        {!isQrError && <QrReader
          delay={1000}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />}

        {!isQrError && <p>Arahkan QR code driver ke kotak diatas</p>}
        {isQrError &&  `Terjadi kesalahan ${isQrError}`}
        {showAlternative && (
          <>
            <IonCard onClick={modalConfirmShipmentNumber}>
              <IonCardContent>
                <p>Apakah ada kesulitan scan? Klik di sini untuk konfirmasi dengan nomor shipment</p>
              </IonCardContent>
            </IonCard>
          </>
        )}
      </IonContent>
    </IonPage>
  );
}

const mapstp = ({trip, user}) => {
  return {
    trip,
    user: user.data
  }
};
const mapdtp = dispatch => bindActionCreators({findTrip, updateTrip, findTripByShipmentNumber}, dispatch);

export default connect(mapstp, mapdtp)(ScanScreen);
