import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux'
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonToggle,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonSpinner,
  IonToast,
  IonIcon,
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons'
import './AdminManageScreen.scss';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { getUserProfile, getRoles, updateUser, USER_UPDATE, USER_CREATE, createUser, getUsers } from '../../../../actions/user';
import S3Provider from '../../../../helpers/S3Provider';

const AdminManageScreen = ({
  getAdminById, getRoles, updateUser, createUser, getUsers, data, loading, roles, isRolesLoading, history
}) => {
  const { id } = useParams();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [role, setRole] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState(false);
  const [message, setMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fileInput = useRef(null);

  useEffect(() => {
    getRoles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (id) {
      getAdminById(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id) {
      setName(_.get(data, 'name', ''))
      setPhone(_.get(data, 'phone_number', ''))
      setEmail(_.get(data, 'email', ''))
      setIsActive(_.get(data, 'is_active', true))
      setRole(_.get(data, 'role_id', ''))
      setImageUrl(_.get(data, 'image_url', ''))
    } else {
      setName('')
      setPhone('')
      setEmail('')
      setIsActive(true)
      setRole('')
      setImageUrl('')
    }
  }, [data])

  const onFileChange = async (e) => {
    if (_.get(e, 'target.files[0]')) {
      const file = e.target.files[0]
      setIsUploading(true)
      const fileUrl = await S3Provider.upload(file);
      setImageUrl(fileUrl)
      setIsUploading(false)
    }
  }

  const checkForm = () => {
    let has_error = false;
    let msg = '';
    if (!name) {
      msg = 'Nama harus diisi';
      has_error = true;
    } else if (!phone) {
      msg = 'Nomor Kontak harus diisi';
      has_error = true;
    } else if (!email) {
      msg = 'Email harus diisi';
      has_error = true;
    } else if (!role) {
      msg = 'Role harus diisi';
      has_error = true;
    } else if (!id && !password) {
      msg = 'Password harus diisi';
      has_error = true;
    } else if (password !== confirmPassword) {
      msg = 'Password dan Konfirmasi Password harus sama';
      has_error = true;
    }

    setMessage(msg);
    setToastType('danger')
    setShowToast(has_error);
    if (!has_error) submit();
  };

  const submit = () => {
    setIsSubmitting(true)
    let body = {
      name,
      phone_number: phone,
      email,
      is_active: isActive,
      role_id: role,
    }

    if (imageUrl) {
      body.image_url = imageUrl
    }

    if (password && password === confirmPassword) {
      body.password = password
    }

    if (!id) {
      body.user_type = 'admin'
    }

    let endpoint = id ? updateUser(id, body) : createUser(body)
    let type = id ? USER_UPDATE : USER_CREATE

    endpoint.then(res => {
      if (res.type === `${type}_SUCCESS`) {
        var { payload: { data } } = res;
        setIsSubmitting(false)
        if (data.errorMessage) {
          setMessage(data.errorMessage);
          setToastType('danger')
          setShowToast(true);
        } else {
          setMessage(`Admin berhasil ${id ? 'diubah' : 'ditambah'}`);
          setToastType('success')
          setShowToast(true);
          getUsers({
            user_type: 'admin',
            populate: 'role',
            limit: 10,
          })
          history.push('/distributor/admin')
        }
      }
    });
  }

  return (
    <IonPage className="homescreen">
      <IonHeader>
        <IonToolbar className="ritase-toolbar" color="primary">
          <IonButtons slot="start">
            <Link to="/distributor/admin">
              <IonButton color="light">
                <IonIcon slot="icon-only" icon={arrowBack} />
              </IonButton>
            </Link>
          </IonButtons>
          <IonTitle>{ id ? 'Ubah' : 'Tambah' } Admin</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading ?
          <IonSpinner name="crescent" style={{width: '100%'}}/>
        :
          <IonGrid>
            <div className="admin-manage-form">
              <IonRow>
                <IonCol>
                  <div className="admin-manage-form-item">
                    <label>Nama</label>
                    <IonInput
                      value={name}
                      placeholder="Nama"
                      onIonChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </IonCol>
              </IonRow>
              
              <IonRow>
                <IonCol>
                  <div className="admin-manage-form-item">
                    <label>Nomor Kontak</label>
                    <IonInput
                      type="tel"
                      value={phone}
                      placeholder="Nomor Kontak"
                      onIonChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </IonCol>
              </IonRow>
              
              <IonRow>
                <IonCol>
                  <div className="admin-manage-form-item">
                    <label>Email</label>
                    <IonInput
                      type="email"
                      value={email}
                      placeholder="Email"
                      onIonChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </IonCol>
              </IonRow>
              
              <IonRow>
                <IonCol>
                  <div className="admin-manage-form-item">
                    <label>Aktif</label>
                    <div>
                      <IonToggle
                        checked={isActive}
                        onIonChange={ e => setIsActive(e.detail.checked)}
                        style={{
                          paddingInline: '0'
                        }}
                      />
                    </div>
                  </div>
                </IonCol>
                <IonCol>
                  <div className="admin-manage-form-item">
                    <label>Role</label>
                    <IonSelect
                      value={role}
                      onIonChange={e => setRole(e.detail.value)}
                      interface="action-sheet"
                      placeholder="Pilih Role"
                      className="admin-manage-select"
                      disabled={isRolesLoading}
                    >
                      {roles && roles.rows && roles.rows.map(item => (
                        <IonSelectOption key={item.id} value={item.id}>
                          { item.name }
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <div className="admin-manage-form-item">
                    <label>Password</label>
                    <IonInput
                      type="password"
                      value={password}
                      placeholder="Password"
                      onIonChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="admin-manage-form-item">
                    <label>Konfirmasi Password</label>
                    <IonInput
                      type="password"
                      value={confirmPassword}
                      placeholder="Konfirmasi Password"
                      onIonChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </IonCol>
                <IonCol>
                  <div className="admin-manage-form-item">
                    <label>Foto Admin</label>
                    <div className="admin-manage-image">
                      {imageUrl && !isUploading &&
                        <img src={imageUrl} alt="admin" className="admin-image" />
                      }
                      <IonButton
                        onClick={() => fileInput.current.click()}
                        style={{ marginTop: '10px' }}
                        disabled={isUploading}
                      >
                        <input
                          accept="image/*"
                          hidden
                          ref={fileInput}
                          type="file"
                          onChange={onFileChange}
                        />
                        { isUploading ? 
                          <IonSpinner name="crescent"/> 
                        : imageUrl ? 
                          'Ubah'
                        : 
                          'Upload' 
                        }
                      </IonButton>
                    </div>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow style={{ marginTop: '30px' }}>
                <IonCol>
                  <IonButton
                    color="primary"
                    fill="solid"
                    expand="block"
                    disabled={isSubmitting}
                    onClick={checkForm}
                  >
                    { isSubmitting ?
                      <IonSpinner name="crescent"/> 
                    :
                      'SIMPAN'
                    }
                  </IonButton>
                </IonCol>
              </IonRow>
            </div>
          </IonGrid>
        }

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={message}
          duration={2000}
          color={toastType}
          position="top"
        />
      </IonContent>
    </IonPage>
  );
}

const mapStateToProps = (state) => {
  let data = state.user.profile.data
  let loading = state.user.profile.isLoading
  let roles = state.user.roles.data
  let isRolesLoading = state.user.roles.isLoading

  return ({
    data,
    loading,
    roles,
    isRolesLoading
  })
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getAdminById: getUserProfile,
  getRoles,
  updateUser,
  createUser,
  getUsers,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdminManageScreen);
