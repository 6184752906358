import React, {useState, useEffect} from 'react';
import {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButton,
    IonButtons,
    IonTitle,
    IonMenuButton,
    IonSearchbar,
    IonText, IonTabButton, IonLabel, IonTabBar, IonIcon
} from '@ionic/react';
import {connect} from 'react-redux';
import TripCard from "../components/TripCard";
import {bindActionCreators} from "redux";
import {getInbound} from "../../../../actions/order";
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import notificationIcon from '../../../../assets/new-icons/notification-white.svg';
import redDotIcon from '../../../../assets/new-icons/red-dot.svg';
import {getAllNotifications} from "../../../../actions/notification";

const InboundScreen = ({match, loading, error, data, getList, detectNewReport, getNotification, notifications}) => {
    const [search, setSearchText] = useState('');
    const [hasUnreadNotif, setHasUnreadNotif] = useState(false);

    const query = {
        status_id: '05,07,10,20,30,40,50',
        populate: 'trip.vehicle.type,trip.driver,company_transport,consignee_reports',
        searchFields: 'shipment_number,obd',
        limit: 10,
        offset: 0,
        ongoing: match.url.includes('ongoing') ? 1 : null,
        history: match.url.includes('history') ? 1 : null,
    }
    // menuController.swipeGesture(true);

    useEffect(() => {
        getList({
            ...query,
            search: search || null,
        });
    }, [search, detectNewReport]);

    useEffect(() => {
        getNotification();
    }, []);

    useEffect(() => {
        if (notifications && notifications.length > 0) {
            let unread_notif = notifications.filter(n => !n.read_at);
            setHasUnreadNotif(unread_notif && unread_notif.length > 0);
        }
    }, [notifications]);

    const nextData = () => {
        const limit = get(query, 'limit', 10);
        const currentPage = get(data, 'offset', 0) / limit + 1;
        const offset = currentPage * limit;
        getList({
            ...query,
            offset,
        })
    }

    return (
        <IonPage className="homescreen">
            <IonHeader>
                <IonToolbar className="ritase-toolbar" color="primary">
                    <IonButtons slot="start">
                        <IonMenuButton auto-hide="true"></IonMenuButton>
                    </IonButtons>
                    <IonTitle>Distributor</IonTitle>
                    <IonButtons slot="end">
                        <Link to={'/distributor/notification'}>
                            <span style={{marginRight: '10px'}}>
                                <img src={notificationIcon} alt="notifikasi" />
                                {
                                    hasUnreadNotif ? <img src={redDotIcon} style={{position: 'relative', right: '10px', top:'-15px', width: '13px' }} /> : ''
                                }
                            </span>
                        </Link>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent style={{textAlign: 'left'}}>
                <form noValidate onSubmit={(e) => {
                    getList({...query, search: search || null});
                    e.preventDefault();
                    return false;
                }}>
                    <IonSearchbar mode="ios" inputmode="search" defaultValue={search} debounce={1000}
                                  onIonChange={e => setSearchText(e.detail.value)} type={'search'}
                                  placeholder="Cari shipment / OBD number"></IonSearchbar>
                </form>

                {/* start no data info */}
                {!loading && !error && get(data, 'rows.length', 0) === 0 && <div style={{textAlign: 'center'}}>
                    <IonText>Data tidak ditemukan</IonText>
                </div>}
                {/* end no data info */}

                {/* start the list */}
                {get(data, 'rows', []).map(inbound => (
                    <TripCard
                        key={get(inbound, 'id')}
                        reports={get(inbound, 'consignee_reports')}
                        expectedArrivalDate={get(inbound, 'expected_arrival')}
                        routerLink={`/distributor/inbound/detail/${get(inbound, 'id')}`}
                        shipmentNumber={get(inbound, 'shipment_number', '')}
                        trip={{
                            ...get(inbound, 'trip', {}),
                            company_transport: get(inbound, 'company_transport')
                        }}/>))}
                {/* end the list */}

                {/* start loading skeleton*/}
                {loading && <>
                    <TripCard loading/>
                    <TripCard loading/>
                    <TripCard loading/>
                </>}
                {/* end loading skeleton*/}
                {!loading && get(data, 'rows.length', 0) < get(data, 'count') && <>
                    <br/>
                    <IonButton onClick={nextData} expand={'block'} fill={'clear'}>Muat lebih banyak</IonButton>
                </>}
            </IonContent>
            <IonTabBar slot="bottom">
                <IonTabButton tab="tab1" href={'/distributor/inbound/ongoing'}>
                    <IonLabel>Ongoing</IonLabel>
                </IonTabButton>

                <IonTabButton tab="tab2" href={'/distributor/inbound/history'}>
                    <IonLabel>History</IonLabel>
                </IonTabButton>
            </IonTabBar>
        </IonPage>
    );
}

const mapStateToProps = ({order: {inbound: {loading, error, data}}, consignee: {store: {loading: detectNewReport}}, notification: {listNotifications} }) => {
    return ({
        loading,
        error,
        data,
        detectNewReport,
        notifications: listNotifications
    })
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getList: getInbound,
    getNotification: getAllNotifications,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InboundScreen);
