/* eslint-disable react-hook/react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { IonPage, IonRouterOutlet, IonAlert} from '@ionic/react';
import { menuController } from "@ionic/core";
import { IonReactRouter } from '@ionic/react-router';
import TripListScreen from './TripListScreen';
import TripDetailScreen from './TripDetailScreen';
import TripAuthorizationScreen from './TripAuthorizationScreen';
import ProfileScreen from '../SecurityContainer/ProfileScreen';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {logout,clearTimeout} from '../../../actions/user';
import ShipperSideMenu from '../../../components/ShipperSideMenu'
import './warehouse.scss';

const WarehouseContainer = ({match, location, token, history, data, isTimeout} ) => {
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    if (!token) {
      history.push('/login');
      window.location.reload();
    }
  }, [token]);

  // useEffect(() => {
  //   if (data && data.role && !checkPermission(data.role, 'setActualQuantity')) {
  //     setShowAlert(true);
  //     history.push('/login');
  //   }
  // }, [data]);

  const checkPermission = (role, action) => {
    if (!role.permissions) {
      return false;
    }

    const hasAccess = role.permissions.find(p =>  p.action === action)
    return typeof hasAccess !== 'undefined';
  }

  const urlGen = (path) => {
    return `${match.url}/${path}`;
  };

  return (
    <>
     <ShipperSideMenu/>
      <IonPage id="mobile-shipper">
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'Informasi'}
          message={'Mohon maaf, anda tidak memiliki akses.'}
          buttons={['OK']}
        />
        <IonAlert
          isOpen={isTimeout}
          onDidDismiss={() => menuController.close()}
          header={'Login Expired!'}
          message={'Silakan login kembali'}
          buttons={['Ok']}
        />
        <IonReactRouter>
            <IonRouterOutlet>
              <Route path={urlGen('trips')} component={TripListScreen} />
              <Route path={urlGen('authorize/:id')} component={TripAuthorizationScreen}/>
              <Route path={urlGen('profile')} component={ProfileScreen} />
              <Route path={match.url} exact render={() => <Redirect to={urlGen('trips')}/>} />
              <Route path={urlGen('trip/:id')} component={TripDetailScreen} />
            </IonRouterOutlet>
        </IonReactRouter>
      </IonPage>
    </>
  );
}

const mapstp = ({user: {token, data, isTimeout}}) => {
  return {
    token,
    data,
    isTimeout
  }
};

const mapdtp = dispatch => bindActionCreators({logout, clearTimeout}, dispatch);

export default connect(mapstp, mapdtp)(WarehouseContainer);
