import axios from 'axios'
import Swal from 'sweetalert2';
import { client } from '../store';


const getSignature = async () => {
  const date = new Date();
  const body = {
    currentDate: date.toISOString(),
    folderName:  "warehouse/",
    redirectURL: "",
    app: "ritase"
  }

  const { data: { data } } = await client.post('upload/picture/generatePolicy', body);
  return data;
}

const ISODateString = (d) => {
  function pad(n) { return n < 10 ? '0' + n : n }
  return '' + d.getUTCFullYear()
    + pad(d.getUTCMonth() + 1)
    + pad(d.getUTCDate()) + 'T'
    + '000000Z'
}

const upload = async (file) => {
  const signature = await getSignature();
  const date = ISODateString(new Date());
  const filename = "warehouse/" + file.name;
  const config = {
    key: filename,
    acl: "public-read",
    'Content-Type': file.type,
    'x-amz-meta-uuid': '14365123651274',
    'X-Amz-Credential': process.env.REACT_APP_S3_ACCESS_KEY + '/' + date.split('T')[0] + '/ap-southeast-1/s3/aws4_request',
    'X-Amz-Algorithm': 'AWS4-HMAC-SHA256',
    'X-Amz-Date': date,
    'x-amz-meta-tag': '',
    'success_action_status': '200',
    Policy: signature.s3Policy,
    'X-Amz-Signature': signature.s3Signature,
    file
  }

  const formData = new FormData();
  Object.keys(config).map(data => {
    if (data === 'file') {
      formData.append(`${data}`, config[data], file.name);
    } else {
      formData.append(`${data}`, config[data]);
    }
  })
  try {
    const response = await axios.post(process.env.REACT_APP_S3_PATH, formData, { 'Content-Type': file.type });
    if (response.status >= 200) {
      return process.env.REACT_APP_S3_PATH + filename;
    }
  } catch (error) {
    Swal.fire(
      'Error',
      'Gagal mengupload!',
      'error'
    );
    throw error;
  }
} 

const S3Provider = {
  getSignature,
  upload,
}

export default S3Provider;