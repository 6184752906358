import React from 'react';
import _ from 'lodash';
import ApprovalLogApprover from './ApprovalLogApprover';
import './ApprovalLogTimeline.scss';

const ApprovalLogTimeline = ({ items, reverse }) => {

  const logItems = _.cloneDeep(items)
  const processItems = reverse ? logItems.reverse() : logItems;

  return (
    <>
      <ul className="timeline">
        {
          processItems && processItems.length > 0 && processItems.map((item, index) => {
            return (
              <li className="timeline-item">
                <div className="timeline-item-tail" className={`timeline-item-tail ${processItems.length == index + 1 ? "last" : ""}`}></div>
                <div className="timeline-item-node timeline-item-node-normal"></div>
                <div className="timeline-item-wrapper">
                { 
                    item.type == 'assign'
                    ? (
                      <span class="assign" key={index}>
                        <span className="title">Requested</span>
                        <span>menugaskan ke { _.get(item, 'company_transport.name') }</span>
                        <span class="timestamp is-bottom">{ _.get(item, 'formatted_date') }</span>
                      </span>
                    )
                    : (
                      item.approvers.map((approver, index) => {
                        return (
                          <ApprovalLogApprover obj={approver} key={index} />
                        )
                      })
                    )
                  }
                </div>
              </li>
            )
          })
        }
      </ul>
    </>
  );
}

export default ApprovalLogTimeline;