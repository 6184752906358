/* global google */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonPage, IonContent } from '@ionic/react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, DirectionsRenderer } from 'react-google-maps';
import _ from 'lodash';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { detail as getDetail } from '../../../../actions/order';
import { getDetail as getTrip } from '../../../../actions/trip';
import left from '../../../../assets/new-icons/left.svg';
import noavatar from '../../../../assets/no-avatar.png';
import originmarker from '../../../../assets/new-icons/origin-orange.svg';
import destinationmarker from '../../../../assets/new-icons/dest-blue.svg';
import truckmarker from '../../../../assets/new-icons/truck_top.svg';
// import './OrderDetailScreen.scss';

const OrderDetailTrack = (props) => {
  const { data } = props;
  const [id, setID] = useState(props.match.params.id);
  const [idtrip, setIDTrip] = useState(props.match.params.idtrip);
  const [position, setPosition] = useState(null);
  const [origin, setOrigin] = useState({});
  const [destination, setDestination] = useState({});
  const [directions, setDirections] = useState({});
  const [isNoGps, setIsNoGps] = useState(false);

  if (id !== props.match.params.id) {
    setID(props.match.params.id);
  };

  if (idtrip !== props.match.params.idtrip) {
    setIDTrip(props.match.params.idtrip);
  };

  useEffect(() => {
    props.getDetail(id).then(function (result) {
      let res = result.payload.data;
      if (res.id) {
        initMap(res);
      };
    });
  }, [id]);

  useEffect(() => {
    props.getTrip(idtrip).then(function (result) {
      let location = _.get(result, 'payload.data.latest_location', null);
      
      if (location) {
        setIsNoGps(false);
        let latest_time = null;
        let latest_position = null;
        _.each(location, (loc) => {
          let time = loc.time ? moment(loc.time) : null;
          if (time) {
            if (!latest_time || moment(latest_time).isBefore(time)) {
              latest_time = time;
              latest_position = {
                lat: loc.latitude,
                lng: loc.longitude,
              }
            }
          }
        });
  
        setPosition(latest_position);
      } else {
        setPosition(null);
        setIsNoGps(true);
      }
    });
  }, [idtrip]);

  // useEffect(() => {
  //   if (origin && destination) {
  //     let data = {
  //       // waypoints: [
  //       //   { location: new google.maps.LatLng(position.lat, position.lng) }
  //       // ],
  //       destination,
  //     }
      
  //     if (position) {
  //       data.origin = position
  //     } else {
  //       data.origin = origin
  //     }

  //     createRoute(data)
  //   }
  // }, [position, origin, destination])

  const goBack = () => {
    // props.history.goBack();
    props.history.push('/distributor/inbound/detail/' + id);
  };

  const initMap = (res) => {
    const directionsService = new google.maps.DirectionsService();

    let ori = {
      lat: parseFloat(_.get(res, 'origin.latitude', 0)),
      lng: parseFloat(_.get(res, 'origin.longitude', 0))
    }
    setOrigin(ori);

    let dest = {
      lat: parseFloat(_.get(res, 'destination.latitude', 0)),
      lng: parseFloat(_.get(res, 'destination.longitude', 0))
    }
    setDestination(dest);

    directionsService.route(
      {
        origin: ori,
        destination: dest,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }


  // const createRoute = (data) => {
  //   const directionsService = new google.maps.DirectionsService();
  //   directionsService.route(
  //     data,
  //     (result, status) => {
  //       if (status === google.maps.DirectionsStatus.OK) {
  //         setDirections(result);
  //       } else {
  //         console.error(`error fetching directions ${result}`);
  //       }
  //     }
  //   );
  // }

  const google_api_key = process.env.REACT_APP_GOOGLE_API_KEY;
  const google_map_url = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=drawing&key=${google_api_key}`;
  const defaultOptions = {
    zoomControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
  }
  const directionsOptions = {
    polylineOptions: {
      strokeColor: '#008c98',
      strokeWeight: 4
    },
    preserveViewPort: true,
    suppressMarkers: true
  }

  const OrderMap = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
      defaultZoom={10}
      clickableIcons={false}
      defaultOptions={defaultOptions}
      defaultCenter={position}
    >
      {_.get(position, 'lat') && _.get(position, 'lng') &&
        <Marker position={position} icon={truckmarker} />
      }
      {origin.lat && origin.lng && 
        <Marker 
          position={origin} 
          icon={{
            url: originmarker,
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(5, 10),
          }} 
        />
      }
      {destination.lat && destination.lng && 
        <Marker 
          position={destination} 
          icon={{
            url: destinationmarker,
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(5, 10),
          }} 
        />
      }
      {directions.status === 'OK' &&
        <DirectionsRenderer
          directions={directions}
          options={directionsOptions}
        />
      }
    </GoogleMap>
  ))

  return (
    <IonPage className="order-detail">
      <IonContent>
        {isNoGps &&
          <div
            className="order-detail-card-container"
            style={{
              bottom: 'auto',
              top: '40%',
              textAlign: 'center',
            }}
          >
            <div
              className="order-detail-card"
              style={{
                width: '50%',
                margin: 'auto',
              }}
            >
              Tidak ada informasi GPS dari driver
            </div>
          </div>
        }

        <div className="back-btn" onClick={goBack}>
          <img src={left} alt=""/>
        </div>

        <div className="order-detail-card-container">
          <div className="order-detail-card">
            {_.get(data, 'trip.driver', false) &&
              <div style={{display: 'inline-flex'}}>
                <img src={_.isNil(data.trip.driver.image_url) ? noavatar : data.trip.driver.image_url} alt=''/>
                <div>
                  <div style={{fontWeight: 'bold', marginBottom: '5px'}}>{data.trip.driver.name || '-'}</div>
                  <div>{data.trip.driver.phone_number || '-'}</div>
                </div>
              </div>
            }
            <span className="order-status">{_.get(data, 'status.name', '')}</span>
            <div style={{display:'inline-flex', fontSize: '12px', marginTop: '14px', width: '100%'}}>
              <div style={{marginRight: '20px'}}>
                <div className="order-card-title">Distance</div>
                <div>{data.distance || '0'} Km</div>
              </div>
              <div>
                <div className="order-card-title">Shipper</div>
                <div>{_.get(data, 'company_shipper.name', '-') || '-'}</div>
              </div>
            </div>
          </div>
        </div>

        <div style={{
          height: '100%', 
          width: '100%',
          filter: isNoGps ? 'brightness(0.5)' : 'none'
        }}>
          <OrderMap
            isMarkerShown
            googleMapURL={google_map_url}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = state => {
  return {
    data: state.order.detail
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getDetail, getTrip
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailTrack);