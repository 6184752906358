import React, {useState, useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {
    IonPage,
    IonRouterOutlet,
    IonAlert,
    // IonTabs,
    // IonTabBar,
    // IonTabButton,
    // IonLabel,
    // IonToolbar,
    // IonButtons, IonMenuButton, IonTitle, IonHeader
} from '@ionic/react';
import {menuController} from "@ionic/core";
import {IonReactRouter} from '@ionic/react-router';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {logout, clearTimeout} from '../../../actions/user';
import ConsigneeSideMenu from '../../../components/ConsigneeSideMenu'
import './distributor.scss';
import InboundScreen from "./InboundScreen";
import get from 'lodash/get';
import InboundDetailScreen from "./InboundDetailScreen";
// import InboundTabScreen from "./InboundTabScreen";
import NotificationSettingScreen from './NotificationSettingScreen';
import NotificationScreen from './NotificationScreen';
import OrderDetailTrack from './InboundDetailScreen/OrderDetailTrack';
import OrderDetailPackage from './InboundDetailScreen/OrderDetailPackage';
import OrderDetailStatus from './InboundDetailScreen/OrderDetailStatus';
import AdminScreen from './AdminScreen';
import AdminManageScreen from './AdminManageScreen';

const DistributorContainer = ({match, token, history, data, isTimeout, isConsignee, getOut}) => {
    const [showAlert, setShowAlert] = useState(false);
    useEffect(() => {
        if (!token) {
            history.push('/login');
            window.location.reload();
        }
    }, [token]);

    useEffect(() => {
        if (!isConsignee) {
            setShowAlert(true);
        }
    }, [isConsignee]);

    const urlGen = (path) => {
        return `${match.url}/${path}`;
    };

    const expelIntruders = () => {
        getOut().then(() => {
            history.push('/login');
        });
    }

    return (
        <>
            <ConsigneeSideMenu/>
            <IonPage id="mobile-consignee">
                <IonAlert
                    isOpen={showAlert}
                    header={'Informasi'}
                    message={'Mohon maaf, anda tidak memiliki akses.'}
                    onDidDismiss={expelIntruders}
                    buttons={[{ text: 'OK', handler: expelIntruders}]}
                />
                <IonAlert
                    isOpen={isTimeout}
                    onDidDismiss={() => menuController.close()}
                    header={'Login Expired!'}
                    message={'Silakan login kembali'}
                    buttons={['Ok']}
                />
                {/*<IonHeader>*/}
                {/*    */}
                {/*</IonHeader>*/}
                <IonReactRouter>
                    <IonRouterOutlet>
                        <Route path={urlGen('inbound')} exact>
                            <Redirect to={urlGen('inbound/ongoing')} />
                        </Route>
                        <Route path={urlGen('inbound/ongoing')} component={InboundScreen}/>
                        <Route path={urlGen('inbound/history')} component={InboundScreen}/>
                        <Route path={match.url} exact render={() => <Redirect to={urlGen('inbound')}/>}/>
                        <Route path={urlGen('inbound/detail/:id/track/:idtrip')} component={OrderDetailTrack}/>
                        <Route path={urlGen('inbound/detail/:id/status')} component={OrderDetailStatus}/>
                        <Route path={urlGen('inbound/detail/:id/package')} component={OrderDetailPackage}/>
                        <Route path={urlGen('inbound/detail/:id')} component={InboundDetailScreen}/>
                        <Route path={urlGen('notification/setting')} component={NotificationSettingScreen}/>
                        <Route path={urlGen('notification')} component={NotificationScreen}/>
                        <Route path={urlGen('admin/create')} component={AdminManageScreen}/>
                        <Route path={urlGen('admin/:id')} component={AdminManageScreen}/>
                        <Route path={urlGen('admin')} component={AdminScreen}/>
                    </IonRouterOutlet>
                </IonReactRouter>
            </IonPage>
        </>
    );
}

const mapstp = ({user: {token, data, isTimeout, company}}) => {
    return {
        token,
        data,
        isTimeout,
        isConsignee: get(company, 'company_type') !== 'consignee',
    }
};

const mapdtp = dispatch => bindActionCreators({getOut: logout, clearTimeout}, dispatch);

export default connect(mapstp, mapdtp)(DistributorContainer);
