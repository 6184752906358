import React, { useEffect, useState } from 'react';
import { IonButton, IonCheckbox, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonItem, IonLabel, IonModal, IonRadio, IonRadioGroup, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import Preloader from '../../../../components/Preloader';
import { VehicleChecklistStatus } from './VehicleChecklistStatus';

export const ModalVehicleChecklist = ({
  isOpenModal,
  toggleModal,
  loading,
  options,
  modalMode,
  selectedVehicleChecklistHistory,
  handleChangeChecklist,
  handleChangeStatus,
  formVehicleChecklist,
  vehicleStatusOptions,
  onClickSubmit,
  setModalMode
}) => {
  const [optionItems, setOptionItems] = useState([])
  
  useEffect(() => {
   if (modalMode === 'view') {
        if (selectedVehicleChecklistHistory) {
          const optionItems = selectedVehicleChecklistHistory.items.map((item)=>{return {name: item.name, is_checked: item.is_checked}})
          setOptionItems(optionItems)
        }
      }

      return () => {
        setOptionItems([])
      }
  }, [selectedVehicleChecklistHistory])

  const clearModeView = () => {
    setModalMode('manage');
  }

  const handleBackButton = () => {
    clearModeView();
    toggleModal();
  }

  return (
    <IonModal
      isOpen={isOpenModal}
      initialBreakpoint={0.5}
      breakpoints={[0, 0.5, 1]}
      style={{marginTop: '20px'}}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Vehicle Checklist</IonTitle>
        </IonToolbar>
      </IonHeader>
      {
        loading && <Preloader/>
      }
      <IonContent>
        <IonGrid>
          {
            !loading && modalMode === 'view' && selectedVehicleChecklistHistory &&
            <VehicleChecklistStatus item={selectedVehicleChecklistHistory}/>
          }
          
          {
             !loading && modalMode !== 'view' && options && options.length && options.map((item, key) => (
              <IonRow className='ion-align-items-center' key={key}>   
                <IonCol size='2'>
                  <IonCheckbox checked={formVehicleChecklist.items && formVehicleChecklist.items[item]} onIonChange={e=>handleChangeChecklist(item, e.detail.checked)} />
                </IonCol>
                <IonCol>
                  {item}
                </IonCol>
              </IonRow>
            ))
          }

          {
            !loading && modalMode === 'view' && optionItems.length
              ? optionItems.map((item, key) => (
                  <IonRow className='ion-align-items-center' key={key}>   
                    <IonCol size='2'>
                      <IonCheckbox disabled={true} checked={item.is_checked}/>
                    </IonCol>
                    <IonCol>
                      {item.name}
                    </IonCol>
                  </IonRow>
                ))
              : ''
          }
        </IonGrid>
        
        <IonGrid className='ion-margin'>
          <IonRow>
            Vehicle Status
          </IonRow>
          <IonRow>
            <IonRadioGroup value={modalMode === 'view' ? selectedVehicleChecklistHistory.status : formVehicleChecklist.status} onIonChange={(e) => { if(modalMode!=='view') handleChangeStatus(e.target.value) }} style={{width: '100%'}}>
              {
                vehicleStatusOptions && vehicleStatusOptions.length && vehicleStatusOptions.map((item, key) => (
                  <IonItem key={key} lines='none'>
                    <IonLabel>{item.label}</IonLabel>
                    <IonRadio disabled={modalMode === 'view'} slot='start' color={item.id === 'accept' ? 'primary' : 'danger'} value={item.id}/>
                  </IonItem>
                ))
              }
            </IonRadioGroup>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonButton color='light' expand='block' onClick={handleBackButton}>Kembali</IonButton>
            </IonCol>
            {
              modalMode !== 'view' &&
              <IonCol>
                <IonButton expand='block' color='primary' onClick={onClickSubmit}>Simpan</IonButton>
              </IonCol>
            }
          </IonRow>
        </IonGrid>
      </IonFooter>
    </IonModal>
  )
}