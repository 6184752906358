import React, { useEffect, useState } from 'react';
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonGrid, IonRow, IonImg, IonSearchbar, IonInfiniteScroll, IonInfiniteScrollContent, IonList, IonText, IonItemDivider } from '@ionic/react';
import './VehicleChecklistScreen.scss';
import {connect} from 'react-redux';
import {logout} from '../../../../actions/user';
import { bindActionCreators } from 'redux';
import { get as getOrders } from '../../../../actions/order';
import { OrderCard } from './OrderCard';
import TruckIcon from '../../../../assets/icons/truck.svg';

const VehicleChecklistScreen = ( props ) => {
  const [ disableInfiniteScroll, setDisableInfiniteScroll ] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [loading, setLoading] = useState(true)
  
  let params = "&is_trucktobee_order=1&status_id=05,07,10"
  const populate = "company_transport,company_shipper,type,origin,origin_area,destination,destination_area,status,trip,trip.tasks,trip.shipping_containers,trip.destination,trip.driver,trip.driver.rating,trip.vehicle,trip.vehicle.type,trip.vehicle.media,trip.pods,pickup,load,dropoff,unload,pod,pods,additional_fees,packages,pool,company_transport.pools,created_by,trip.container_tasks.tasks,files,document_tracks,pods.uploaded_by,issues,issues.type,route_code,trip.vehicle_type,tag,company_shipper.company_rating.badge_level,company_transport.company_rating.badge_level,pods.media,company_broker,trip.all_order_offers"

  useEffect(() => {
    if (!props.token) {
      return window.location.href = '/login'
    }

    if (searchValue) {
      params = params + "&searchFields=shipment_number&search="+searchValue
    }
    setLoading(true)
    props.getOrders(0, params, populate).then(_=>setLoading(false))
  }, [props.token, searchValue])

  const loadMore = (e) => {
    props.getOrders(props.offset + 10, params, populate);
    
    e.target.complete();
    
    if (props.count > props.orders.length) {
      setDisableInfiniteScroll(false);
    } else {
      setDisableInfiniteScroll(true);
    };
  };

  return (
    <IonPage className="vehiclechecklistscreen">
      <IonHeader>
        <IonToolbar className="ritase-toolbar" color="primary">
          <IonButtons slot="start">
            <IonMenuButton auto-hide="true"></IonMenuButton>
          </IonButtons>
        <IonTitle style={{ textAlign: 'left', marginLeft: '-30px'}}>Vehicle Checklist</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonItemDivider sticky>
          <IonSearchbar disabled={loading} placeholder='Cari shipment number' value={searchValue} onIonChange={e => setSearchValue(e.detail.value)} debounce={1000}></IonSearchbar>
        </IonItemDivider>

        {
          !loading && props.orders && !props.orders.length && !loading && 
          <IonGrid>
              <IonRow className='ion-justify-content-center'>
                <IonText style={{ textAlign: 'center'}}>
                  <p>Data tidak ditemukan</p>
                </IonText>
              </IonRow>
              <IonRow className='ion-justify-content-center' style={{marginTop: '100px'}}>
                <img src={TruckIcon} width={100} style={{ opacity: '0.1'}} />
              </IonRow>
          </IonGrid>
        }
        
        {
          !loading && 
          <IonList>
          {props.orders.map((item, index) => {
            return <OrderCard key={index} order={item} nextButton={ true } />
          })}
        </IonList>
        }

        <IonInfiniteScroll
          threshold="10px"
          disabled={disableInfiniteScroll}
          onIonInfinite={(e) => loadMore(e)}
        >
          <IonInfiniteScrollContent loadingSpinner="circular" />
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
}

const mapstp = state => {
  const { user, order } = state
  const token = user && user.token
  const data = user && user.data

  const { list, count, offset } = order

  return {
    token,
    data,
    orders: list,
    count,
    offset
  }
};

const mapdtp = (dispatch) => {
  return bindActionCreators({ getOrders, logout }, dispatch);
}

export default connect(mapstp, mapdtp)(VehicleChecklistScreen);
