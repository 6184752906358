import React, { useState } from 'react'
import { IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonItem, IonList, IonPopover, IonRow } from '@ionic/react'

import piiVehicleIcon from '../../../../assets/icons/pii-vehicle.svg'
import locationIcon from '../../../../assets/new-icons/gray-location.svg'
import rightsmallIcon from '../../../../assets/new-icons/primary-rightsmall.svg'
import { Link } from 'react-router-dom'

export const OrderCard = ({ order, nextButton }) => {
  const id = order && order.id

  const driverName = order && order.trip && order.trip.driver && order.trip.driver.name
  const license_plate = order && order.trip && order.trip.vehicle && order.trip.vehicle.license_plate
  const company_transport_name = order && order.company_transport && order.company_transport.name

  const destination = order && order.destination_contact_name
  const vehicle_type = order && order.trip && order.trip.vehicle_type && order.trip.vehicle_type.name
  const ready_time = order && order.ready_time
  
  const shipment_number = order && order.shipment_number
  const order_number = order && order.order_number && order.order_number.includes(",") ? order.order_number.split(",") : [order.order_number]
  const po_number = order && order.po_number && order.po_number.includes(",") ? order.po_number.split(",") : [order.po_number]

  const [popoverOrdernumber, setPopoverOrdernumber] = useState(false)
  const [popoverPonumber, setPopoverPonumber] = useState(false)

  const formatReadyTime = (time) => {
    const event = new Date(time)
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    const timeOptions = { hour: '2-digit', minute: '2-digit' }
    return `${event.toLocaleDateString('id-ID', options)}, ${event.toLocaleTimeString('it-IT', timeOptions)}`
  }

  return (
    <IonCard>
      <IonCardContent>
        <IonGrid>
          <IonRow className='ion-align-items-center'>
            <IonCol size='10'>
              <IonGrid>
                <IonRow className='color-black'>
                  { license_plate !== '0' ? license_plate : '-' } { driverName && ', '+driverName }
                </IonRow>
                <IonRow class='color-black'>
                  { company_transport_name }
                </IonRow>
              </IonGrid>
            </IonCol>
            {
              nextButton &&
                <IonCol>
                  <Link to={'/security/vehicle-checklist/'+id}>
                    <button className='next-button'>
                      <img src={rightsmallIcon} width={16} height={16}/>
                    </button>
                  </Link>
                </IonCol>
            }
          </IonRow>
        </IonGrid>
        <hr />
        
        <IonGrid>
          <IonRow>
            <IonCol size='2'>
              <img src={locationIcon} width={24} height={24}  style={{color: 'gray'}}/>
            </IonCol>
            <IonCol className='color-black'>
              { destination || '-' }
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2'>
              <img src={piiVehicleIcon} width={24} height={24} />
            </IonCol>
            <IonCol className='color-black'>
              { vehicle_type || '-'}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='3'>
              <small>Ready Time</small>
            </IonCol>
            <IonCol className='color-black'>
              <small>
                {formatReadyTime(ready_time)}
              </small>
            </IonCol>
          </IonRow>
        </IonGrid>
        <hr />
        
        <IonGrid>
          <IonRow>
            <IonCol size='4'>
              <small>
                Shipment Number
                <br />
                
                <span className='color-black'>
                  { shipment_number || '-' }
                </span>
              </small>
            </IonCol>
            <IonCol size='4'>
              <small>
                Order Number
                <br />
                
                <span className='color-black'>
                  { order_number[0] || '-' }
                </span>
                {
                  order_number && order_number.length > 1 &&
                  <>
                    <br />
                    <IonButton size='small' onClick={()=>setPopoverOrdernumber(true)}>
                      +{order_number.length-1}
                    </IonButton>
                    <IonPopover isOpen={popoverOrdernumber} onDidDismiss={()=>setPopoverOrdernumber(false)}>
                      <IonList>
                        {
                          order_number.map((item, key) => (
                            <IonItem lines='none' key={key}>
                              <small>
                                {item}
                              </small>
                            </IonItem>
                          ))
                        }
                      </IonList>
                    </IonPopover>
                  </>
                }
              </small>
            </IonCol>
            <IonCol size='4'>
              <small>
                PO Number
                <br />
                
                <span className='color-black'>
                  { po_number[0] || '-' }
                </span>
                {
                  po_number && po_number.length > 1 &&
                  <>
                    <br />
                    <IonButton size='small' onClick={()=>setPopoverPonumber(true)} onDidDismiss={()=>setPopoverPonumber(false)}>
                      +{po_number.length-1}
                    </IonButton>
                    <IonPopover isOpen={popoverPonumber}>
                      <IonList>
                        {
                          po_number.map((item, key) => (
                            <IonItem lines='none' key={key}>
                              <small>
                                {item}
                              </small>
                            </IonItem>
                          ))
                        }
                      </IonList>
                    </IonPopover>
                  </>
                }
              </small>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  )
}