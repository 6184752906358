import React from 'react';
import { IonPage, IonContent, IonHeader, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonButtons, IonMenuButton, IonCard, IonLabel } from '@ionic/react';
import './ProfileScreen.scss';
import {connect} from 'react-redux';
import noavatar from '../../../../assets/no-avatar.png';
import {logout} from '../../../../actions/user';
import {bindActionCreators} from 'redux';
import { Typography } from '@material-ui/core';
import logo from '../../../../assets/logo-freighthub.png';

const ProfileScreen = ({data}) => {
  const image_url = data && data.image_url ? data.image_url : noavatar;
  const userRole = data && data.role && data.role.name ? data.role.name : '';

  return (
    <IonPage className="profile-screen">
      <IonHeader>
        <IonToolbar className="ritase-toolbar" color="primary">
          <IonButtons slot="start">
            <IonMenuButton auto-hide="true"></IonMenuButton>
          </IonButtons>
        <IonTitle style={{ textAlign: 'left', marginLeft: '-30px'}}>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div>
          <IonCard style={{width: '100%'}}>
            <IonGrid>
              <IonRow>
              <div className='username'> 
                <img src={image_url} className='avatar' alt='avatar'/>
                  <IonGrid>
                    <IonRow>
                      <Typography className='label'>{ data && data.name }</Typography>
                    </IonRow>
                    <IonRow>
                      <IonLabel className='ion-text-capitalize'>{userRole}</IonLabel>
                    </IonRow>
                </IonGrid>
              </div>
            </IonRow>
            <IonRow>
              <IonCol className='label'>Email</IonCol>
              <IonCol className="text-muted ion-text-end">{data && data.email}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol className='label'>Nomor Kontak</IonCol>
              <IonCol className="text-muted ion-text-end">{data && data.phone_number}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol className='label'>Perusahaan</IonCol>
              <IonCol className="text-muted ion-text-end">{data && data.company && data.company.name}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol className='label'>Security ID</IonCol>
              <IonCol className="text-muted ion-text-end">{data && data.id}</IonCol>
            </IonRow>
          </IonGrid>
          </IonCard>
          <img src={logo} alt="Freighthub" width="150dp" />
        </div>
      </IonContent>
    </IonPage>
  );
}

const mapstp = ({user: {data}}) => ({data});
const mapdtp = dispatch => bindActionCreators({logout}, dispatch);

export default connect(mapstp, mapdtp)(ProfileScreen);
