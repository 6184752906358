const getApprovalTime  = (data, user_id) => {
  let schema = data && data.schema;
  if (schema && typeof schema != 'object') {
    schema = JSON.parse(schema);
  }

  if (data && data.approval_records && data.approval_records.length > 0) {
    let selectedApprovalRecords = data.approval_records.find(record => record.user_id == user_id);
    if (selectedApprovalRecords && selectedApprovalRecords.action_at) {
      return selectedApprovalRecords.action_at;
    }
  }

  if (schema) {
    let allBaseApproval = [];
    schema.payload.forEach(element => {
      allBaseApproval = allBaseApproval.concat(element.base_approval);
    });

    let selectedApprover = allBaseApproval.find(base_approval => base_approval.approver_id === user_id)

    if (!selectedApprover || !selectedApprover.notified_at) {
      return data.created_at;
    }

    return selectedApprover.notified_at;
  }
}

export default getApprovalTime;
