import React, { useState, memo} from 'react';
import { IonMenu, IonItem, IonList, IonContent, IonHeader, IonTitle, IonToolbar, IonAvatar, IonGrid, IonRow, IonCol, IonAlert, IonIcon} from '@ionic/react';
import { withRouter } from "react-router-dom";
import { menuController } from "@ionic/core";
import defaultAvatar from '../assets/no-avatar.png';
import { logout} from '../actions/user';
import { logOut } from 'ionicons/icons'
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
import './ShipperSideMenu.scss';
import { Typography } from '@material-ui/core';

const ShipperSideMenu = memo(({user, logout}) => {
  const [showAlert, setShowAlert] = useState(false);
  const image_url = user && user.data && user.data.image_url ? user.data.image_url : defaultAvatar;

  const doLogout = () => {
    logout().then(res => {
      menuController.toggle();
      window.location.reload();
    });

  }
  return (
    <>
      <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'Keluar'}
          message={'Anda yakin ingin keluar?'}
          buttons={[{
            text: 'Batal',
            role: 'cancel',
          }, {
            text: 'Keluar',
            handler: () => doLogout(),
          }]}
        />
      <IonMenu side="start" id='shipper-side-menu' contentId='mobile-shipper' swipeGesture={false}>
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle></IonTitle>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonAvatar style={{ margin: 'auto' }}>
                    <img src={image_url} alt='avatar'/>
                  </IonAvatar>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol style={{textAlign: 'center'}}>
                  <IonTitle>{user && user.data && user.data.role && user.data.role.name.toUpperCase()}</IonTitle>
                  <Typography>{user.data && user.data.name}</Typography>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonHeader>
        <IonContent color="primary">
          <IonList className="sidemenu-list ion-no-padding ion-margin-vertical">
            <IonItem color="primary" lines="none" onClick={() => setShowAlert(true)}>
              <IonIcon icon={logOut} mode="ios" style={{ transform: 'rotate(180deg)' }} className="ion-margin-end" size="large"></IonIcon> Logout
            </IonItem>
          </IonList>
        </IonContent>
      </IonMenu>
    </>
  );
});

const mapstp = ({user}) => ({user});
const mapdtp = dispatch => bindActionCreators({logout}, dispatch);

export default connect(mapstp, mapdtp) (withRouter(ShipperSideMenu));