import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    IonButtons,
    IonHeader,
    IonPage,
    IonContent,
    IonTitle,
    IonToolbar,
    IonSpinner,
    IonButton,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRow,
    IonCol
} from "@ionic/react";
import { getAllNotifications, markAsRead, setNotification } from '../../../../actions/notification';
import _ from 'lodash';
import NotificationCard from './NotificationCard';
import back from '../../../../assets/new-icons/back.svg';

const NotificationScreen = props => {

    const { list, count, offset, isLoading, history } = props;
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);

    const init = () => {
      props.getAllNotifications();
    }

    useEffect(() => {
      init()
    }, []);

    const goBack = () => {
      history.goBack();
    };

    const loadMore = (e) => {
      props.getAllNotifications(offset + 10);
      e.target.complete();
  
      if (count > list.length) {
        setDisableInfiniteScroll(false);
      } else {
        setDisableInfiniteScroll(true);
      };
    };

    const onClickCard = (notif) => {
      if (!notif.read_at) {
        let payload = { notification_log_ids: [notif.id]}
        let now = new Date();
        props.markAsRead(payload)
          .then(() => {
            let allNotifications = _.cloneDeep(list);
            let index = _.findIndex(allNotifications, (n) => n.id === notif.id);

            if (index) {
              allNotifications[index].read_at = now;
              props.setNotification(allNotifications);
            }
          });
      }

      let metadata = _.get(notif, 'notification_data.metadata', null);
      if (metadata) {
        metadata = JSON.parse(metadata);
        let order_id = _.get(metadata, 'order_id', null);
        if (order_id) {
          history.push(`/distributor/inbound/detail/${order_id}`);
        }
      }
    }


    return (
      <IonPage>
          <IonHeader>
              <IonToolbar className="ritase-toolbar" color="primary">
                  <IonButtons slot="start">
                    <IonButton onClick={ () => goBack() }>
                      <span style={{filter: 'invert(1) brightness(200%)', marginRight: '25px'}}><img src={back} alt="back" /></span>
                    </IonButton>
                  </IonButtons>
                  <IonTitle>Notifikasi</IonTitle>
              </IonToolbar>
          </IonHeader>
          <IonContent style={{textAlign: 'left'}}>
            { list && list.length > 0 && !isLoading 
              ? <div style={{paddingBottom: disableInfiniteScroll ? '45px' : '0'}}>
                  {list.map(notif => (
                    <NotificationCard notif={notif} onClickCard={onClickCard} />
                  ))}
                </div>
              : isLoading ?
                <IonSpinner name="crescent" style={{width: '100%'}}/>
              : list && list.length ==0 && !isLoading ?
                <IonRow>
                  <IonCol>
                    <div className="ion-text-center" style={{ paddingTop: '50%'}}>
                      <h4>Tidak ada notifikasi</h4>
                      <p>Lakukan pengaturan notifikasi agar anda selalu mendapatkan update tentang shipment anda</p>
                    </div>
                  </IonCol>
                </IonRow>
              : ''
            }

            <IonInfiniteScroll
              threshold="10px"
              disabled={disableInfiniteScroll}
              onIonInfinite={(e) => loadMore(e)}
            >
              <IonInfiniteScrollContent loadingSpinner="circular" />
            </IonInfiniteScroll>
          </IonContent>

      </IonPage>
    )
}

const mapStateToProps = state => {
  const { listNotifications, countNotifications, offsetNotifications, loadNotifications } = state.notification;

  return {
    list: listNotifications,
    count: countNotifications,
    offset: offsetNotifications,
    isLoading: loadNotifications
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getAllNotifications, markAsRead, setNotification
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationScreen);