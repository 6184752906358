const getApprovalStatus  = (data, user_id) => {
  let schema = data && data.schema;
  if (schema && typeof schema != 'object') {
    schema = JSON.parse(schema);
  }

  if (schema) {
    let allBaseApproval = [];
    schema.payload.map(element => {
      element.base_approval.map(approval => {
        approval.approval_conditional_type = element.conditional_type;
        approval.approval_is_approved = element.is_approved;
        approval.approval_is_completed = element.is_completed;
      })
      allBaseApproval = allBaseApproval.concat(element.base_approval);
    });

    const selectedApproval = allBaseApproval.find(approval => approval.approver_id === user_id);
    if (selectedApproval) {
      if (selectedApproval.approval_conditional_type == 'or') {
        if (selectedApproval.approval_is_completed) {
          return selectedApproval.approval_is_approved ? 'approved' : 'rejected';
        } else {
          return null;
        }
      } else if (selectedApproval.approval_conditional_type == 'and') {
        if (selectedApproval.is_completed) {
          return selectedApproval.is_approved ? 'approved' : 'rejected';
        } else {
          return null;
        }
      }
    }
  }
}

export default getApprovalStatus;