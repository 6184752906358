import React, {useState, useEffect} from 'react';
import { IonHeader, IonFooter, IonToolbar, IonTitle, IonButton, IonContent, IonCard, IonCardContent, IonGrid, IonRow, IonCol, IonIcon, IonInput } from '@ionic/react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {closeOrder, setOrder, setTrip} from '../../../../actions/w_trip';
import { add, remove, refresh} from 'ionicons/icons'
import './UpdateQuantityModal.scss';
import PackageItem from '../../../../components/PackageItem';

const UpdateQuantityModal = ({trip, order, closeOrder, setTrip, setOrder}) => {

  const [packages, setPackages] = useState([]);

  const getShortId  = (id) => {
    if (!id) {
      return '';
    }
    return id.slice(0,8) + id.slice(22);
  };

  const addQuantity = (newPkg, delta) => () => {
    if (newPkg.updatedQuantity > 0 || delta > -1) {
      setPackages(packages.map(pkg => {
        if (newPkg.id === pkg.id) {
          return {
            ...pkg,
            updatedQuantity: pkg.updatedQuantity + delta
          }
        }
        return pkg;
      }));
    }
  };

  const revertQuantityChanges = (newPkg) => () => {
    setPackages(packages.map(pkg => {
      if (newPkg.id === pkg.id) {
        return {
          ...pkg,
          updatedQuantity: pkg.quantity
        }
      }
      return pkg;
    }))
  };

  const handleChange = newPkg => e => {
    if (newPkg.updatedQuantity !== e.target.value && e.target.value >= 0) {
      setPackages(packages.map(pkg => {
        if (newPkg.id === pkg.id) {
          return {
            ...pkg,
            updatedQuantity: Number(e.target.value)
          }
        }
        return pkg;
      }));
    } 
  };

  const saveToTrip = () => () => {
    if (order && packages) {
      let newOrder = {
        ...order,
        packages
      }

      setTrip({
        ...trip,
        orders: trip.orders.map(order => {
          if (order.id === newOrder.id) {
            return newOrder;
          }
          return order;
        })
      });
    }
    closeOrder();
  };

  useEffect(() => {
    if (order && order.packages && !packages.length) {
      setPackages(order.packages);
    }
  }, [order])


  return (
    <>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle className="modal-title">
            <strong>{order && order.shipment_number}</strong>/<span style={{ color: '#9b9b9b' }}>{order && order.order_number}</span>
            <span className="ion-float-right" style={{ color: '#9b9b9b' }}>{getShortId(order && order.id)}</span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {
          packages && packages.map(pkg => (
            <IonCard key={pkg.id} className="package-card">
              <IonCardContent>
                <IonGrid>
                  <IonRow>
                    <IonCol size="4">
                      <strong>{pkg.sku || 'Tidak ada SKU'}</strong><br/>
                      <span>{pkg.external_number || 'Tidak ada external number'}</span>
                    </IonCol>
                    <IonCol size="6">
                      <strong>{pkg.description || '-'}</strong><br />
                    </IonCol>
                    <IonCol size="2">
                      <PackageItem type={pkg.package_type}><strong>{pkg.actual_quantity}</strong></PackageItem>
                    </IonCol>
                    {/* <IonCol size="1">
                      <IonButton color="light" className="no-padding" expand="block" onClick={(addQuantity(pkg, -1))}>
                        <IonIcon icon={remove}/>
                      </IonButton>
                    </IonCol>
                    <IonCol size="2">
                      <IonInput type="number" value={pkg.updatedQuantity} onIonChange={handleChange(pkg)}/>
                    </IonCol>
                    <IonCol size="1">
                      <IonButton color="light" className="no-padding" expand="block" size="xl" onClick={addQuantity(pkg, 1)}>
                        <IonIcon icon={add}/>
                      </IonButton>
                    </IonCol> */}
                    {pkg.quantity === pkg.updatedQuantity ? null : (                      <IonCol size="1">
                      <IonButton color="light" className="no-padding" expand="block" onClick={revertQuantityChanges(pkg)}>
                        <IonIcon icon={refresh} />
                      </IonButton>
                    </IonCol>
                    )}
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>
          ))
        }
        {packages && packages.length < 1 && <IonCard><IonCardContent>No Data.</IonCardContent></IonCard>}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton color="light" expand="block" onClick={closeOrder}>
                  Tutup
                </IonButton>
              </IonCol>
              {/* <IonCol>
                <IonButton expand="block" onClick={saveToTrip()}>
                  Simpan
                </IonButton> 
              </IonCol>*/}
            </IonRow>
          </IonGrid>
          
          
        </IonToolbar>
      </IonFooter>
    </>
  ) 
}

const mapStateToProps = ({w_trip}) => ({
  trip: w_trip.trip,
  isLoading: w_trip.isLoading,
  errorMessage: w_trip.errorMessage,
  modalOrder: w_trip.order
});
const mapDispatchToProps = dispatch => bindActionCreators({setTrip, closeOrder, setOrder}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps) (UpdateQuantityModal);