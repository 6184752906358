/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IonPage, IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonModal, IonDatetime, IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import moment from 'moment';
import { getList } from '../../../../actions/report';
import calendar from '../../../../assets/new-icons/calendar.svg';
import rightsmall from '../../../../assets/new-icons/angle-right-small.svg';
import './ReportScreen.scss';

const ReportScreen = props => {
  const { reports, offset, count } = props;
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [date, setDate] = useState('');
  const [tempDate, setTempDate] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');
  const [params, setParams] = useState('&status=approved');

  useEffect(() => {
    props.getList(0, params);
  }, []);

  const loadMore = (e) => {
    props.getList(offset + 10, params);

    e.target.complete();

    if (count > reports.length) {
      setDisableInfiniteScroll(false);
    } else {
      setDisableInfiniteScroll(true);
    };
  };

  const applyFilter = () => {
    let today = moment().endOf('day');
    let new_date;
    switch (selectedFilter) {
      case 'today':
        new_date = today;
        break;
      case 'yesterday':
        new_date = moment(today).subtract(1, 'days');
        break;
      case 'custom':
        new_date = moment(tempDate).endOf('day');
        break;
      default:
        new_date = '';
        break;
    }

    let start = moment(new_date).startOf('day').utc().format();
    let end = moment.utc(new_date).format();

    let new_params = new_date ? `&reports_updated_time=${start},${end}` : ''
    props.getList(0, new_params + '&status=approved');
    setDate(new_date);
    setParams(new_params + '&status=approved');
    setShowFilter(false);
  }

  const resetFilter = () => {
    setSelectedFilter('');
    setTempDate('');
  }

  return (
    <IonPage className="operator operator-report">
      <IonContent className="operator-content">
        <div className="operator-header">
          <span>Report</span>

          <div className="report-header">
            <img src={calendar} alt="" onClick={() => setShowFilter(true)} />
          </div>
        </div>

        <div className="report-content">
          { !reports ?
            <div style={{padding: '30px'}}>Loading</div>
          : reports.length <= 0 ?
            <div style={{padding: '30px'}}>No data found</div>
          :
            <div>
              {reports.map(report => (
                <Link key={report.id} to={`/operator/report/${report.id}`}>
                  <div className="report-item">
                    <img src={rightsmall} alt="" />
                    <div style={{fontSize: '14px'}}>{report.name}</div>
                    <div style={{color: '#595959'}}>{_.get(report, 'summary.count_order', 0)} shipment</div>
                    <div style={{color: '#9e9e9e'}}>{_.get(report, 'company_template_report.send_time') ? `${moment(report.approved_at).format('DD MMMM YYYY')}, ${moment.utc(report.company_template_report.send_time, 'HH:mm:ss').local().format('HH:mm')}` : moment(report.approved_at).format('DD MMMM YYYY, HH:mm')}</div>
                  </div>
                </Link>
              ))}

              <IonInfiniteScroll
                threshold="10px"
                disabled={disableInfiniteScroll}
                onIonInfinite={(e) => loadMore(e)}
              >
                <IonInfiniteScrollContent loadingSpinner="circular" />
              </IonInfiniteScroll>
            </div>
          }
        </div>

        <IonModal isOpen={showFilter} cssClass="report-filter" swipeToClose={true} onDidDismiss={() => setShowFilter(false)}>
          <div className="report-filter-content">
            <div className="report-filter-title">Date Filter</div>
            <div className="report-filter-options">
              <label className="radio-container">
                Today
                <input type="radio" value="today" name="date" checked={selectedFilter === 'today'} onChange={() => setSelectedFilter('today')} />
                <span className="checkmark"></span>
              </label>

              <label className="radio-container">
                Yesterday
                <input type="radio" value="yesterday" name="date" checked={selectedFilter === 'yesterday'} onChange={() => setSelectedFilter('yesterday')} />
                <span className="checkmark"></span>
              </label>

              <label className="radio-container">
                Custom Date
                <input type="radio" value="custom" name="date" checked={selectedFilter === 'custom'} onChange={() => setSelectedFilter('custom')} />
                <span className="checkmark"></span>
                <div style={{display: 'flex'}}>
                  <img src={calendar} alt="" />
                  <IonDatetime
                    displayFormat="DD/MM/YYYY"
                    placeholder="Start Date"
                    value={tempDate}
                    onIonChange={e => setTempDate(e.detail.value)}
                    className="filter-date"
                    onClick={() => setSelectedFilter('custom')}
                  />
                </div>
              </label>

            </div>

            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonButton className="btn-filter" expand="block" color="light" onClick={() => resetFilter()}>Reset</IonButton>
                </IonCol>
                <IonCol>
                  <IonButton className="btn-filter" expand="block" color="primary" onClick={applyFilter}>Filter</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = state => {
  const { lists, offset, count } = state.report;

  return {
    reports: lists,
    count,
    offset
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getList
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportScreen);
